<template>
  <div>
    <div class="pools-streams-head-section">
      <h1 class="mb-0 mr-5">
        Your Pools
        <span class="pools-streams-count">
          <span v-if="totalRecordsSelected > 0">Selected: {{totalRecordsSelected}} / </span><span>Total: {{totalRecords}}</span>
        </span>
      </h1>

      <IconField iconPosition="left" class="search-input-box mt-4 lg:mt-0">
        <InputIcon class="pi pi-search lg:text-2xl"></InputIcon>
        <InputText
          class="inputfield text-lg lg:text-xl"
          placeholder="Search"
          type="text"
          v-model="search"
          @input="debounceSearch()"
        />
      </IconField>
    </div>
    <DataView :value="pools" dataKey="Id" layout="list" :paginator="true" :rows="20" :lazy="true" @page="onPage($event)" :totalRecords="totalRecords" class="pools-streams-view pools" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown" :rowsPerPageOptions="[10,20,50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
      <template #header>
        <div class="pools-streams-view-header-item mt-5 mb-4 lg:mb-5">
          <Button @click="openNewPool" label="Add Pool" icon="pi pi-plus-circle text-lg lg:text-2xl" class="p-button-raised text-lg font-light rounded-border-radius pools-streams-control-btn"/>
          <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Sort By" @change="onSortChange($event)" class="dropdown-lg box-shadow-container rounded-border-radius"/>
          <Button @click="selectAll" label="Select All" class="p-button-raised p-button-text p-button-secondary text-lg font-light rounded-border-radius pools-streams-control-btn"/>
          <Button v-if="totalRecordsSelected > 0" @click="selectNone" label="Deselect" class="p-button-raised p-button-text p-button-secondary text-lg font-light rounded-border-radius pools-streams-control-btn"/>
          <Button v-if="totalRecordsSelected > 0" @click="openConfirmation" label="Remove" class="p-button-raised p-button-text p-button-danger text-lg font-light rounded-border-radius pools-streams-control-btn"/>
        </div>
        <div class="pools-streams-view-header-item for-admin-only mb-4 lg:mb-5" v-if="permissions && permissions.BitpoolAdmin">
          <div class="field-checkbox mb-0" v-if="permissions && permissions.BitpoolAdmin">
            <Checkbox
              inputId="includeHidden"
              :binary="true"
              v-model="includeHidden"
            ></Checkbox>
            <label class="text-lg font-light" for="includeHidden">Show archived</label>
          </div>
          <div class="field-checkbox mb-0" v-if="permissions && permissions.BitpoolAdmin">
            <Checkbox
              inputId="organisationOnly"
              :binary="true"
              v-model="organisationOnly"
            ></Checkbox>
            <label class="text-lg font-light" for="organisationOnly">Show organisation pools</label>
          </div>
        </div>
      </template>
      <template #list="slotProps">
        <div class="grid grid-nogutter">
          <div v-for="(item, index) in slotProps.items" :key="index" class="col-12">
            <PoolView :pool="item" />
          </div>
        </div>
      </template>
      <template #empty>
        <div v-if="isLoadedPage">
          No pools found.
        </div>
        <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
    </DataView>

    <Dialog header="Delete Pools" v-model:visible="displayConfirmation" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div v-if="deletePoolsInProgress">
          <ProgressBar :value="deletePoolsProgress" :showValue="false" />
        </div>
        <div class="flex align-items-center" v-else>
          <i class="pi pi-exclamation-triangle text-4xl mr-3" style="color: var(--error-500);" />
          <span>Are you sure you want to delete {{totalRecordsSelected}} pools?</span>
        </div>
      </div>
      <template #footer>
        <Button v-if="!deletePoolsInProgress" label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text p-button-secondary"/>
        <Button v-if="!deletePoolsInProgress" label="Yes" icon="pi pi-check" @click="deletePools" />
      </template>
    </Dialog>

    <Dialog header="Create Pool" v-model:visible="displayNewPool" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div class="field">
          <label for="">Enter pool name</label>
          <div>
            <InputText
              class="inputfield p-inputtext p-inputtext-lg w-full"
              placeholder="Pool Name"
              type="text"
              v-model="newPoolName"
            />
          </div>
        </div>
        <div class="field flex align-items-center mb-0">
          <div>
            <InputSwitch inputId="virtualPoolSwitch" v-model="virtualPool" />
          </div>
          <label for="virtualPoolSwitch" class="mb-0 ml-2">Virtual pool</label>
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="closeNewPool" class="p-button-text p-button-secondary"/>
        <Button label="Create" icon="pi pi-check" @click="createNewPool" :disabled='!newPoolName' />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import DataView from 'primevue/dataview';
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';
import InputText from 'primevue/inputtext';
import Checkbox from "primevue/checkbox";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import ProgressBar from 'primevue/progressbar';
import InputSwitch from 'primevue/inputswitch';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import PoolView from "./PoolView.vue";
import { PoolModel } from "@/models/PoolModel";
import { debounce, throttle } from 'throttle-debounce';
import { Watch } from "vue-facing-decorator";
import PoolsSearchPageQuery from "@/models/PoolsSearchPageQuery";
import { BitpoolOSPermissions } from "@/models/BitpoolOSPermissions";
import SignalRDataService from "@/services/signalR/SignalRDataService";

@Component({
  components: {
    DataView,
    Dropdown,
    ProgressSpinner,
    InputText,
    Checkbox,
    Button,
    Dialog,
    ProgressBar,
    InputSwitch,
    IconField,
    InputIcon,
    PoolView
  },
})
class PoolsView extends Vue {
  includeHidden = false;
  organisationOnly = true;

  get permissions(): BitpoolOSPermissions | null {
    return this.$store.state.auth.permissions;
  }
  
  search = '';
  searchFinal = '';
  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  get pools(): PoolModel[] {
    const pools = this.$store.state.pools.poolsPage && this.$store.state.pools.poolsPage ?
      this.$store.state.pools.poolsPage.Pools as PoolModel[] :
      [];
    return pools;
  }

  get totalRecords(): number {
    return this.$store.state.pools.poolsPage ? this.$store.state.pools.poolsPage.PoolsCount : 0;
  }

  get totalRecordsSelected(): number {
    return this.selectedPoolKeys.length;
  }

  get selectedPoolKeys(): string[] {
    return this.$store.state.pools.selectedPoolKeys ? this.$store.state.pools.selectedPoolKeys : [];
  }

  get isLoadedPage(): boolean {
    return this.$store.state.pools.isLoadedPage;
  }

  sortOrder = 1;
  sortField = 'Name';
  sortOptions = [
    {label: 'Name: A-Z', value: 'Name'},
    {label: 'Name: Z-A', value: '!Name'},
    {label: 'Last Update: Fresh First', value: '!LastUpdates'},
    {label: 'Last Update: Old First', value: 'LastUpdates'},
    {label: 'Records: Ascending', value: 'Records'},
    {label: 'Records: Descending', value: '!Records'},
    {label: 'Streams: Ascending', value: 'Streams'},
    {label: 'Streams: Descending', value: '!Streams'}
  ];
  sortKey: any = this.sortOptions[0];

  take = 20;
  skip = 0;

  created(): void {
    this.refresh();
    this.$store.dispatch("pools/loadPoolsList");
  }

  unmounted(): void {
    if (this.pools?.length) {
      SignalRDataService.unsubscribe(this.$store.state.apiUrl, "poolHub", this.pools.map(x => x.Id));
    }
    SignalRDataService.removeCallback(this.$store.state.apiUrl, "poolHub", this.signalRCallback);
  }

  @Watch('pools', { immediate: false, deep: false })
  onChangePools(val: PoolModel[], oldVal: PoolModel[]): void {
    SignalRDataService.unsubscribe(this.$store.state.apiUrl, "poolHub", oldVal.map(x => x.Id));
    SignalRDataService.subscribe(this.$store.state.apiUrl, "poolHub", val.map(x => x.Id), this.signalRCallback);
  }

  signalRKeys = new Set();

  signalRCallback(poolKey: string): void {
    this.signalRKeys.add(poolKey);
    this.signalRCallbackThrottle();
  }

  signalRCallbackThrottle = throttle(1000, this.onSignalRCallback, { noLeading: true });

  signalRCallbackActual(): void {
    this.signalRKeys.forEach(poolKey => {
      this.$store.dispatch("pools/refreshPool", poolKey);
    });
    this.signalRKeys.clear();
  }

  onSignalRCallback(): void {
    this.signalRCallbackActual();
  }

  buildQuery(): PoolsSearchPageQuery { 
    return {
      search: this.searchFinal,
      orderBy: this.sortField,
      desc: this.sortOrder === -1,
      take: this.take,
      skip: this.skip,
      includeHidden: this.includeHidden,
      organisationOnly: this.organisationOnly
    }
  }

  @Watch('searchFinal', { immediate: false, deep: false })
  @Watch('includeHidden', { immediate: false, deep: false })
  @Watch('organisationOnly', { immediate: false, deep: false })
  onInputChanged(): void {
    this.refresh();
  }

  refresh(): void {
    this.$store.dispatch("pools/loadPoolsPage", this.buildQuery());
  }

  onPage(event: any): void {
    // event.page: New page number
    // event.first: Index of first record
    // event.rows: Number of rows to display in new page
    // event.pageCount: Total number of pages
    this.skip = event.page * event.rows;
    this.take = event.rows;
    this.refresh();
  }

  onSortChange(event: any): void {
    const value = event.value.value;
    const sortValue = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
      this.sortKey = sortValue;
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
      this.sortKey = sortValue;
    }
    this.refresh();
  }

  selectAll(): void {
    const poolKeys = this.pools.map((pool) => pool.Id);
    this.$store.commit("pools/selectManyPools", poolKeys);
  }

  selectNone(): void {
    this.$store.commit("pools/selectNonePools");
  }

  // #region delete pools
  displayConfirmation = false;

  openConfirmation(): void {
    this.deletePoolsInProgress = false;
    this.displayConfirmation = true;
  }

  closeConfirmation(): void {
    this.displayConfirmation = false;
  }

  deletePoolsInProgress = false;
  deletePoolsProgress = 0;

  async deletePools(): Promise<void> {
    this.deletePoolsProgress = 0;
    this.deletePoolsInProgress = true;
    const keys = this.selectedPoolKeys;
    for (let i = 0; i < keys.length; i++) {
      this.deletePoolsProgress = i * 100 / keys.length;
      const request = {
        poolKey: keys[i],
        reload: false
      };
      await this.$store.dispatch("pools/delete", request);
    }
    this.deletePoolsProgress = 100;
    this.selectNone();
    this.refresh();
    this.closeConfirmation();
  }
  // #endregion delete pools

  // #region new pool
  displayNewPool = false;
  newPoolName = "";
  virtualPool = false;

  openNewPool(): void {
    this.newPoolName = "";
    this.displayNewPool = true;
  }

  closeNewPool(): void {
    this.displayNewPool = false;
  }

  createNewPool(): void {
    if (this.newPoolName) {
      this.closeNewPool();
      const body = {
        Poolname: this.newPoolName,
        Public: false,
        Virtual: this.virtualPool
      };
      this.$store.dispatch("pools/registerPool", body);
    }
  }
  // #endregion new pool
}

export default PoolsView;
</script>