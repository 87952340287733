<template>
  <draggable
    tag="div"
    :list="items"
    handle=".handle"
    @end="dropEvent"
    :group="{ name: 'elements' }"
    :animation="150"
    :fallbackOnBody="true"
    :swapThreshold="0.65"
    :invertSwap="true"
    :direction="getSortDirection"
    :disabled="!reports3.editMode"
    ghostClass="dragging"
    class="bp-grid droppable-area"
    item-key="Uid"
  >
    <template #item="{ element }">
      <div :class="`bp-col-${element.Size}`">
        <!-- Element -->
        <div
          v-if="element.Role === reports3ItemRole.Element"
          class="report-element"
          :class="`${reports3.editMode && element.Uid === reports3.dataOneSelectedElementConfiguration?.Uid ? 'is-selected' : ''}`"
          :id="`reportConstructorElementContainer-${element.Uid}`"
        >
          <div 
            v-if="reports3.editMode"
            class="report-element-head handle"
          >
            <div class="report-element-head-inner" @click="selectElement(element)"></div>
            <Button
              v-if="reports3.isCompactMode"
              v-tippy="'Edit'"
              @click="openEditDialog($event, element)"
              class="p-button-half-white"
            >
              <ReportEditSvg/>
            </Button>
            <Button
              v-tippy="'Delete'"
              @click="deleteItem($event, element)"
              class="p-button-half-white"
            >
              <ReportDeleteSvg/>
            </Button>
            <Button
              v-tippy="'More Actions'"
              @click="showAdditionalMenu($event, element)"
              class="p-button-half-white"
            >
              <ReportMoreActionsSvg/>
            </Button>
          </div>
          <ReportsHtmlElementView
            :element="getElement(element.ElementId)" 
            :configuration="element"
            :report="report"
          />
        </div>

        <!-- Grid -->
        <div
          v-else-if="element.Role === reports3ItemRole.Grid"
          class="report-grid"
          :class="`${reports3.editMode && element.Uid === reports3.dataOneSelectedElementConfiguration?.Uid ? 'is-selected' : ''}`"
          :id="`reportConstructorGridContainer-${element.Uid}`"
        >
          <div
            v-if="reports3.editMode"
            class="report-grid-head handle"
          >
            <div class="report-grid-head-inner" @click="selectElement(element)"></div>
            <Button
              v-tippy="'Add Grid'"
              @click="addGrid($event, element)"
              class="p-button-half-white"
            >
              <AddReportGridSvg/>
            </Button>
            <Button
              v-tippy="'Add Element'"
              @click="openElementsDialog($event, element)"
              class="p-button-half-white"
            >
              <AddReportElementSvg/>
            </Button>
            <Button
              v-if="reports3.isCompactMode"
              v-tippy="'Edit'"
              @click="openEditDialog($event, element)"
              class="p-button-half-white"
            >
              <ReportEditSvg/>
            </Button>
            <Button
              v-tippy="'Delete'"
              @click="deleteItem($event, element)"
              class="p-button-half-white"
            >
              <ReportDeleteSvg/>
            </Button>
            <Button
              v-tippy="'More Actions'"
              @click="showAdditionalMenu($event, element)"
              class="p-button-half-white"
            >
              <ReportMoreActionsSvg/>
            </Button>
          </div>
          <ReportsHtmlGridView v-model="element.Items" :report="report"/>
        </div>
        
        <Menu ref="additionalMenu" :model="additionalMenuItems" :popup="true" />
      </div>
    </template>
  </draggable>

  <ReportsSelectElementDialogView
    v-model="displayElementsDialog"
    @elementSelected="addElement"
  />
</template>

<script lang="ts">
import { Component, Model, Prop, Ref, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { MenuItem } from "primevue/menuitem";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { useReports3ElementsStore } from "@/stores/reports3Elements";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import ReportsHtmlElementView from "@/components/views/reports/ReportsHtmlElementView.vue";
import { Reports3ItemRole } from "@/models/reports/v3/Reports3ItemRole";
import { v4 as uuidv4 } from "uuid";
import { useReports3Store } from "@/stores/reports3";
import ReportsSelectElementDialogView from "@/components/views/reports/ReportsSelectElementDialogView.vue";
import draggable from "vuedraggable";
import AddReportElementSvg from "@/components/svg/AddReportElementSvg.vue";
import AddReportGridSvg from "@/components/svg/AddReportGridSvg.vue";
import ReportMoreActionsSvg from "@/components/svg/ReportMoreActionsSvg.vue";
import ReportDeleteSvg from "@/components/svg/ReportDeleteSvg.vue";
import ReportEditSvg from "@/components/svg/ReportEditSvg.vue";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";

@Component({
  components: {
    Button,
    Menu,
    ReportsHtmlElementView,
    ReportsSelectElementDialogView,
    draggable,
    AddReportElementSvg,
    AddReportGridSvg,
    ReportMoreActionsSvg,
    ReportDeleteSvg,
    ReportEditSvg
  },
  directives: {
  }
})
class ReportsHtmlGridView extends Vue {
  @Model items!: Reports3ElementConfiguration[];
  @Prop({ required: false, default: null }) report!: Reports3Entity | null;

  reports3 = useReports3Store();
  reports3Elements = useReports3ElementsStore();

  getElement(id: string): Reports3ElementEntity | undefined {
    if (this.reports3.dataOneElements?.length) {
      const element = this.reports3.dataOneElements.find((x) => x.Id === id);
      return element;
    }
    return undefined;
  }

  selectElement(elementConfiguration: Reports3ElementConfiguration): void {
    this.reports3.selectElement(elementConfiguration);
  }

  deleteItem(event: Event, elementConfiguration: Reports3ElementConfiguration): void {
    const index = this.items.findIndex((x) => x.Uid === elementConfiguration.Uid);
    if (index !== -1) {
      this.items.splice(index, 1);
    }
    if (this.reports3.dataOneSelectedElementConfiguration?.Uid === elementConfiguration.Uid) {
      this.reports3.dataOneSelectedElementConfiguration = null;
    }
  }

  addGrid(event: Event, elementConfiguration: Reports3ElementConfiguration): void {
    if (elementConfiguration.Role === Reports3ItemRole.Grid) {
      const item = this.reports3.createGridConfiguration();
      if (!elementConfiguration.Items) {
        elementConfiguration.Items = [];
      }
      elementConfiguration.Items.push(item);
      this.reports3.dataOneSelectedElementConfiguration = item;
    }
  }

  displayElementsDialog = false;
  selectedElementConfiguration: Reports3ElementConfiguration | null = null;

  openElementsDialog(event: Event, elementConfiguration: Reports3ElementConfiguration): void {
    this.selectedElementConfiguration = elementConfiguration;
    this.displayElementsDialog = true;
  }

  addElement(element: Reports3ElementEntity): void {
    if (this.selectedElementConfiguration?.Role === Reports3ItemRole.Grid) {
      const item = this.reports3.createElementConfiguration(element);
      if (!this.selectedElementConfiguration.Items) {
        this.selectedElementConfiguration.Items = [];
      }
      this.selectedElementConfiguration.Items.push(item);
      this.reports3.dataOneSelectedElementConfiguration = item;
      this.displayElementsDialog = false;
    }
  }

  reports3ItemRole = Reports3ItemRole;

  dropEvent(event: any): void {
    // nothing
  }

  getSortDirection(event: DragEvent, target: HTMLElement, dragEl: HTMLElement): string {
    // console.log(event);
    // console.log(target);
    // console.log(dragEl);
    if (target && target.classList.contains("grid")) {
      // drag to grid
      return "vertical";
    } else {
      // drag to widget
      return "horizontal";
    }
  }

  openEditDialog(event: Event, elementConfiguration: Reports3ElementConfiguration): void {
    this.selectElement(elementConfiguration);
    if (this.reports3.isCompactMode) {
      this.reports3.displayConfigurationDialog = true;
    }
  }

  // #region additional actions
  @Ref() readonly additionalMenu!: Menu;

  showAdditionalMenu(event: Event, elementConfiguration: Reports3ElementConfiguration): void {
    this.selectElement(elementConfiguration);
    this.additionalMenu.toggle(event);
  }
  
  get additionalMenuItems(): MenuItem[] {
    const result: MenuItem[] = [];
    if (this.reports3.dataOneSelectedElementConfiguration) {
      result.push({
          label: 'Cut',
          icon: undefined,
          command: () => {
            if (this.reports3.dataOneSelectedElementConfiguration) {
              this.reports3.cutOrCopy = false;
              this.reports3.copiedConfiguration = this.reports3.dataOneSelectedElementConfiguration;
            }
          }
        });
        result.push({
          label: 'Copy',
          icon: undefined,
          command: () => {
            if (this.reports3.dataOneSelectedElementConfiguration) {
              this.reports3.cutOrCopy = true;
              this.reports3.copiedConfiguration = this.reports3.dataOneSelectedElementConfiguration;
            }
          }
        });
        if (this.reports3.dataOneSelectedElementConfiguration.Role === Reports3ItemRole.Grid) {
          result.push({
            label: 'Paste',
            icon: undefined,
            command: () => {
              if (this.reports3.dataOneSelectedElementConfiguration?.Items && this.reports3.copiedConfiguration) {
                this.pasteConfiguration(this.reports3.dataOneSelectedElementConfiguration.Items, this.reports3.copiedConfiguration, this.reports3.cutOrCopy);
              }
            },
            disabled: !this.reports3.copiedConfiguration
          });
        }
        result.push({
          label: 'Duplicate',
          icon: undefined,
          command: () => {
            if (this.reports3.dataOneSelectedElementConfiguration) {
              this.pasteConfiguration(this.items,this.reports3.dataOneSelectedElementConfiguration, true);
            }
          }
        });
    }
    return result;
  }

  regenerateUids(source: Reports3ElementConfiguration): void {
    source.Uid = uuidv4();
    if (source.Items) {
      source.Items.forEach((x) => this.regenerateUids(x));
    }
  }

  findParent(source: Reports3ElementConfiguration, items: Reports3ElementConfiguration[]): Reports3ElementConfiguration[] | null {
    for (const item of items) {
      if (item.Uid === source.Uid) {
        return items;
      }
      if (item.Items) {
        const result = this.findParent(source, item.Items);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  pasteConfiguration(destination: Reports3ElementConfiguration[], source: Reports3ElementConfiguration, cutOrCopy: boolean): void {
    // cutOrCopy: false - cut, true - copy
    if (cutOrCopy) {
      // copy
      const newItem = JSON.parse(JSON.stringify(source)) as Reports3ElementConfiguration;
      this.regenerateUids(newItem);
      destination.push(newItem);
    } else {
      // cut
      const newItem = source;
      if (this.report?.Items) {
        const parent = this.findParent(source, this.report.Items);
        if (parent) {
          const index = parent.findIndex((x) => x.Uid === source.Uid);
          if (index !== -1) {
            parent.splice(index, 1);
          }
        }
        destination.push(newItem);
      }
    }
    // #endregion additional actions
  }
}

export default ReportsHtmlGridView;
</script>