<template>
  <Button label="Choose" :class="buttonClass" icon="pi pi-images" @click="displayImageGalleryDialog = true" />
  <ImageGalleryDialogView v-model="displayImageGalleryDialog" @imageSelected="imageSelected"/>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from "vue-facing-decorator";
import Button from "primevue/button";
import { ImageGalleryEntity } from "@/models/image-gallery/ImageGalleryEntity";
import ImageGalleryDialogView from "@/components/views/image-gallery/ImageGalleryDialogView.vue";

@Component({
  components: {
    Button,
    ImageGalleryDialogView
  },
})
class ImageGalleryDialogButtonView extends Vue {
  @Prop({ required: false, default: "" }) buttonClass!: string;

  @Emit imageSelected(entity: ImageGalleryEntity | null): ImageGalleryEntity | null {
    this.displayImageGalleryDialog = false;
    return entity;
  }

  displayImageGalleryDialog = false;
}

export default ImageGalleryDialogButtonView;
</script>