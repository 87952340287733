<template>
  <BlockUI :blocked="updateInProgress || !isDataLoaded" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-overlay-z-index main-bg-color" :class="updateInProgress || !isDataLoaded ? 'blockui-blocked' : ''">
    <section>
      <h3>Organisation Logo</h3>
      <div class="flex align-items-center">
        <Avatar v-if="organisationLogoUrl" :image="organisationLogoUrl" size="large" />
        <Avatar v-else icon="pi pi-building" size="large" />

        <div class="flex flex-column row-gap-2">
          <ImageGalleryDialogButtonView 
            buttonClass="p-button-outlined"
            @imageSelected="onImageSelected"
          />
          <Button 
            label="Remove Picture" 
            class="p-button-outlined p-button-danger delete-avatar"
            icon="pi pi-trash" 
            @click="removeLogo" 
            :disabled="!organisationLogo"
          />
        </div>
      </div>
    </section>
    <section class="mb-0">
      <h3>General Information</h3>
      <div class="formgrid grid">
        <div class="field col-12 sm:col-6">
          <label for="organisationName">Organisation Name</label>
          <div>
            <InputText
              class="inputfield w-full"
              type="text"
              id="organisationName"
              v-model="organisationName"
              @keyup="userDataChange"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label for="organisationAddress">Organisation Address</label>
          <div>
            <InputText
              class="inputfield w-full"
              type="text"
              id="organisationAddress"
              v-model="organisationAddress"
              @keyup="userDataChange"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-6 md:col-4 md:mb-0">
          <label for="organisationPerson">Contact Person</label>
          <div>
            <InputText
              class="inputfield w-full"
              type="text"
              id="organisationPerson"
              v-model="organisationPerson"
              @keyup="userDataChange"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-6 md:col-4 md:mb-0">
          <label for="organisationEmail">Contact Email</label>
          <div>
            <InputText
              class="inputfield w-full"
              type="text"
              id="organisationEmail"
              v-model="organisationEmail"
              @keyup="userDataChange"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-6 md:col-4 mb-0">
          <label for="organisationPhone">Contact Phone</label>
          <div>
            <InputText
              class="inputfield w-full"
              type="text"
              id="organisationPhone"
              v-model="organisationPhone"
              @keyup="userDataChange"
            />
          </div>
        </div>
      </div>
    </section>
    <div class="flex align-items-center justify-content-end column-gap-2 organisation-profile-edit-button-container">
      <Button
        label="Delete Organisation"
        icon="pi pi-trash" 
        class="p-button-danger organisation-profile-edit-delete-button"
        @click="openDeleteConfirmationDialog()"
      />
      <Button 
        label="Save changes" 
        :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-save'" 
        @click="saveAll" 
        class="organisation-profile-edit-save-button"
        :disabled='updateInProgress || !isDataChaged || !isDataLoaded' 
      />
    </div>

    <Dialog header="Delete Organisation" v-model:visible="displayDeleteConfirmationModal" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <BlockUI :blocked="organisationStore.deleteInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="organisationStore.deleteInProgress ? 'blockui-blocked' : ''">
          <div>
            <b>Are you sure you want to delete {{ organisationStore.currentOrganisation?.Name ?? "this" }} organisation?</b>
          </div>
          <div>
            If you delete this organisation you will lose all dashboards, widgets, and other information related to the organisation. <b>This action is not reversible.</b>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #footer>
        <Button 
          label="Cancel" 
          icon="pi pi-times" 
          @click="displayDeleteConfirmationModal = false" 
          class="p-button-text p-button-secondary"
        />
        <Button 
          label="Delete" 
          :icon="organisationStore.deleteInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" 
          @click="deleteOrganisation" 
          :disabled='organisationStore.deleteInProgress' 
          class="p-button-danger" 
        />
      </template>
    </Dialog>

    <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-facing-decorator";
import Dialog from 'primevue/dialog';
import InputText from "primevue/inputtext";
import Button from 'primevue/button';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import Avatar from 'primevue/avatar';
import UserSvg from "@/components/svg/UserSvg.vue";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import { useOrganisationStore } from "@/stores/organisation";
import { useOrganisationDetailsStore } from "@/stores/organisationDetails";
import { AUTH_LoginAs } from "@/models/auth/AUTH_LoginAs";
import NavigationHelper from "@/helpers/NavigationHelper";
import { useAuthStore } from "@/stores/auth";
import { AllUserData } from "@/models/user/AllUserData";
import ImageGalleryDialogButtonView from "@/components/views/image-gallery/ImageGalleryDialogButtonView.vue"
import CustomWindow from "@/CustomWindow";
import { ImageGalleryEntity } from "@/models/image-gallery/ImageGalleryEntity";

declare const window: CustomWindow;

@Component({
  
  components: {
    Dialog,
    InputText,
    Button,
    BlockUI,
    ProgressSpinner,
    Avatar,
    UserSvg,
    ImageGalleryDialogButtonView
  },
})
class OrganisationEditProfileView extends Vue {
  organisationStore = useOrganisationStore();
  organisationDetailsStore = useOrganisationDetailsStore();

  get organisationLogoUrl(): string {
    const apiUrl = this.$store.state.apiUrl;
    return this.organisationLogo ? `${apiUrl}/rest/AWS_S3_V1/File/${this.organisationLogo}` : "";
  }
  
  isDataChaged = false;
  isDataLoaded = false;
  organisationName = "";
  organisationLogo = "";
  organisationAddress = "";
  organisationPerson = "";
  organisationEmail = "";
  organisationPhone = "";
  updateInProgress = false;

  created(): void {
    this.init();
  }

  async init(): Promise<void> {
    if (this.organisationStore.currentOrganisation) {
      this.organisationName = this.organisationStore.currentOrganisation?.Name;
      this.organisationLogo = this.organisationStore.currentOrganisation?.Logo;
      if (this.organisationDetailsStore.entity?.organisationId !== this.organisationStore.currentOrganisation.Id) {
        await this.organisationDetailsStore.load(this.organisationStore.currentOrganisation.Id);
      }
      if (this.organisationDetailsStore.entity) {
        this.organisationAddress = this.organisationDetailsStore.entity.organisation;
        this.organisationPerson = this.organisationDetailsStore.entity.person;
        this.organisationEmail = this.organisationDetailsStore.entity.email;
        this.organisationPhone = this.organisationDetailsStore.entity.phone;
      }
    }
    this.isDataLoaded = true;
  }

  userDataChange(): void {
    this.isDataChaged = true;
  }

  async saveAll(): Promise<boolean> {
    this.updateInProgress = true;
    let isOk = true;
    try {
      // check what need to save and call api
      if (this.isDataChaged) {
        // save organisation
        if (this.organisationStore.currentOrganisation &&
          (this.organisationStore.currentOrganisation.Name !== this.organisationName ||
            this.organisationStore.currentOrganisation.Logo !== this.organisationLogo)
        ) {
          const oldName = this.organisationStore.currentOrganisation.Name;
          const oldLogo = this.organisationStore.currentOrganisation.Logo;
          this.organisationStore.currentOrganisation.Name = this.organisationName;
          this.organisationStore.currentOrganisation.Logo = this.organisationLogo;
          await this.organisationStore.createUpdate(this.organisationStore.currentOrganisation, true);
          if (this.organisationStore.updateError) {
            isOk = false;
            this.organisationStore.currentOrganisation.Name = oldName;
            this.organisationStore.currentOrganisation.Logo = oldLogo;
          }
        }

        // save organisation details
        if (this.organisationDetailsStore.entity &&
          (this.organisationDetailsStore.entity.organisation !== this.organisationAddress ||
            this.organisationDetailsStore.entity.person !== this.organisationPerson ||
            this.organisationDetailsStore.entity.email !== this.organisationEmail ||
            this.organisationDetailsStore.entity.phone !== this.organisationPhone)
        ) {
          const oldOrganisation = this.organisationDetailsStore.entity.organisation;
          const oldPerson = this.organisationDetailsStore.entity.person;
          const oldEmail = this.organisationDetailsStore.entity.email;
          const oldPhone = this.organisationDetailsStore.entity.phone;
          this.organisationDetailsStore.entity.organisation = this.organisationAddress;
          this.organisationDetailsStore.entity.person = this.organisationPerson;
          this.organisationDetailsStore.entity.email = this.organisationEmail;
          this.organisationDetailsStore.entity.phone = this.organisationPhone;
          await this.organisationDetailsStore.createUpdate(this.organisationDetailsStore.entity, true);
          if (this.organisationDetailsStore.updateError) {
            isOk = false;
            this.organisationDetailsStore.entity.organisation = oldOrganisation;
            this.organisationDetailsStore.entity.person = oldPerson;
            this.organisationDetailsStore.entity.email = oldEmail;
            this.organisationDetailsStore.entity.phone = oldPhone;
          }
        }
      }
      if (isOk) {
        ToastService.showToast("success", "", "Changes saved", 5000);
        this.isDataChaged = false;
      }
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        "Can't save changes",
        errorMessage,
        5000
      );
    } finally {
      this.updateInProgress = false;
    }
    return isOk;
  }

  removeLogo(): void {
    this.organisationLogo = "";
    this.userDataChange();
  }

  // #region upload
  onImageSelected(entity: ImageGalleryEntity | null): void {
    this.organisationLogo = entity ? entity.OptimizedFilename ? entity.OptimizedFilename : entity.Filename : '';
    this.userDataChange();
  }
  // #endregion upload

  // #region remove organisation
  authStore = useAuthStore();

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  displayDeleteConfirmationModal = false;

  openDeleteConfirmationDialog(): void {
    this.displayDeleteConfirmationModal = true;
  }

  async deleteOrganisation(): Promise<void> {
    if (this.organisationStore.currentOrganisation) {
      // generate authKey for another organisation
      const anotherOrganisation = this.organisationStore.entities?.find(x => x.Id !== this.organisationStore.currentOrganisation?.Id);
      let anotherOrganisationAuthKey = "";
      if (anotherOrganisation) {
        const loginAsBody: AUTH_LoginAs = {
          Username: this.allUserData.userName,
          Organisation: anotherOrganisation.Name,
          Place: window.location.host
        };
        anotherOrganisationAuthKey = await this.authStore.loginAs(loginAsBody);
      }

      // delete organisation
      await this.organisationStore.delete(this.organisationStore.currentOrganisation);
      if (!this.organisationStore.deleteError) {
        this.displayDeleteConfirmationModal = false;

        // switch to another organisation using previously generated authKey or logout
        if (anotherOrganisationAuthKey && anotherOrganisation) {
          const angularCredentials = {
            OrgId: anotherOrganisation.Id,
            Organisation: anotherOrganisation.Name,
            username: this.allUserData.userName,
            password: "",
            Place: window.location.host
          };
          window.angularInitService.processLogin(angularCredentials, anotherOrganisationAuthKey);
        } else {
          NavigationHelper.logout();
        }
      }
    }
  }
  // #endregion remove organisation
}

export default OrganisationEditProfileView;
</script>