<template>
  <div class="dashboard-comments">
    <div v-if="!spaceCommentsState.isLoaded || !spaceCommentsState.isLoadedReaction || !authState.isLoadedAllUsers" class="dashboard-comments-spinner">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
    </div>
    <div v-else>
      <div class="dashboard-comments-add field mb-0" v-if="commentsPermissions === Permission.Write">
        <div v-if="replyComment" class="dashboard-comments-reply-person">
          <span @click="scrollToComment(replyComment?.Id)"><span>Replying to <b>{{getFullname(replyComment.Username)}}</b></span></span>
          <Button @click="cancelReply" :disabled="spaceCommentsState.inProgressSave" label="Cancel" class="flex-shrink-0 p-button-link dashboard-comments-cancel-btn"/>
        </div>
        <label class="font-semibold pb-1" v-else>Write a comment</label>
        <div>
          <Textarea 
            v-model="newComment" 
            :disabled="spaceCommentsState.inProgressSave" 
            placeholder="Type your message..." 
            style="white-space: pre-wrap;" 
            :autoResize="true" 
            class="w-full" 
            @keydown.enter="($event) => {
              if ($event.shiftKey) {
                return;
              }
              $event.preventDefault();
              newComment.trim();
              addComment();
            }"
          />
          <div class="inline-flex dashboard-comments-add-btn">
            <Button @click="addComment" :disabled="spaceCommentsState.inProgressSave || !newComment" label="Add" icon="pi pi-send text-xl" class="flex-shrink-0 p-button-lg p-button-icon-only p-button-text p-button-secondary" />
          </div>
        </div>
      </div>

      <div class="overflow-x-hidden">
        <div class="dashboard-comments-empty flex-auto relative" v-if="spaceCommentsState.data?.length === 0">
          <div class="flex flex-column justify-content-center align-items-center">
            <div class="dashboard-comments-empty-img">
              <img
                :src="'/assets/dashboard-comments-placeholder.svg'"
                width="230"
                alt="Bitpool"
              />
            </div>
            <div class="flex-shrink-0 text-center">
              <h4 class="mb-2 mt-3">No comments to show</h4>
              <p class="mb-0 mt-0">Start a conversation with members in your <br>organisation! Share any important updates, notes, <br>or insights using the field above.</p>
            </div>
          </div>
        </div>

        <TransitionGroup name="list">
          <div v-for="comment in spaceCommentsState.data" :key="comment.Id" :id="`comment-${comment.Id}`" class="dashboard-comments-item" :class="{ 'my-comment-highlight': comment.Username === allUserData.userName, 'parent-comment-highlight': highlightCommentId === comment.Id }">
            <div v-if="comment.ReplyTo" class="dashboard-comments-reply-parent">
              <span @click="scrollToComment(comment.ReplyTo)" v-html="scrollToCommentHtml(comment.ReplyTo)"></span>
            </div>
            <div class="flex align-items-start">
              <Avatar v-if="usersDic[comment.Username]?.Avatar" :image="getAvatarUrl(usersDic[comment.Username].Avatar)" size="large" />
              <Avatar v-else :label="shortName(getFullname(comment.Username))" size="large" />

              <div class="flex-auto">
                <div class="dashboard-comments-item-head">             
                  <div>
                    <div class="break-word"><b>{{getFullname(comment.Username)}}</b></div>
                    <div class="dashboard-comments-item-time"><DateTimezoneView :date="comment.DateCreated" timezone="local" :hideSeconds="true"/></div>
                  </div>

                  <Button v-if="comment.Username === allUserData.userName && commentsPermissions === Permission.Write" @click="showMessageMenu($event, comment)" v-tippy="'Actions'" link class="btn-dots"><i class="pi pi-ellipsis-h text-2xl"></i></Button>
                </div>
                
                <div class="dashboard-comments-item-body-wrap">
                  <Transition name="slide-out-editing">
                    <div v-if="editCommentId === comment.Id" class="dashboard-comments-item-body">
                      <div class="dashboard-comments-edit field mb-0">
                        <div>
                          <Textarea v-model="editCommentValue" :disabled="spaceCommentsState.inProgressSave" style="white-space: pre-wrap;" :autoResize="true" class="w-full" />
                          <div class="inline-flex align-items-center column-gap-2">
                            <Button @click="cancelChanges" :disabled="spaceCommentsState.inProgressSave" label="Cancel" class="flex-shrink-0 font-normal p-button-link dashboard-comments-cancel-btn" />
                            <Button @click="saveChanges" :disabled="spaceCommentsState.inProgressSave || !editCommentValue" label="Save" class="flex-shrink-0" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="dashboard-comments-item-body break-word" v-html="markdownToHtml(comment.Comment)"></div>
                  </Transition>
                </div>

                <div class="dashboard-comments-reaction">
                  <DashboardCommentsReactionView :dashboardId="dashboardId" :commentId="comment.Id"/>
                  <Button v-if="commentsPermissions === Permission.Write" label="Reply" class="p-button-link dashboard-comments-reply-btn" @click="reply(comment.Id)"/>
                </div>
              </div>
            </div>
          </div>
        </TransitionGroup>
      </div>
    </div>

    <Menu ref="commentMenu" :model="commentMenuItems" :popup="true" />
  </div>
</template>

<script lang="ts">
import { Permission } from "@/models/enums/Permission";
import AuthState from "@/store/states/AuthState";
import DashboardState from "@/store/states/DashboardState";
import SpaceCommentsState from "@/store/states/SpaceCommentsState";
import { Component, Prop, Vue } from "vue-facing-decorator";
import { Ref, Watch } from "vue-facing-decorator";
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Avatar from 'primevue/avatar';
import { MenuItem } from "primevue/menuitem";
import Menu from "primevue/menu";
import { SpaceCommentsEntity } from "@/models/dashboard/SpaceCommentsEntity";
import { AllUserData } from "@/models/user/AllUserData";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { UserData } from "@/models/user/UserData";
import DashboardCommentsReactionView from "./DashboardCommentsReactionView.vue";
import ConfirmationService from "@/services/ConfirmationService";
import SignalRDataService from "@/services/signalR/SignalRDataService";
import { SpaceCommentsReactionEntity } from "@/models/dashboard/SpaceCommentsReactionEntity";
import ProgressSpinner from 'primevue/progressspinner';
import { nextTick } from "vue";
import DOMPurify from "dompurify";
import { marked } from "marked";

@Component({
  components: {
    Textarea,
    Button,
    Avatar,
    Menu,
    DateTimezoneView,
    DashboardCommentsReactionView,
    ProgressSpinner
  },
})
class DashboardCommentsView extends Vue {
  @Prop({ required: true }) dashboardId!: string;

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get allUserData() : AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get spaceCommentsState(): SpaceCommentsState {
    return this.$store.state.spaceComments;
  }

  Permission = Permission;

  get commentsPermissions(): Permission {
    const result = this.$store.getters["dashboard/getCommentsPermissions"] as Permission;
    return result;
  }

  created(): void {
    this.loadUsers();
    this.loadComments();

    SignalRDataService.subscribe(this.$store.state.apiUrl, "spaceCommentsHub", [this.dashboardId], this.signalRCallback);
  }

  unmount(): void {
    SignalRDataService.unsubscribe(this.$store.state.apiUrl, "spaceCommentsHub", [this.dashboardId]);
    SignalRDataService.removeCallback(this.$store.state.apiUrl, "spaceCommentsHub", this.signalRCallback);
  }

  signalRCallback(event: string, ...args: any[]): void {
    switch (event) {
      case "Updated":{
        const comment = args[0] as SpaceCommentsEntity;
        if (typeof comment.DateCreated === "string") {
          comment.DateCreated = new Date(comment.DateCreated);
        }
        this.$store.commit("spaceComments/addComment", comment);
        break;
      }
      case "Deleted":{
        const commentId = args[0] as string;
        this.$store.commit("spaceComments/deleteComment", commentId);
        break;
      }
      case "Reaction":{
        const commentId = args[0] as string;
        const reaction = args[1] as SpaceCommentsReactionEntity;
        this.$store.commit("spaceComments/updateReaction", reaction);
        break;
      }
    }
  }

  usersDic: Record<string, UserData> = {};

  async loadUsers(): Promise<void> {
    if (!this.authState.isLoadedAllUsers) {
      await this.$store.dispatch("auth/loadAllUsers");
    }
    if (this.authState.allUsers?.length) {
      this.authState.allUsers.forEach(user => {
        this.usersDic[user.Username] = user;
      });
    }
  }
  
  @Watch('dashboardId', { immediate: false, deep: false })
  onDashboardIdChanged(val: string, oldVal: string): void {
    this.loadComments();

    if (oldVal) {
      SignalRDataService.unsubscribe(this.$store.state.apiUrl, "spaceCommentsHub", [oldVal]);
    }
    if (val) {
      SignalRDataService.subscribe(this.$store.state.apiUrl, "spaceCommentsHub", [val], this.signalRCallback);
    }
  }

  async loadComments(): Promise<void> {
    const promises: Promise<any>[] = [];
    promises.push(this.$store.dispatch("spaceComments/loadData", this.dashboardId));
    promises.push(this.$store.dispatch("spaceComments/loadReaction", this.dashboardId));
    await Promise.all(promises); 
  }

  newComment = "";
  newCommentReplyTo: string | null = null;

  async addComment(): Promise<void> {
    const body: SpaceCommentsEntity = {
      Id: "",
      Username: this.allUserData.userName,
      SpaceId: this.dashboardId,
      DateCreated: new Date(),
      Comment: this.newComment,
      ReplyTo: this.newCommentReplyTo
    };
    await this.$store.dispatch("spaceComments/saveComment", body);
    this.newComment = "";
    this.newCommentReplyTo = null;
  }

  cancelReply(): void {
    this.newCommentReplyTo = null;
  }

  reply(commentId: string): void {
    this.newCommentReplyTo = commentId;
    // we need it only if add comment is not sticky
    //document.querySelector(".dashboard-comments-add")?.scrollIntoView({ behavior: 'smooth' });
  }

  get replyComment(): SpaceCommentsEntity | null | undefined {
    return this.newCommentReplyTo ?
      this.spaceCommentsState.data.find(x => x.Id === this.newCommentReplyTo) :
      null;
  }

  getParentComment(commentId: string | null | undefined): SpaceCommentsEntity | null | undefined {
    return commentId ?
      this.spaceCommentsState.data.find(x => x.Id === commentId) :
      null;
  }

  highlightCommentId: string | null = "";

  async scrollToComment(commentId: string | null | undefined): Promise<void> {
    if (commentId) {
      this.highlightCommentId = null;
      // without sticky section
      //document.querySelector(`#comment-${commentId}`)?.scrollIntoView({ behavior: 'smooth' });
      // with sticky section
      const addCommentHeight = document.querySelector(".dashboard-comments-add")?.clientHeight ?? 0;
      const topOfElementToView = (document.querySelector(`#comment-${commentId}`) as HTMLElement).offsetTop;
      const scrollTo = topOfElementToView - addCommentHeight;
      document.querySelector(".p-sidebar-content")?.scrollTo({ top: scrollTo < 0 ? 0 : scrollTo, behavior: "smooth" });
      await nextTick();
      this.highlightCommentId = commentId;
    }
  }

  scrollToCommentHtml(commentId: string | null | undefined): string {
    if (commentId) {
      const parentComment = this.getParentComment(commentId);
      if (parentComment) {
        const fullname = this.getFullname(parentComment.Username);
        return `<span><i class="pi pi-reply mr-2"></i><span><b>${fullname}</b> ${parentComment.Comment}</span></span>`;
      } else {
        return `<i class="empty-parent">Original message was deleted.</i>`;
      }
    } else {
      return "";
    }
  }

  getFullname(username: string | null | undefined): string {
    if (!username) {
      return "";
    }
    let result = username;
    if (this.authState.isLoadedAllUsers && this.usersDic[username]) {
      const user = this.usersDic[username];
      if (user.Firstname || user.Lastname) {
        result = user.Firstname ? user.Firstname : "";
        if (user.Lastname) {
          result = result.length ? `${result} ${user.Lastname}` : user.Lastname;
        }
      }
    }
    return result;
  }

  shortName(fullname: string | null): string {
    return fullname ? fullname.split(" ").map(x=> x[0]).join("") : "";
  }

  getAvatarUrl(avatar: string): string {
    const apiUrl = this.$store.state.apiUrl;
    return `${apiUrl}/rest/AWS_S3_V1/File/${avatar}`;
  }

  markdownToHtml(markdown: string): string {
    const result = DOMPurify.sanitize(marked.parse(markdown));
    return result;
  }

  selectedComment: SpaceCommentsEntity | null = null;
  editCommentId: string | null = null;
  editCommentValue = "";

  async saveChanges(): Promise<void> {
    const comment = this.spaceCommentsState.data.find(x => x.Id === this.editCommentId);
    if (comment) {
      comment.Comment = this.editCommentValue
      await this.$store.dispatch("spaceComments/saveComment", comment);
      this.editCommentId = null;
      this.editCommentValue = "";
    }
  }

  cancelChanges(): void {
    this.editCommentId = null;
    this.editCommentValue = "";
  }

  @Ref() readonly commentMenu!: Menu;
  
  get commentMenuItems(): MenuItem[] {
    return [
      {
        label: 'Edit',
        icon: undefined,
        command: () => {
          if (this.selectedComment) {
            this.editCommentId = this.selectedComment.Id;
            this.editCommentValue = this.selectedComment.Comment;
          }
        }
      },
      {
        label: 'Delete',
        icon: undefined,
        command: () => {
          const message = `Are you sure you want to delete comment?`;
          ConfirmationService.showConfirmation({
            message: message,
            header: 'Delete Comment',
            icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
            acceptIcon: 'pi pi-check',
            rejectIcon: 'pi pi-times',
            rejectClass: 'p-button-secondary p-button-text',
            accept: () => {
              // callback to execute when user confirms the action
              if (this.selectedComment) {
                this.$store.dispatch("spaceComments/delete", this.selectedComment.Id);
              }
            },
            reject: () => {
              // callback to execute when user rejects the action
            }
          });
        }
      }
    ];
  }

  showMessageMenu(event: Event, comment: SpaceCommentsEntity): void {
    this.selectedComment = comment;
    this.commentMenu.toggle(event);
  }
}

export default DashboardCommentsView;
</script>
