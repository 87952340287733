<template>
  <div class="grid formgrid report-general-settings-data-source">
    <!-- Basic settings -->
    <div class="field col-12 mt-0">
      <label for="datasourceName">Name</label>
      <div>
        <InputText class="inputfield w-full" type="text" id="datasourceName" v-model="datasource.Name" />
      </div>
    </div>
    <!-- DRS Grid -->
    <div class="col-12">
      <div class="report-general-settings-colored-box">
        <div class="formgrid grid">
          <div class="col-12">
            <h4 class="mb-0">RDRS</h4>
          </div>
          <!-- Use Report DRS -->
          <div class="field col-12 sm:col-6">
            <div class="flex align-items-center">
              <InputSwitch v-model="datasource.UseReportDRS" inputId="widgetUseGdrs" class="vertical-align-top" />
              <label for="widgetUseGdrs" class="mb-0 ml-2">Use Report Date Range Setpoint (RDRS)</label>
            </div>
          </div>
          <!-- Display Null Indicator -->
          <div class="field col-12 sm:col-6 sm:align-self-center">
            <div class="flex align-items-center">
              <InputSwitch v-model="showNullValues" inputId="widgetDisplayNullIndicator" class="vertical-align-top" />
              <label for="widgetDisplayNullIndicator" class="mb-0 ml-2">Display Data Gaps</label>
            </div>
          </div>
          <!-- Aggregation Period -->
          <div class="field col-12 sm:col-6">
            <label for="widgetAggregationPeriod">Aggregation Period</label>
            <div>
              <Dropdown class="w-full" inputId="widgetAggregationPeriod" v-model="selectedDataAggregation"
                :options="dataAggregations" optionValue="key" optionLabel="name"
                placeholder="Select Data Aggregation" />
            </div>
          </div>
          <!-- Date Range -->
          <div class="field col-12 sm:col-6">
            <label for="widgetDateRange">Date Range</label>
            <div>
              <Dropdown class="w-full" inputId="widgetDateRange" v-model="selectedDateRange" :options="dateRanges"
                optionValue="key" optionLabel="name" placeholder="Select Date Range" @change="setDates(false)" />
            </div>
          </div>
          <!-- Start, End Date Grid -->
          <div class="col-12">
            <div class="formgrid grid">
              <!-- Start Date -->
              <div class="field col-12 sm:col-6">
                <label>Start Date</label>
                <div>
                  <Calendar 
                    class="w-full" 
                    ref="calendarFrom" 
                    v-model="dateFrom" 
                    :maxDate="dateTo" 
                    :showTime="true"
                    dateFormat="dd/mm/yy" 
                    @date-select="onChangeDate()" 
                    @change="onChangeDate()" 
                    panelClass="with-max-width"
                  >
                    <template #footer>
                      <div class="flex justify-content-end pb-3">
                        <Button label="Close" icon="pi pi-times" @click="closeCalendar"
                          class="p-button-text p-button-secondary" />
                      </div>
                    </template>
                  </Calendar>
                </div>
              </div>
              <!-- End Date -->
              <div class="field col-12 sm:col-6">
                <label>End date</label>
                <div>
                  <Calendar 
                    class="w-full" 
                    ref="calendarTo" 
                    v-model="dateTo" 
                    :minDate="dateFrom" 
                    :showTime="true"
                    dateFormat="dd/mm/yy" 
                    @date-select="onChangeDate()" 
                    @change="onChangeDate()" 
                    panelClass="with-max-width"
                  >
                    <template #footer>
                      <div class="flex justify-content-end pb-3">
                        <Button label="Close" icon="pi pi-times" @click="closeCalendar"
                          class="p-button-text p-button-secondary" />
                      </div>
                    </template>
                  </Calendar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import { useReports3Store } from "@/stores/reports3";
import ReportsDatasourceStreamsView from "./ReportsDatasourceStreamsView.vue";
import { TimeRange } from "@/models/enums/TimeRange";
import { Reports3Datasource } from "@/models/reports/v3/Reports3Datasource";
import DateHelper from "@/helpers/DateHelper";
import moment from "moment";
import { debounce } from "throttle-debounce";

@Component({
  components: {
    InputText,
    Button,
    InputSwitch,
    Dropdown,
    Calendar,
    ReportsDatasourceStreamsView
  },
  directives: {
  }
})
class ReportsDatasourceSettingsView extends Vue {
  @Prop({ required: true }) datasource!: Reports3Datasource;
  
  reports3 = useReports3Store();

  created(): void {
    this.fromWidgetConfigToVariables();
  }

  // #region null values
  get showNullValues(): boolean {
    const result = !!this.datasource.Configuration.showNullValues;
    return result;
  }

  set showNullValues(value: boolean) {
    this.datasource.Configuration.showNullValues = value ? 1 : 0;
  }
  // #endregion null values

  // #region data aggregation
  get selectedDataAggregation(): number {
    if (this.datasource.Configuration.autoAggPeriod) {
      return -1;
    } else {
      return this.datasource.Configuration.aggPeriod;
    }
  }

  set selectedDataAggregation(value: number) {
    if (value === -1) {
      this.datasource.Configuration.autoAggPeriod = true;
    } else {
      this.datasource.Configuration.aggPeriod = value;
      this.datasource.Configuration.autoAggPeriod = false;
    }
  }

  dataAggregations = DateHelper.getDataAggregations();
  // #endregion data aggregation

  // #region dates
  dateFrom: Date = new Date();
  dateTo: Date = new Date();
  selectedDateRange = TimeRange.Custom;
  dateRanges = DateHelper.getDateRanges();

  internalWDSUpdate = false;
  @Watch('datasource.Configuration', { immediate: false, deep: true })
  fromWidgetConfigToVariables(): void {
    if (!this.internalWDSUpdate) {
      this.selectedDateRange = this.datasource.Configuration.rangePreset;
      if (this.datasource.Configuration.rangePreset === TimeRange.Custom) {
        const startDateM = moment(`${this.datasource.Configuration.startDate}T${this.datasource.Configuration.startTime}`);
        const endDateM = moment(`${this.datasource.Configuration.endDate}T${this.datasource.Configuration.endTime}`);
        this.dateFrom = startDateM.toDate();
        this.dateTo = endDateM.toDate();
      } else {
        this.setDates(true);
      }
    }
    this.internalWDSUpdate = false;
  }

  fromVariablesToWidgetConfig(): void {
    if (this.selectedDateRange !== TimeRange.Custom) {
      this.datasource.Configuration.rangePresetHolder = this.selectedDateRange;
    }
    this.datasource.Configuration.rangePreset = this.selectedDateRange;
    if (this.selectedDateRange === TimeRange.Custom) {
      const startDateM = moment(this.dateFrom);
      const endDateM = moment(this.dateTo);
      this.datasource.Configuration.startDate = startDateM.format("YYYY-MM-DD");
      this.datasource.Configuration.startTime = startDateM.format("HH:mm:ss");
      this.datasource.Configuration.endDate = endDateM.format("YYYY-MM-DD");
      this.datasource.Configuration.endTime = endDateM.format("HH:mm:ss");
    } else {
      this.datasource.Configuration.startDate = "";
      this.datasource.Configuration.startTime = "";
      this.datasource.Configuration.endDate = "";
      this.datasource.Configuration.endTime = "";
    }
    this.internalWDSUpdate = true;
  }

  setDates(silent: boolean): void {
    const dates = DateHelper.timeRangeToMoments(this.selectedDateRange);
    const start = dates[0];
    const end = dates[1];
    if (start) {
      this.dateFrom = start.toDate();
    }
    if (end) {
      this.dateTo = end.toDate();
    }
    if (!silent) {
      this.fromVariablesToWidgetConfig();
    }
  }

  closeCalendar(): void {
    if (this.$refs.calendarFrom) {
      (this.$refs.calendarFrom as any).overlayVisible = false;
    }
    if (this.$refs.calendarTo) {
      (this.$refs.calendarTo as any).overlayVisible = false;
    }
  }

  onChangeDate(): void {
    this.debounceSelectedDateRangeTrigger();
  }

  debounceSelectedDateRangeTrigger = debounce(500, this.onSelectedDateRangeChanged);

  onSelectedDateRangeChanged(): void {
    this.selectedDateRange = TimeRange.Custom;

    this.fromVariablesToWidgetConfig();
  }
  // #endregion dates
}

export default ReportsDatasourceSettingsView;
</script>