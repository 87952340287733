<template>
  <div class="widget-type-limittracker">    
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-show="!isLodingData && !isNoData" :class="limitIsReached ? 'widget-type-limittracker-stop' : 'widget-type-limittracker-available'">
      <div class="widget-type-limittracker-count">
        <span ref="textContainer" :style="[ {color: textColor} ]">
          {{value}}
        </span><!--
        --><span ref="textContainer2" :style="[ {color: textColor} ]">
          /{{limitThreshold}}
        </span>
      </div>
      <p class="widget-type-limittracker-state">
        {{state}}
      </p>
      <p class="widget-type-limittracker-text">
        {{quantifier}}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import ColorHelper from '@/helpers/ColorHelper';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { nextTick, PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import fitty, { FittyInstance, FittyOptions } from 'fitty';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import DataHelper from '@/helpers/DataHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import numbro from "numbro";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import SignalRDataService from "@/services/signalR/SignalRDataService";
import { throttle } from 'throttle-debounce';

@Component({
  components: {
    ProgressSpinner,
    WidgetNoDataView
  }
})
class LimitTrackerWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  get backgroundColor(): string {
    const result = this.aws?.widgetColorHex ? this.aws?.widgetColorHex : '';
    return result;
  }

  set backgroundColor(color: string) {
    if(this.aws?.widgetColorHex) {
      this.aws.widgetColorHex = color;
    }
  }

  get textColor(): string {
    return ColorHelper.getContrastColor(this.selectedColor!);
  }

  get limitThreshold(): number {
    return this.aws?.limitThreshold ?? 0;
  }

  get underLimitColor(): string {
    const result = this.aws?.underLimitColor ? this.aws?.underLimitColor : '';
    return result;
  }

  get overLimitColor(): string {
    const result = this.aws?.overLimitColor ? this.aws?.overLimitColor : '';
    return result;
  }

  streamKey = "";
  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  requestBody: AggregatedDataRequest | null = null;
  value = 0;
  state : undefined | string = "Available";
  quantifier : undefined | string = "You may enter";
  selectedColor : undefined | string = "";
  fittyInstance: FittyInstance | null = null;
  fittyInstance2: FittyInstance | null = null;
  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();
  dataRefreshInterval = 0;
  limitIsReached = false;

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.initFitty();
    this.emitter.on("size_changed", this.gridSizeChangedEvent);
  }

  unmounted(): void {
    if (this.fittyInstance) {
      this.fittyInstance.unsubscribe();
    }
    if (this.fittyInstance2) {
      this.fittyInstance2.unsubscribe();
    }
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
    this.emitter.off("size_changed", this.gridSizeChangedEvent);

    const streamKeys = this.wds?.streamOptions.map(x => x.StreamKey).filter(x => x);
    if (streamKeys?.length) {
      SignalRDataService.unsubscribe(this.$store.state.apiUrl, "streamHub", streamKeys);
    }
    SignalRDataService.removeCallback(this.$store.state.apiUrl, "streamHub", this.signalRCallback);
  }

  signalRKeys = new Set();

  signalRCallback(streamKey: string): void {
    this.signalRKeys.add(streamKey);
    this.signalRCallbackThrottle();
  }

  signalRCallbackThrottle = throttle(5000, this.onSignalRCallback, { noLeading: true });

  signalRCallbackActual(): void {
    this.signalRKeys.forEach(streamKey => {
      if(streamKey == this.streamKey) {
        this.reloadData(true, false);
      }
    });
    this.signalRKeys.clear();
  }

  onSignalRCallback(): void {
    this.signalRCallbackActual();
  }

  gridSizeChangedEvent(): void {
    this.recalcFontSize();
  }

  initFitty(): void {
    if (this.$refs.textContainer) {
      const params = {
        minSize: 28,
        maxSize: 150,
        observeMutations: false
      } as unknown;
      (fitty as any).observeWindowDelay = 110;
      this.fittyInstance = fitty(this.$refs.textContainer as HTMLElement, params as FittyOptions);
    }

    if (this.$refs.textContainer2) {
      const params = {
        minSize: 20,
        maxSize: 45,
        observeMutations: false
      } as unknown;
      (fitty as any).observeWindowDelay = 110;
      this.fittyInstance2 = fitty(this.$refs.textContainer2 as HTMLElement, params as FittyOptions);
    }
  }

  @Watch('aws', { immediate: false, deep: true })
  @Watch("widgetSize", { immediate: false, deep: true })
  @Watch("editMode", { immediate: false, deep: false })
  async recalcFontSize(): Promise<void> {
    if (this.fittyInstance) {
      this.fittyInstance.unsubscribe();
      await nextTick();
      this.initFitty();
    } 
    if (this.fittyInstance2) {
      this.fittyInstance2.unsubscribe();
      await nextTick();
      this.initFitty();
    }         
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      //set color to default
      const colorOverwriteArg = `guid:${this.widgetConfig.guid};color:NONE`;
      this.emitter.emit("widgetColorOverwrite", colorOverwriteArg);
      this.isLodingData = true;
      this.isNoData = false;
    }

    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (this.signalRKeys.size === 0 && this.wds.streamOptions[0].StreamKey) {
        this.streamKey = this.wds.streamOptions[0].StreamKey;
        SignalRDataService.subscribe(this.$store.state.apiUrl, "streamHub", [this.wds.streamOptions[0].StreamKey], this.signalRCallback);
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.requestBody = requestBody;
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      } else if (streamData.Data && streamData.Data.length && 
        typeof streamData.Data[0].y === "number") {
        const decimals = this.aws?.decimals ? this.aws.decimals : 0;
        const resultVal = numbro(streamData.Data[0].y).format({
          thousandSeparated: true,
          mantissa: decimals < 0 ? 0 : decimals
        });
        this.value = +resultVal;
        this.setState();
        this.recalcFontSize();
      } else {
        this.value = 0;
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
        //set color to default
        const colorOverwriteArg = `guid:${this.widgetConfig.guid};color:NONE`;
        this.emitter.emit("widgetColorOverwrite", colorOverwriteArg);
      }
    });
  }

  setState() {
    switch (this.aws?.limitRuleOperator) {
      case "gt":
        if (this.value > this.limitThreshold) {
          //under limit
          this.selectedColor = this.underLimitColor;
          this.state = this.aws?.underLimitState;
          this.quantifier = this.aws?.underLimitText;
          this.limitIsReached = false;
        } else {
          //over limit
          this.selectedColor = this.overLimitColor;
          this.state = this.aws?.overLimitState;
          this.quantifier = this.aws?.overLimitText;
          this.limitIsReached = true;
        }

        break;
      case "gte":
        if (this.value >= this.limitThreshold) {
          //under limit
          this.selectedColor = this.underLimitColor;
          this.state = this.aws?.underLimitState;
          this.quantifier = this.aws?.underLimitText;
          this.limitIsReached = false;
        } else {
          //over limit
          this.selectedColor = this.overLimitColor;
          this.state = this.aws?.overLimitState;
          this.quantifier = this.aws?.overLimitText;
          this.limitIsReached = true;
        }

        break;
      case "lt":
        if (this.value < this.limitThreshold) {
          //under limit
          this.selectedColor = this.underLimitColor;
          this.state = this.aws?.underLimitState;
          this.quantifier = this.aws?.underLimitText;
          this.limitIsReached = false;
        } else {
          //over limit
          this.selectedColor = this.overLimitColor;
          this.state = this.aws?.overLimitState;
          this.quantifier = this.aws?.overLimitText;
          this.limitIsReached = true;
        }
        
        break;
      case "lte":
        if (this.value <= this.limitThreshold) {
          //under limit
          this.selectedColor = this.underLimitColor;
          this.state = this.aws?.underLimitState;
          this.quantifier = this.aws?.underLimitText;
          this.limitIsReached = false;
        } else {
          //over limit
          this.selectedColor = this.overLimitColor;
          this.state = this.aws?.overLimitState;
          this.quantifier = this.aws?.overLimitText;
          this.limitIsReached = true;
        }

        break;
      default:
        if (this.value < this.limitThreshold) {
          //under limit
          this.selectedColor = this.underLimitColor;
          this.state = this.aws?.underLimitState;
          this.quantifier = this.aws?.underLimitText;
          this.limitIsReached = false;
        } else {
          //over limit
          this.selectedColor = this.overLimitColor;
          this.state = this.aws?.overLimitState;
          this.quantifier = this.aws?.overLimitText;
          this.limitIsReached = true;
        }

        break;
    }
    const colorOverwriteArg = `guid:${this.widgetConfig.guid};color:${this.selectedColor}`;
    this.emitter.emit("widgetColorOverwrite", colorOverwriteArg);
  }
}
export default LimitTrackerWidget;
</script>