<template>
  <div class="widget-type-mqtt-control widget-type-mqtt-control-numeric">
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-if="isLodingData">
      <ProgressSpinner style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" class="spinner-primary" />
    </div>
    <div v-show="!isLodingData" class="widget-type-mqtt-control-inner">
      <div>
        <InputNumber
          v-model="value"
          showButtons
          buttonLayout="horizontal"
          :step="0.25"
          :min-fraction-digits="2"
          :max-fraction-digits="20"
          :prefix="prefix"
          :suffix="suffix"
          decrementButtonClass="p-button-primary"
          incrementButtonClass="p-button-primary"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          @input="onValueChanged()"
          :disabled="inProgressWrite"
          @focus="isInputActive = true"
          @blur="isInputActive = false"
        />
      </div>
      <div class="flex align-items-center flex-shrink-0 justify-content-end">
        <Button
          label="Release"
          :disabled="inProgressWrite"
          @click="applyChanges(null)"
          class="p-button-outlined widget-type-mqtt-control-apply-btn mr-auto"
          v-if="isNubeIOOnly"
        />
        <Button
          :label="isNubeIOOnly ? 'Command' : 'Apply'"
          :disabled="!isChanged || inProgressWrite"
          @click="applyChanges(value)"
          class="widget-type-mqtt-control-apply-btn"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import DataHelper from '@/helpers/DataHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import { useMQTTStore } from '@/stores/mqtt';
import { JSStreamLog } from '@/models/email-import/JSStreamLog';
import SignalRDataService from "@/services/signalR/SignalRDataService";
import { throttle } from 'throttle-debounce';
import { useTagManagerStore } from '@/stores/tagManager';

@Component({
  components: {
    ProgressSpinner,
    InputNumber,
    Button
  }
})
class MQTTNumericControlWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }
  
  tagManagerStore = useTagManagerStore();

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  isLodingData = false;
  chartData: AggregatedDataHighchartsResponse[] = [];
  requestBody: AggregatedDataRequest | null = null;
  value = 0;
  isChanged = false;

  get prefix(): string | undefined {
    if (this.aws?.widgetUnit && this.aws?.widgetUnitPos === 0) {
      return this.aws.widgetUnit;
    }
    return undefined;
  }
  get suffix(): string | undefined {
    if (this.aws?.widgetUnit && this.aws?.widgetUnitPos === 1) {
      return this.aws.widgetUnit;
    }
    return undefined;
  }

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  unmounted(): void {
    const streamKeys = this.wds?.streamOptions.map(x => x.StreamKey).filter(x => x);
    if (streamKeys?.length) {
      SignalRDataService.unsubscribe(this.$store.state.apiUrl, "streamHub", streamKeys);
    }
    SignalRDataService.removeCallback(this.$store.state.apiUrl, "streamHub", this.signalRCallback);
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }

  streamsTags: Record<string, string[]> = {};

  get isNubeIOOnly(): boolean {
    const streamKeys = Object.keys(this.streamsTags);
    const result = streamKeys.every(x => this.streamsTags[x].includes("nubeIO"));
    return result;
  } 

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          if (requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      const streamKeys = this.wds?.streamOptions.map(x => x.StreamKey).filter(x => x);
      const oldStreamKeys = Object.keys(this.streamsTags);
      if (streamKeys.length !== oldStreamKeys.length || streamKeys.every(x => oldStreamKeys.includes(x))) {
        if (streamKeys.length > 0) {
          this.streamsTags = await this.tagManagerStore.getStreamsTags(streamKeys);
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      this.refreshSignalR();
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      }
      this.isLodingData = false;
    } else {
      this.isLodingData = false;
    }
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.requestBody = requestBody;
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      } else if (streamData.Data && streamData.Data.length && 
        (typeof streamData.Data[0].y === "number")) {
        this.value = streamData.Data[0].y;      
      } else {
        this.value = 0;
      }
    });
  }

  onValueChanged(): void {
    this.isChanged = true;
  }

  mqttStore = useMQTTStore();
  inProgressWrite = false;

  async applyChanges(value: number | null): Promise<void> {
    const streamKeys: string[] = this.wds?.streamOptions.filter(x => x.StreamKey).map(x => x.StreamKey) ?? [];
    if (streamKeys.length === 0) {
      ToastService.showToast(
        "error",
        "Error",
        "Please select streams",
        5000
      );
      return;
    }
    const data: JSStreamLog = {
      name: "", // api will fill it
      date: new Date(),
      value: value,
      valueStr: null
    };
    this.inProgressWrite = true;
    const result = await this.mqttStore.write(streamKeys, data);
    this.inProgressWrite = false;
    if (!result) {
      this.isChanged = false;
    }
  }

  // #region SignalR
  streamKeysSubscribed: string[] = [];
  isInputActive = false;

  signalRCallback(streamKey: string): void {
    this.signalRCallbackThrottle();
  }

  signalRCallbackThrottle = throttle(1000, this.onSignalRCallback, { noLeading: true });

  onSignalRCallback(): void {
    if (!this.isInputActive && !this.isChanged) {
      this.reloadData(true, false);
    }
  }

  refreshSignalR(): void {
    const streamKeys = this.wds?.streamOptions.map(x => x.StreamKey).filter(x => x);
    if (streamKeys && streamKeys.length > 0) {
      const newStreamKeys = streamKeys.filter(x => !this.streamKeysSubscribed.includes(x));
      const deleteStreamKeys = this.streamKeysSubscribed.filter(x => !streamKeys.includes(x));
      // unsub
      SignalRDataService.unsubscribe(this.$store.state.apiUrl, "streamHub", deleteStreamKeys);
      this.streamKeysSubscribed = this.streamKeysSubscribed.filter(x => !deleteStreamKeys.includes(x));
      // sub
      SignalRDataService.subscribe(this.$store.state.apiUrl, "streamHub", newStreamKeys, this.signalRCallback);
      this.streamKeysSubscribed.push(...newStreamKeys);
    }
  }
  // #endregion SignalR
}

export default MQTTNumericControlWidget;
</script>