export enum ScriptAdditionalParameterType {
  String,
  Number,
  Boolean,
  Color,
  Image,
  StringMultiLine,
  FontFamily,
  FontSize,
  FontWeight,
  FontStyle,
  TextAlign
}