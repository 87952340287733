<template>
  <div v-if="reports3.dataOne">
    <div class="mb-3 pb-1">
      <Button label="Add" icon="pi pi-plus" class="p-button-outlined" @click="addDatasource" />
    </div>
    <DataTable
      :value="reports3.dataOne.Datasources" 
      dataKey="Uid"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint">
      <template #empty>
        <div v-if="reports3.isLoadedOne" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column field="Name" header="Name" headerStyle="min-width: min-content;" headerClass="no-break-word"></Column>
      <Column 
        :exportable="false" 
        headerStyle="width: 1%; min-width: 88px;" 
        bodyStyle="text-align: right; justify-content: flex-end;"
      >
        <template #body="slotProps">
          <div>
            <div class="inline-flex">
              <Button 
                icon="pi pi-pencil" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openDatasource(slotProps.data)"
                v-tippy="'Edit'"
              />
              <Button 
                icon="pi pi-trash" 
                class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                @click="openDatasourceConfirmation(slotProps.data)" 
                v-tippy="'Delete'"
              />
            </div>
          </div>
        </template>
      </Column>
    </DataTable>

    <Dialog header="Data Source" v-model:visible="displayDatasourceDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content" v-if="selectedDatasource">
        <ReportsDatasourceSettingsView :datasource="selectedDatasource"/>
        <ReportsDatasourceStreamsView :datasource="selectedDatasource"/>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="displayDatasourceDialog = false" class="p-button-text p-button-secondary"/>
        <Button label="Save" icon="pi pi-check" @click="saveDatasource" />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from "primevue/dialog";
import { useReports3Store } from "@/stores/reports3";
import ConfirmationService from "@/services/ConfirmationService";
import ReportsDatasourceSettingsView from "./ReportsDatasourceSettingsView.vue";
import ReportsDatasourceStreamsView from "./ReportsDatasourceStreamsView.vue";
import { v4 as uuidv4 } from "uuid";
import { TimeRange } from "@/models/enums/TimeRange";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";
import { Reports3Datasource } from "@/models/reports/v3/Reports3Datasource";
import { useReports3DataStore } from "@/stores/reports3Data";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";

@Component({
  components: {
    InputText,
    Button,
    DataTable,
    Column,
    ProgressSpinner,
    Dialog,
    ReportsDatasourceSettingsView,
    ReportsDatasourceStreamsView
  },
  directives: {
  }
})
class ReportsDatasourcesView extends Vue {
  reports3 = useReports3Store();
  reports3Data = useReports3DataStore();

  addDatasource(): void {
    if (this.reports3.dataOne) {
      let i = 1;
      let uniqueName = `Data source ${i}`;
      while (this.reports3.dataOne.Datasources.some(ds => ds.Name === uniqueName)) {
        i++;
        uniqueName = `Data source ${i}`;
      }
      const ds = {
        Uid:  uuidv4(),
        Name: uniqueName,
        UseReportDRS: true,
        Configuration: {
          streamOptions: [],
          aggType: undefined,
          showNullValues: 1,

          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
        }
      };
      this.reports3.dataOne.Datasources.push(ds);
      this.openDatasource(ds);
    }
  }

  selectedDatasource: Reports3Datasource | null = null;
  displayDatasourceDialog = false;

  openDatasource(datasource: Reports3Datasource): void {
    this.selectedDatasource = JSON.parse(JSON.stringify(datasource));
    this.displayDatasourceDialog = true;
  }

  saveDatasource(): void {
    if (this.reports3.dataOne && this.selectedDatasource) {
      const index = this.reports3.dataOne.Datasources.findIndex(x => x.Uid === this.selectedDatasource!.Uid);
      if (index >= 0) {
        this.reports3.dataOne.Datasources[index] = this.selectedDatasource;
      }
      this.displayDatasourceDialog = false;
      this.loadData(this.selectedDatasource);
    }
  }
  
  getElement(id: string): Reports3ElementEntity | undefined {
    if (this.reports3.dataOneElements?.length) {
      const element = this.reports3.dataOneElements.find((x) => x.Id === id);
      return element;
    }
    return undefined;
  }

  async loadData(datasource: Reports3Datasource): Promise<void> {
    if (this.reports3.dataOne) {
      // Load data sources
      const elementConfigurations = this.reports3.findAllElementConfigurations(this.reports3.dataOne.Items);
      const promises = [];
      for (const elementConfiguration of elementConfigurations) {
        if (datasource.Uid !== elementConfiguration.DatasourceId) {
          continue;
        }
        const element = this.getElement(elementConfiguration.ElementId);
        if (!element) {
          continue;
        }
        const promise = this.reports3Data.load(element, elementConfiguration, datasource, this.reports3.dataOne);
        promises.push(promise);
      }
      await Promise.all(promises);
    }
  }

  openDatasourceConfirmation(datasource: Reports3Datasource): void {
    const message = `Are you sure you want to delete ${datasource.Name}?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.reports3.dataOne) {
          const index = this.reports3.dataOne.Datasources.findIndex(x => x.Uid === datasource.Uid);
          if (index >= 0) {
            this.reports3.dataOne.Datasources.splice(index, 1);
          }
          this.cleanupDatasourceId(datasource);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  async cleanupDatasourceId(datasource: Reports3Datasource): Promise<void> {
    if (this.reports3.dataOne) {
      // Load data sources
      const elementConfigurations = this.reports3.findAllElementConfigurations(this.reports3.dataOne.Items);
      for (const elementConfiguration of elementConfigurations) {
        if (datasource.Uid !== elementConfiguration.DatasourceId) {
          continue;
        }
        elementConfiguration.DatasourceId = null;
      }
    }
  }
}

export default ReportsDatasourcesView;
</script>