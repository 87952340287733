<template>
  <div class="stream-details stream-details-visual mt-5 md:mt-4 xl:mt-5">
    <header class="stream-details-visual-header">
      <div class="md:flex align-items-center justify-content-between gap-3 md:pb-2 lg:w-full">
        <h2 class="mb-3 md:mb-0">Data Visualisation</h2>
        <div v-if="stream?.DataType === 0" class="min-w-min md:w-13rem">
          <SelectButton 
            v-model="selectedDataView" 
            @change="loadData(false)" 
            :options="dataViews" 
            optionValue="key" 
            optionLabel="name" 
            :allowEmpty="false"
          />
        </div>
      </div>
      <div class="stream-details-visual-time-filters">
        <label><span>Date Range</span><span v-tippy="'Date range refers to the period for which you want to see data. You can select different date ranges using the dropdown or manually entering start and end dates.'"><HelpIconSvg/></span></label>
        <div>
          <div class="stream-details-visual-time-data-range">
            <Dropdown 
              v-model="selectedDateRange" 
              :options="dateRanges" 
              optionValue="key" 
              optionLabel="name" 
              placeholder="Select Date Range" 
              @change="setDates(false)" 
            />
          </div>
          <div class="stream-details-visual-time-start-date">
            <span>Start</span>
            <Calendar 
              ref="calendarFrom" 
              v-model="dateFrom" 
              :maxDate="dateTo" 
              :showTime="true" 
              :showSeconds="true"
              dateFormat="dd/mm/yy" 
              @date-select="onChangeDate()" 
              @change="onChangeDate()" 
              panelClass="with-max-width"
            >
              <template #footer>
                <div class="flex justify-content-end pb-3">
                  <Button label="Close" icon="pi pi-times" @click="closeCalendar" class="p-button-text p-button-secondary"/>
                </div>
              </template>
            </Calendar>
          </div>
          <div class="stream-details-visual-time-end-date">
            <span>End</span>
            <Calendar 
              ref="calendarTo" 
              v-model="dateTo" 
              :minDate="dateFrom" 
              :showTime="true" 
              :showSeconds="true"
              dateFormat="dd/mm/yy"
              @date-select="onChangeDate()" 
              @change="onChangeDate()" 
              panelClass="with-max-width"
            >
              <template #footer>
                <div class="flex justify-content-end pb-3">
                  <Button label="Close" icon="pi pi-times" @click="closeCalendar" class="p-button-text p-button-secondary"/>
                </div>
              </template>
            </Calendar>
          </div>
        </div>
      </div>

      <div class="stream-details-visual-aggregation-filters" v-if="selectedDataView === 0 && stream?.DataType === 0 && streamAdditional">
        <label><span>Aggregation</span><span v-tippy="'The aggregation is the summarization of any set of raw data (over a specified time period) for statistical analysis.'"><HelpIconSvg/></span></label>
        <div>
          <div class="stream-details-visual-aggregation-type">
            <span>Type</span>
            <Dropdown 
              v-model="aggregateType" 
              :options="aggregateTypes" 
              optionValue="key" 
              optionLabel="name" 
              placeholder="Aggregation type"
              @change="onChangeAggregationType"
            />
          </div>
          <div class="stream-details-visual-aggregation-period">
            <span>Period</span>
            <Dropdown 
              v-model="aggregationPeriod" 
              :options="aggregationPeriods" 
              optionValue="key" 
              optionLabel="name" 
              placeholder="Aggregation period"
              @change="onChangeAggregationPeriod"
            />
          </div>
        </div>
      </div>

      <div class="stream-details-visual-value-filters" v-if="selectedDataView === 1 && stream?.DataType === 0">
        <label><span>Value Filters</span><span v-tippy="'Filter data by value.'"><HelpIconSvg/></span></label>
        <div>
          <div class="stream-details-visual-value-from">
            <span>From</span>
            <InputNumber v-model="minValueBind" @input="onChangeMinValue" mode="decimal" :minFractionDigits="2" />
          </div>
          <div class="stream-details-visual-value-to">
            <span>To</span>
            <InputNumber v-model="maxValueBind" @input="onChangeMaxValue($event)" mode="decimal" :minFractionDigits="2" />
          </div>
        </div>
      </div>

      <div class="stream-details-visual-data-filters" v-if="selectedDataView === 1 && stream?.DataType === 0 && stream?.PostProcessingType">
        <label><span>Data Type</span><span v-tippy="'You can select a data type to display processed or raw data.'"><HelpIconSvg/></span></label>
        <div>
          <div class="stream-details-visual-data-range">
            <Dropdown 
              v-model="selectedPostProcessing" 
              :options="postProcessings" 
              optionValue="key" 
              optionLabel="name" 
              placeholder="Data Type"
              @change="loadData(false)"
            />
          </div>
        </div>
      </div>
    </header>

    <div class="stream-details-visual-body mt-2 pt-1 lg:mt-3 lg:pt-0" v-if="stream">
      <div class="stream-details-visual-body-inner" v-if="selectedDataView === 0">
        <highcharts v-if="isLoadedChartData && chartData && chartData.length" ref="chartElement" class="data-chart" :options="chartOptions"></highcharts>
        <div v-else-if="!isLoadedChartData" class="flex justify-content-center align-items-center flex-auto" style="min-height: 400px;">
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
        </div>
        <div v-else>No data found</div>
        <Button 
          v-if="isZoomed" 
          @click="resetZoom" 
          icon="pi pi-search-minus text-xl font-bold" 
          v-tippy="'Reset Zoom'" 
          class="p-button-icon-only p-button-rounded p-button-light chart-zoom-out"/>
      </div>
      
      <div class="stream-details-visual-body-inner" v-else-if="selectedDataView === 1">
        <DataTable 
          :value="logsPageData" 
          v-model:selection="selectedRecords"
          dataKey="Timestamp"
          :totalRecords="logsPageTotal" 
          :paginator="true" 
          :rows="10" 
          :lazy="true" 
          @page="onPage($event)" 
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown" 
          :rowsPerPageOptions="[10,20,50]" 
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
          showGridlines 
          stripedRows 
          responsiveLayout="stack" 
          breakpoint="850px"
          class="p-datatable-sm default-visual-table responsive-breakpoint default-visual-table-stack-label-width stream-details-visual-table"
        >
          <template #header>
            <div v-if="stream.DataType === 0" class="table-header">
              <Button 
                v-if="!stream.PostProcessingType || selectedPostProcessing" 
                label="Add" 
                v-tippy="'Add new record'"
                icon="pi pi-plus-circle" 
                class="my-1 mr-2" 
                @click="openStreamLogDialog(null, 0)" 
              />
              <Button 
                v-if="!stream.PostProcessingType || selectedPostProcessing" 
                label="Restore" 
                v-tippy="'Delete all manually added records, and restore original data'"
                icon="pi pi-replay" 
                class="p-button-outlined my-1 mr-2" 
                @click="openConfirmationReset" 
              />
              <Button 
                :disabled="exportInProgress" 
                label="Export" 
                v-tippy="'Export all records on the selected date range to a CSV file'"
                :icon="exportInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-cloud-download'" 
                class="p-button-outlined my-1 mr-2" 
                @click="exportRecords" 
              />
              <Button 
                v-if="!stream.PostProcessingType || selectedPostProcessing" 
                label="Delete on date range" 
                v-tippy="'Delete all records on the selected date range'"
                icon="pi pi-trash" 
                class="p-button-outlined p-button-danger my-1 mr-2" 
                @click="openConfirmationRange" 
              />
              <Button 
                v-if="(!stream.PostProcessingType || selectedPostProcessing) && selectedRecords.length" 
                label="Delete selected" 
                v-tippy="'Delete the selected records'"
                icon="pi pi-trash" 
                class="p-button-outlined p-button-danger my-1 mr-2" 
                @click="openConfirmationSelected" 
              />
            </div>
          </template>
          <template #empty>
            <div v-if="isLoadedPage" class="w-full" style="min-height: 195px;">
              <span class="inline-block py-2">No data found.</span>
            </div>
            <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 195px;" v-else>
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 1%; min-width: 3rem;" headerClass="column-with-checkbox" bodyClass="column-with-checkbox" v-if="!stream.PostProcessingType || selectedPostProcessing"></Column>
          <Column field="Timestamp" header="Timestamp">
            <template #body="slotProps">
              <DateTimezoneView :date="slotProps.data.Timestamp" :timezone="timezone" :hideMilliseconds="false"/>
            </template>
          </Column>
          <Column field="Value" header="Value" headerStyle="text-align: right" bodyStyle="text-align: right" bodyClass="content-left-in-stack">
            <template #body="slotProps">
              <span v-if="stream.DataType">{{slotProps.data.ValueString}}</span>
              <span v-else>{{slotProps.data.Value ? slotProps.data.Value.toLocaleString() : 0}}</span>
            </template>
          </Column>
          <Column field="Calculated" header="Calculated" headerStyle="text-align: right" bodyStyle="text-align: right" bodyClass="content-left-in-stack"></Column>
          <Column v-if="stream.DataType === 0 && (!stream.PostProcessingType || selectedPostProcessing)" :exportable="false" headerStyle="width: 1%; min-width: 88px;" bodyStyle="text-align: right; justify-content: flex-end;">
            <template #body="slotProps">
              <div class="inline-flex">
                <Button icon="pi pi-pencil" class="p-button-icon-only p-button-rounded p-button-outlined mr-2" @click="openStreamLogDialog(slotProps.data.Timestamp, slotProps.data.Value)" />
                <Button icon="pi pi-trash" class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" @click="openConfirmation(slotProps.data.Timestamp)" />
              </div>
            </template>
          </Column>
        </DataTable> 
      </div>
    </div>

    <Dialog header="Streamlog" v-model:visible="displayStreamlogDialog" :modal="true" :style="{width: '44rem'}">
      <div class="dialog-content">
        <div v-if="streamlogDataModel" class="field mb-0">
          <label for="streamlogDataModelDate">Timestamp</label>
          <div>
            <Calendar 
              id="streamlogDataModelDate" 
              ref="streamlogDataModelDate" 
              v-model="streamlogDataModel.Date" 
              :showTime="true" 
              :showSeconds="true"
              dateFormat="dd/mm/yy"
              panelClass="with-max-width"
              class="inputfield w-full"
            >
              <template #footer>
                <div class="flex justify-content-end pb-3">
                  <Button label="Close" icon="pi pi-times" @click="closeCalendar" class="p-button-text p-button-secondary"/>
                </div>
              </template>
            </Calendar>
          </div>
        </div>
        <div v-if="streamlogDataModel" class="field mt-3 pt-1 mb-0">
          <label for="streamlogDataModelValue">Value</label>
          <div>
            <InputNumber
              id="streamlogDataModelValue"
              mode="decimal" :minFractionDigits="2" placehoder="Value" 
              class="inputfield w-full"
              v-model="streamlogDataModel.Value"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="closeStreamLogDialog" class="p-button-text p-button-secondary"/>
        <Button v-if="streamlogDataModel" :label="streamlogDataModel.OldDate ? 'Update' : 'Create'" icon="pi pi-check" @click="saveStreamLog" :disabled='!(streamlogDataModel.Date && streamlogDataModel.Value !== null && !updateInProgress)' />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { StreamModel } from "@/models/StreamModel";
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import SelectButton from 'primevue/selectbutton';
import ProgressSpinner from 'primevue/progressspinner';
import { StreamLogsListModel } from "@/models/StreamLogsListModel";
import moment, { Moment } from "moment";
import { ValueEntity } from "@/models/ValueEntity";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { TimeZoneDto } from "@/models/TimeZoneDto";
import { debounce, throttle } from 'throttle-debounce';
import { Watch } from "vue-facing-decorator";
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import { StreamlogDataModel } from "@/models/StreamlogDataModel";
import { StreamAdditionalDto } from "@/models/StreamAdditionalDto";
import { AggregatedDataRequest } from "@/models/AggregatedDataRequest";
import { TimeRange } from "@/models/enums/TimeRange";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";
import EnumHelper from "@/helpers/EnumHelper";
import { AggregationTypeString } from "@/models/enums/AggregationTypeString";
import { AggregateType } from "@/models/enums/AggregateType";
import { StreamValuesHighchartsResponse } from "@/models/StreamValuesHighchartsResponse";
import { AggregationType } from "@/models/enums/AggregationType";
import { StreamDataType } from "@/models/enums/StreamDataType";
import SignalRDataService from "@/services/signalR/SignalRDataService";
import DateHelper from "@/helpers/DateHelper";
import ToastService from "@/services/ToastService";
import ConfirmationService from "@/services/ConfirmationService";
import { DeleteDataRangerModel } from "@/models/DeleteDataRangerModel";
import { DeleteDataManyModel } from "@/models/DeleteDataManyModel";
import HelpIconSvg from "@/components/svg/HelpIconSvg.vue";

@Component({
  components: {
    Button,
    InputText,
    InputNumber,
    Dropdown,
    Calendar,
    DataTable,
    Column,
    Dialog,
    SelectButton,
    ProgressSpinner,
    highcharts: Chart,
    DateTimezoneView,
    HelpIconSvg
  }
})
class StreamDetailsDataView extends Vue {
  get isLoaded(): boolean {
    return this.$store.state.stream.isLoaded;
  }

  get stream(): StreamModel | null {
    return this.$store.state.stream.stream;
  }

  get isLoadedStreamAdditional(): boolean {
    return this.$store.state.stream.isLoadedStreamAdditional;
  }

  get streamAdditional(): StreamAdditionalDto | null {
    return this.$store.state.stream.streamAdditional;
  }

  @Watch('streamAdditional.IsAccumulating', { immediate: false, deep: false })
  @Watch('streamAdditional.AggregateType', { immediate: false, deep: false })
  onChangeStreamAdditional(): void {
    this.loadData(false);
  }

  created(): void {
    if (this.isLoaded && this.stream && this.stream.DataType === StreamDataType.String) {
      this.selectedDataView = 1;
    }
    this.setDates(false);
    if (this.stream) {
      SignalRDataService.subscribe(this.$store.state.apiUrl, "streamHub", [this.stream.Id], this.signalRCallback);
    }
  }

  unmounted(): void {
    if (this.stream) {
      SignalRDataService.unsubscribe(this.$store.state.apiUrl, "streamHub", [this.stream.Id]);
    }
    SignalRDataService.removeCallback(this.$store.state.apiUrl, "streamHub", this.signalRCallback);
  }

  @Watch('stream', { immediate: false, deep: false })
  onChangeStreams(val: StreamModel, oldVal: StreamModel): void {
    this.selectedRecords = [];
    if (oldVal) {
      SignalRDataService.unsubscribe(this.$store.state.apiUrl, "streamHub", [oldVal.Id]);
    }
    if (val) {
      SignalRDataService.subscribe(this.$store.state.apiUrl, "streamHub", [val.Id], this.signalRCallback);
    }
  }

  signalRKeys = new Set();

  signalRCallback(streamKey: string): void {
    this.signalRKeys.add(streamKey);
    this.signalRCallbackThrottle();
  }

  signalRCallbackThrottle = throttle(1000, this.onSignalRCallback, { noLeading: true });

  signalRCallbackActual(): void {
    this.signalRKeys.forEach(streamKey => {
      if (this.stream && this.stream.Id === streamKey) {
        this.$store.dispatch("pool/refreshStream", { poolKey: this.stream.PoolKey, streamKey: streamKey });
        if (this.selectedDataView === 0 || this.selectedDataView === 1) {
          this.setDates(true); // update dates and reload data for chart or table
        }
      }
    });
    this.signalRKeys.clear();
  }

  onSignalRCallback(): void {
    this.signalRCallbackActual();
  }

  // #region aggregation type
  aggregateTypes = [
    {name: 'Default', key: 0}, // None
    {name: 'Average', key: 1},
    {name: 'Maximum', key: 2},
    {name: 'Minimum', key: 3},
    {name: 'Difference', key: 4},
    {name: 'Sum', key: 5},
    // {name: 'HighDuration', key: 6}, deprecated
    // {name: 'LowDuration', key: 7}, deprecated
    {name: 'Accum', key: 8},
    {name: 'First', key: 9},
    {name: 'Last', key: 10}
  ];

  private aggregateTypeLocal: AggregateType | string = "";

  get aggregateType(): AggregateType | string {
    if (this.aggregateTypeLocal === "") {
      return this.streamAdditional?.AggregateType ?? AggregateType.None;
    }
    return this.aggregateTypeLocal;
  }

  set aggregateType(value: AggregateType | string) {
    this.aggregateTypeLocal = value;
  }

  onChangeAggregationType(): void {
    this.loadData(false);
  }
  // #endregion aggregation type

  // #region aggregation period
  get aggregationPeriods(): any[] {
    return DateHelper.getDataAggregations();
  }

  aggregationPeriod: AggregationPeriod | number = -1;

  onChangeAggregationPeriod(): void {
    this.loadData(false);
  }
  // #endregion aggregation period

  // #region dates
  get timezone(): string {
    return this.stream ? this.stream.TimeZone : "UTC";
  }

  get timezoneModel(): TimeZoneDto | undefined {
    const timezone = this.timezones.find((tz) => tz.Id === this.timezone);
    return timezone;
  }

  get timezones(): TimeZoneDto[] {
    return this.$store.state.timezones;
  }
  
  dateFrom: Date = new Date();
  dateTo: Date = new Date();
  selectedDateRange: TimeRange | number = TimeRange.Today;
  get dateRanges(): any[] {
    const result = DateHelper.getDateRanges();
    result.push({ name: "All", key: -1 });
    return result;
  }

  momentToTimezone(m: Moment): Moment {
    const utcOffset = moment().utcOffset();
    const utcOffsetTimezone = 60 * (this.timezoneModel ? this.timezoneModel.UtcOffset : 0);
    const utcOffsetFinal = utcOffsetTimezone - utcOffset;
    return m.add(utcOffsetFinal, "minutes");
  }

  momentFromTimezone(m: Moment): Moment {
    const utcOffset = moment().utcOffset();
    const utcOffsetTimezone = 60 * (this.timezoneModel ? this.timezoneModel.UtcOffset : 0);
    const utcOffsetFinal = utcOffsetTimezone - utcOffset;
    return m.subtract(utcOffsetFinal, "minutes");
  }

  setDates(silent: boolean, forceReload = false): void {
    let start: Moment | null = null;
    let end: Moment | null = null;
    if (this.selectedDateRange === -1) {
      if (this.stream && this.stream.FirstUpdates && this.stream.LastUpdates) {
        start = this.momentToTimezone(moment(this.stream.FirstUpdates as Date).startOf('minute'));
        end = this.momentToTimezone(moment(this.stream.LastUpdates as Date).add(1, "minutes").startOf('minute'));
      }
    } else {
      const dates = DateHelper.timeRangeToMoments(this.selectedDateRange);
      start = dates[0];
      end = dates[1];
    }
    if (start && end) {
      this.dateFrom = start.toDate();
      this.dateTo = end.toDate();
      this.loadData(silent);
    } else if (forceReload) {
      this.loadData(silent);
    }
  }

  closeCalendar(): void {
    if (this.$refs.calendarFrom) {
      (this.$refs.calendarFrom as any).overlayVisible = false;
    }
    if (this.$refs.calendarTo) {
      (this.$refs.calendarTo as any).overlayVisible = false;
    }
    if (this.$refs.streamlogDataModelDate) {
      (this.$refs.streamlogDataModelDate as any).overlayVisible = false;
    }
  }

  onChangeDate(): void {
    this.debounceSelectedDateRangeTrigger();
  }

  debounceSelectedDateRangeTrigger = debounce(500, this.onSelectedDateRangeChanged);

  onSelectedDateRangeChanged(): void {
    this.selectedDateRange = TimeRange.Custom;
    this.loadData(false);
  }
  // #endregion dates

  selectedDataView = 0;

  get dataViews(): any {
    const result = [
      {name: 'Graph', key: 0},
      {name: 'Table', key: 1},
      // {name: 'Manual Entry', key: 2}
    ];
    if (this.isLoaded && this.stream && this.stream.DataType === StreamDataType.String) {
      result.splice(0, 1);
      result.splice(1, 1);
    }
    return result;
  }

  loadData(silent: boolean): void {
    switch (this.selectedDataView) {
      case 0: {
        this.loadChartData(silent);
        break;
      }
      case 1: {
        this.loadTableData(silent);
        break;
      }
    }
  }

  selectedPostProcessing = 0;

  postProcessings = [
    {name: 'Machine Learned', key: 0},
    {name: 'Source', key: 1}
  ];

  // #region chart
  get isLoadedChartData(): boolean {
    return this.$store.state.stream.isLoadedChartData;
  }

  get chartData(): StreamValuesHighchartsResponse[][] {
    return this.$store.state.stream.chartData;
  }

  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  resetZoom(): void {
    const chart = this.getChartElement()
    if (chart && chart.chart) {
      chart.chart.zoomOut();
    }
  }

  isZoomed = false;

  get isDarkTheme(): boolean {
    return !!this.$store.state.auth.userSettings?.darkTheme;
  }

  get tags(): string[] {
    return this.stream && this.stream.Tags ? this.stream.Tags : [];
  }

  get units(): string {
    const tags = this.tags;
    for (const i in tags) {
      const tag = tags[i];
      if (tag.startsWith("unit=")) {
        const result = tag.slice("unit=".length);
        return result;
      }
    }
    return "";
  }

  get chartOptions(): Highcharts.Options {
    const xPlotBands: any[] = [];
    const xPlotColor = this.isDarkTheme ? "#19475f" : "#cecece";
    let startDate: number | undefined = undefined;
    let endDate: number | undefined = undefined;
    for (const serie of this.chartData) {
      for (const item of serie) {
        if (startDate === undefined) {
          if (item.calculated) {
            startDate = item.x;
            endDate = item.x;
          }
        } else {
          if (item.calculated) {
            endDate = item.x;
          }
          else {
            xPlotBands.push({
              color: xPlotColor,
              from: startDate,
              to: endDate
            });
            startDate = undefined;
            endDate = undefined;
          }
        }
      }
    }
    if (startDate) {
      xPlotBands.push({
        color: xPlotColor,
        from: startDate,
        to: endDate
      });
      startDate = undefined;
      endDate = undefined;
    }
    const series: Highcharts.SeriesOptionsType[] = [];
    this.chartData.forEach((serie, index) => {
      series.push({
        type: 'area',
        name: index === 0 ? 'Source' : 'ML',
        color: index === 0 ? "#0072ab" : "#00edff",
        lineWidth: 3,
        marker: {
          symbol: 'circle'
        },
        data: serie.map(x => [x.x, x.y])
      });
    });
    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        animation: true,
        zooming: {
          type: 'x'
        },
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          },
          selection: (event: Highcharts.SelectEventObject): (boolean | undefined) => {
            if (event.resetSelection) {
              this.isZoomed = false;
            } else {
              this.isZoomed = true;
            }
            return true;
          }
        }
      },
      tooltip: {
        useHTML: true,
        outside: true,
        shared: true,
        valueDecimals: 2,
        formatter: function() {
          if (this.points) {
            let result = `<small>${typeof this.x === "number" ? Highcharts.dateFormat('%b %e,%Y %H:%M', this.x) : this.x}</small>`;
            this.points.forEach(point => {
              result += `<br/><span style="color:${point.color};">\u25CF </span>${point.series.name}: <b>${(point.y ?? 0).toFixed(2)}</b>`
            });
            return result;
          } else {
            return `<small>${typeof this.x === "number" ? Highcharts.dateFormat('%b %e,%Y %H:%M', this.x) : this.x}</small><br/><span style="color:${this.color};">\u25CF </span>${this.series.name}: <b>${(this.y ?? 0).toFixed(2)}</b>`;
          }
        },
      },
      xAxis: {
        type: 'datetime',
        gridLineWidth: 1,
        // plotLines: this.chartData.filter(x => x.calculated).map(x => { return {
        //   color: 'red', // Color value
        //   dashStyle: 'Solid',
        //   value: x.x, // Value of where the line will appear
        //   width: 2 // Width of the line    
        // }}),
        plotBands: xPlotBands
      },
      yAxis: {
        title: {
          text: this.units
        }
      },
      legend: {
        enabled: series.length > 0 ? true : false,
        verticalAlign: 'top',
        layout: 'horizontal',
        align: 'left',
        symbolHeight: 8,
        symbolWidth: 8,
        maxHeight: 40
      },
      plotOptions: {
        area: {
          fillOpacity: 0.1,
          lineWidth: 3,
          states: {
            hover: {
              lineWidth: 3
            }
          },
          threshold: null
        }
      },

      series: series
    };
  }

  loadChartData(silent: boolean): void {
    const utcOffset = moment().utcOffset();
    // dates in stream timezone
    const from = moment(this.dateFrom).add(utcOffset, "minutes");
    const to = moment(this.dateTo).add(utcOffset, "minutes");

    const diffDays = to.diff(from, 'days');
    let ap: AggregationPeriod;
    const autoForce = this.aggregationPeriod !== -1 && diffDays >= 365 && (
      this.aggregationPeriod === AggregationPeriod.Raw ||
      this.aggregationPeriod === AggregationPeriod.Minute ||
      this.aggregationPeriod === AggregationPeriod.Minutes5 ||
      this.aggregationPeriod === AggregationPeriod.Minutes10 ||
      this.aggregationPeriod === AggregationPeriod.Minutes15 ||
      this.aggregationPeriod === AggregationPeriod.Minutes30 ||
      this.aggregationPeriod === AggregationPeriod.Hourly ||
      this.aggregationPeriod === AggregationPeriod.Daily);
    if (this.aggregationPeriod === -1 || autoForce) {
      if (diffDays >= 1095)
      {
        ap = AggregationPeriod.Yearly;
      }
      else if (diffDays >= 365)
      {
        ap = AggregationPeriod.Monthly;
      }
      else if (diffDays > 31)
      {
        ap = AggregationPeriod.Weekly;
      }
      else if (diffDays >= 7)
      {
        ap = AggregationPeriod.Daily;
      }
      else if (diffDays >= 1)
      {
        ap = AggregationPeriod.Hourly;
      }
      else if (diffDays > 0.5)
      {
        ap = AggregationPeriod.Minutes15;
      }
      else
      {
        ap = AggregationPeriod.Minutes5;
      }
    } else {
      ap = this.aggregationPeriod;
    }
    if (autoForce) {
      ToastService.showToast(
        "warn",
        "Warning",
        "Please select aggregation period >= weekly for preiod >= 1 year. Otherwise, bitpool will use Auto aggregation.",
        10000
      );
    }
    let at = EnumHelper.aggregateTypeToAggregationType((this.aggregateType ?? AggregateType.None) as AggregateType);
    if (at === AggregationType.None) {
      at = AggregationType.Avg;
    }
    const body: AggregatedDataRequest = {
      TimeFrom: from.toJSON().replaceAll("Z",""),
      TimeTo: to.toJSON().replaceAll("Z",""),
      TimeRange: TimeRange.Custom,
      AggregationPeriod: ap,
      NullWhenNoData: false,
      LocalTimezoneResult: true,
      Streams: [
        {
          StreamKey: this.stream ? this.stream.Id : "",
          AggregationType: at,
          AggregationTypeString: AggregationTypeString.None,
          Multiplier: 1,
          PostProcessIfAvailable: false,
          TimeIntervals: null,
          Time: null,
          AggregationPeriod: ap
        }
      ]
    };
    if (this.stream?.PostProcessingType) {
      body.Streams.push(
        {
          StreamKey: this.stream ? this.stream.Id : "",
          AggregationType: at === AggregationType.Diff ? AggregationType.Sum : at,
          AggregationTypeString: AggregationTypeString.None,
          Multiplier: 1,
          PostProcessIfAvailable: true,
          TimeIntervals: null,
          Time: null,
          AggregationPeriod: ap
        }
      );
    }
    this.$store.dispatch("stream/loadChartData", { silent: silent, body: body });
  }
  // #endregion chart

  // #region table
  get isLoadedPage(): boolean {
    return this.$store.state.stream.isLoadedPage;
  }

  get logsPage(): StreamLogsListModel | null {
    return this.$store.state.stream.logsPage;
  }

  get logsPageData(): ValueEntity[] {
    const data = this.logsPage;
    return data ? data.Data : [];
  }

  get logsPageTotal(): number {
    const data = this.logsPage;
    return data ? data.Total : 0;
  }

  selectedRecords: ValueEntity[] = [];

  take = 10;
  skip = 0;

  onPage(event: any): void {
    // event.page: New page number
    // event.first: Index of first record
    // event.rows: Number of rows to display in new page
    // event.pageCount: Total number of pages
    this.skip = event.page * event.rows;
    this.take = event.rows;
    this.loadTableData(false);
  }

  minValueBind: number | null = null;
  maxValueBind: number | null = null;
  minValue: number | null = null;
  maxValue: number | null = null;
  minValueFinal: number | null = null;
  maxValueFinal: number | null = null;
  onChangeMinValue(event: any): void {
    this.minValue = event.value;
    this.debounceMinMaxValue();
  }
  onChangeMaxValue(event: any): void {
    this.maxValue = event.value;
    this.debounceMinMaxValue();
  }
  debounceMinMaxValue = debounce(500, this.onMinMaxUpdateTriggerChanged);

  onMinMaxUpdateTriggerChanged(): void {
    this.minValueFinal = this.minValue;
    this.maxValueFinal = this.maxValue;
    this.loadTableData(false);
  }

  loadTableData(silent: boolean): void {
    if (this.stream) {
      this.$store.dispatch("stream/loadStreamLogsPage", { silent: silent, query: {
        streamKey: this.stream.Id,
        from: this.momentFromTimezone(moment(this.dateFrom)).toJSON(),
        to: this.momentFromTimezone(moment(this.dateTo)).toJSON(),
        take: this.take,
        skip: this.skip,
        minValue: this.minValueFinal,
        maxValue: this.maxValueFinal,
        postProcessIfAvailable: !this.selectedPostProcessing,
        showDifference: false
      }});
    }
  }
  // #endregion table

  // #region streamlog new/edit
  displayStreamlogDialog = false;
  streamlogDataModel: StreamlogDataModel | null = null;

  openStreamLogDialog(oldDate: Date | null, oldValue: number): void {
    if (this.stream) {
      this.streamlogDataModel = {
        StreamKey: this.stream.Id,
        OldDate: oldDate ? this.momentToTimezone(moment(oldDate)).toDate() : null,
        Date: oldDate ?
          this.momentToTimezone(moment(oldDate)).toDate() :
          this.momentToTimezone(moment().startOf('minute')).toDate(),
        Value: oldValue
      };
      this.displayStreamlogDialog = true;
    }
  }

  closeStreamLogDialog(): void {
    this.displayStreamlogDialog = false;
  }

  get updateInProgress(): boolean {
    return this.$store.state.stream.updateInProgress;
  }

  get updateError(): boolean {
    return this.$store.state.stream.updateError;
  }

  async saveStreamLog(): Promise<void> {
    if (this.streamlogDataModel && this.stream) {
      const streamlogDataModelUtc = {
        StreamKey: this.stream.Id,
        OldDate: this.streamlogDataModel.OldDate ? this.momentFromTimezone(moment(this.streamlogDataModel.OldDate)).toDate() : null,
        Date: this.momentFromTimezone(moment(this.streamlogDataModel.Date)).toDate(),
        Value: this.streamlogDataModel.Value
      };
      await this.$store.dispatch("stream/createUpdateStreamLog", streamlogDataModelUtc);
      if (!this.updateError) {
        this.displayStreamlogDialog = false;
        this.setDates(false, true);
      }
    }
  }
  // #endregion streamlog new/edit

  // #region streamlog delete
  dateDelete: Date | null = null;

  openConfirmation(date: Date): void {
    this.dateDelete = date;
    const message = `Are you sure you want to delete record?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Record',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        if (this.stream) {
          const body = {
            StreamKey: this.stream.Id,
            Date: this.dateDelete
          };
          this.$store.dispatch("stream/deleteStreamLog", body);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
  // #endregion streamlog delete

  // #region streamlogs reset
  openConfirmationReset(): void {
    const message = `Are you sure you want to restore stream? All your manual changes will be reverted.`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Restore',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.$store.dispatch("stream/resetStreamLogs");
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
  // #endregion streamlogs reset

  // #region streamlogs export
  get exportInProgress(): boolean {
    return this.$store.state.stream.exportInProgress;
  }

  exportRecords(): void {
    if (this.stream) {
      const body = {
        StreamKey: this.stream.Id,
        From: this.momentFromTimezone(moment(this.dateFrom)).toJSON(),
        To: this.momentFromTimezone(moment(this.dateTo)).toJSON(),
        FromValue: this.minValueFinal,
        ToValue: this.maxValueFinal
      };
      this.$store.dispatch("stream/exportStreamLogs", body);
    }
  }
  // #endregion streamlogs export

  // #region streamlog delete
  openConfirmationRange(): void {
    const message = `Are you sure you want to delete selected records?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Records',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        if (this.stream) {
          const body: DeleteDataRangerModel = {
            StreamKey: this.stream.Id,
            From: this.momentFromTimezone(moment(this.dateFrom)).toJSON(),
            To: this.momentFromTimezone(moment(this.dateTo)).toJSON(),
          };
          this.$store.dispatch("stream/deleteStreamLogDateRange", body);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  openConfirmationSelected(): void {
    const message = `Are you sure you want to delete selected records?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Records',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteSelectedRecords();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  async deleteSelectedRecords(): Promise<void> {
    if (this.stream && this.selectedRecords.length) {
      const body: DeleteDataManyModel = {
        StreamKey: this.stream.Id,
        Dates: this.selectedRecords.map(x => x.Timestamp)
      };
      await this.$store.dispatch("stream/deleteStreamLogs", body);
      this.selectedRecords = [];
    }
  }
  // #endregion streamlog delete
}

export default StreamDetailsDataView;
</script>