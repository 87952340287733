<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path stroke="var(--report-control-button-color)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12.227 7.17 9.33 4.273M1 15.5l2.451-.272c.3-.034.45-.05.59-.096.124-.04.242-.097.35-.168.124-.081.23-.188.443-.401L14.4 4.997A2.049 2.049 0 0 0 11.503 2.1l-9.566 9.566c-.213.213-.32.32-.4.442a1.449 1.449 0 0 0-.17.351c-.045.14-.061.29-.095.59L1 15.5Z"/>
  </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class ReportEditSvg extends Vue {
}

export default ReportEditSvg;
</script>