<template>
  <div class="flex-auto overflow-y-auto overflow-x-hidden" v-if="editRecord">
    <Accordion class="report-element-settings-accordion" :activeIndex="0">
      <template #collapseicon>
        <i class="p-accordion-toggle-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="2" fill="none"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 1H1"/></svg>
        </i>
      </template>
      <template #expandicon>
        <i class="p-accordion-toggle-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 9H1m8-8v16"/></svg>
        </i>
      </template>
      <AccordionTab header="General">
        <div class="formgrid grid report-element-settings-item">
          <div class="field col-12" v-if="canEditGlobal">
            <div class="flex align-items-center">
              <InputSwitch 
                inputId="editRecordGlobal"
                v-model="selectedEntityIsGlobal"
                class="vertical-align-top"
                :disabled="updateInProgress"
              />
              <label for="editRecordGlobal" class="mb-0 ml-2">Public</label>
            </div>
          </div>
          <div class="field col-12">
            <label for="editRecordName">Name</label>
            <div>
              <InputText 
                id="editRecordName" 
                class="inputfield w-full"
                type="text" 
                v-model="editRecord.Name"
                placeholder="Name"
              />
            </div>
          </div>
          <div class="field col-12">
            <label for="editRecordDescription">Description</label>
            <div>
              <Textarea 
                id="editRecordDescription" 
                class="w-full" 
                v-model="editRecord.Description"
                :autoResize="true" 
                :rows="3"
              />
            </div>
          </div>
          <div class="field col-12 md:col-6">
            <label for="editRecordRole">Role</label>
            <div>
              <Dropdown 
                inputId="editRecordRole"
                v-model="editRecord.Role"
                :options="roles" 
                optionValue="key" 
                optionLabel="name" 
                placeholder="Select roel" 
                class="w-full"
                @change="onRoleChange()"
              />
            </div>
          </div>
          <div class="field col-12 md:col-6" v-if="editRecord.Role === Reports3ElementRole.Body">
            <label for="editRecordDefaultSize">Default Size</label>
            <div>
              <Dropdown 
                inputId="editRecordDefaultSize"
                v-model="editRecord.DefaultSize"
                :options="columns" 
                optionValue="key" 
                optionLabel="name" 
                placeholder="Select size" 
                class="w-full"
              />
            </div>
          </div>
          <div class="field col-12 md:col-6" v-if="editRecord.Role !== 0">
            <label for="editRecordHeight">Height</label>
            <div>
              <InputNumber 
                inputId="editRecordHeight" 
                class="inputfield w-full"
                v-model="editRecord.Height"
                placeholder="Height"
                suffix="cm"
                :min="0"
                :allowEmpty="false"
                :minFractionDigits="2"
                :maxFractionDigits="20"
              />
            </div>
          </div>
          <div class="field col-12 mb-0">
            <label for="editRecordImage">Image</label>
            <div class="img-choose-upload-container" :class="editRecord.Image ? 'with-image-uploaded' : ''">
              <div class="img-choose-upload" :class="editRecord.Image ? 'with-image-uploaded' : ''">
                <span v-if="!editRecord.Image" class="img-choose-upload-placeholder">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 92 104"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M56.312 1v28.966a5.528 5.528 0 0 0 5.526 5.526h28.966M56.312 1 90.8 35.492v61.32a5.528 5.528 0 0 1-5.526 5.527H6.526A5.528 5.528 0 0 1 1 96.813V6.526A5.528 5.528 0 0 1 6.526 1h49.786Zm-45.28 82.454v5.404a2.947 2.947 0 0 0 2.948 2.948h63.966a2.947 2.947 0 0 0 2.947-2.948V73.374c0-.804-.328-1.574-.91-2.13L59.3 51.434a2.95 2.95 0 0 0-4.27.199L34.608 75.249 23.54 67.633a2.948 2.948 0 0 0-4.21.933l-7.896 13.392a2.927 2.927 0 0 0-.41 1.496h.008Zm25.613-41.316a9.531 9.531 0 1 1-19.062 0 9.531 9.531 0 0 1 19.062 0Z" /></svg>
                </span>
                <Button label="Choose from Graphic Library" icon="pi pi-images" @click="displayImageGalleryDialog = true"/>
                <span class="img-choose-upload-divider">or</span>
                <FileUpload
                  mode="basic" 
                  name="files[]" 
                  :url="uploadImageUrl" 
                  @before-send="beforeSend" 
                  @upload="onUploadImage"
                  @error="onError" 
                  :multiple="false" 
                  :auto="true" 
                  accept="image/*" 
                  :withCredentials="true"
                  chooseLabel="Upload from files"
                >
                  <template #uploadicon>
                    <i class="pi pi-upload"></i>
                  </template>
                </FileUpload>
              </div>

              <div v-if="editRecord.Image" class="w-full flex justify-content-center align-items-center mt-5">
                <img :src="editRecord.Image" />
              </div>
            </div>
            
            <ImageGalleryDialogView v-model="displayImageGalleryDialog" @imageSelected="onImageSelected"/>
          </div>
        </div>
      </AccordionTab>

      <AccordionTab header="Data" v-if="editRecord.Role === Reports3ElementRole.Body">
        <div class="formgrid grid">
          <div class="field col-12" :class="editRecord.EnableData ? '' : 'mb-0'">
            <div class="flex align-items-center">
              <InputSwitch 
                inputId="editRecordEnableData"
                v-model="editRecord.EnableData"
                class="vertical-align-top"
                :disabled="updateInProgress"
              />
              <label for="editRecordEnableData" class="mb-0 ml-2">Enable</label>
            </div>
          </div>
          <div class="field col-12" v-if="editRecord.EnableData">
            <label for="editRecordMaxCount">Max count</label>
            <div>
              <InputNumber 
                inputId="editRecordMaxCount" 
                class="inputfield w-full"
                v-model="editRecord.DataStreamsMaxCount"
                placeholder="Max count"
                :min="0"
              />
            </div>
          </div>
          <div class="field col-12 mb-0" v-if="editRecord.EnableData">
            <label for="editRecordFeatures">Features</label>
            <div>
              <MultiSelect
                v-model="editRecord.Features"
                :options="elementFeatures"
                :optionValue="x => x[0]"
                :optionLabel="x => x[1]"
                :filter="true"
                class="w-full"
                inputId="editRecordFeatures"
                placeholder="Select features"
              />
            </div>
          </div>
        </div>
      </AccordionTab>

      <AccordionTab header="Parameters">
        <div class="formgrid grid">
          <ScriptAdditionalParameterView 
            v-if="editRecord" 
            v-model="editRecord.AdditionalParameters" 
            :extendedMode="true"
            class="field col-12 mb-0 report-element-settings-parameters"
          />
        </div>
      </AccordionTab>

      <AccordionTab header="Code">
        <TabView class="data-tabs p-0 mb-0">
          <TabPanel header="HTML">
            <Codemirror
              v-model="editRecord.HTML"
              placeholder="HTML"
              class="w-full"
              :style="{ height: 'auto', 'min-height': '200px' }"
              :indent-with-tab="false"
              :tab-size="2"
              :extensions="extensionsHTML"
            />
          </TabPanel>
          <TabPanel header="JavaScript" v-if="editRecord.Role === Reports3ElementRole.Body">
            <Codemirror
              v-model="editRecord.JavaScript"
              placeholder="JavaScript"
              class="w-full"
              :style="{ height: 'auto', 'min-height': '200px' }"
              :indent-with-tab="false"
              :tab-size="2"
              :extensions="extensionsScript"
            />
          </TabPanel>
          <TabPanel header="CSS">
            <Codemirror
              v-model="editRecord.CSS"
              placeholder="CSS"
              class="w-full"
              :style="{ height: 'auto', 'min-height': '200px' }"
              :indent-with-tab="false"
              :tab-size="2"
              :extensions="extensionsCSS"
            />
          </TabPanel>
        </TabView>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import MultiSelect from 'primevue/multiselect';
import Textarea from 'primevue/textarea';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import AuthState from "@/store/states/AuthState";
import { Codemirror } from 'vue-codemirror';
import { xcodeDark, xcodeLight } from '@uiw/codemirror-theme-xcode';
import { javascript } from '@codemirror/lang-javascript';
import { css } from '@codemirror/lang-css';
import { html } from '@codemirror/lang-html';
import { indentWithTab } from "@codemirror/commands";
import { keymap } from "@codemirror/view";
import { ScriptAdditionalParameterType } from "@/models/script/ScriptAdditionalParameterType";
import { ScriptAdditionalParameter } from "@/models/script/ScriptAdditionalParameter";
import ScriptAdditionalParameterView from "@/components/views/ScriptAdditionalParameterView.vue";
import { useOrganisationStore } from "@/stores/organisation";
import { useReports3ElementsStore } from "@/stores/reports3Elements";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import WidgetHelper from "@/helpers/WidgetHelper";
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import { useReports3Store } from "@/stores/reports3";
import { Report3ElementFeatures } from "@/models/reports/v3/Report3ElementFeatures";
import { useReports3DataStore } from "@/stores/reports3Data";
import { ImageGalleryEntity } from "@/models/image-gallery/ImageGalleryEntity";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import FileUpload, { FileUploadBeforeSendEvent, FileUploadErrorEvent } from "primevue/fileupload";
import ImageGalleryDialogView from "@/components/views/image-gallery/ImageGalleryDialogView.vue"

@Component({
  components: {
    Accordion,
    AccordionTab,
    Button,
    InputText,
    InputNumber,
    Dialog,
    BlockUI,
    InputSwitch,
    Message,
    ProgressSpinner,
    Dropdown,
    TabView,
    TabPanel,
    MultiSelect,
    FileUpload,
    Textarea,
    Codemirror,
    ScriptAdditionalParameterView,
    ImageGalleryDialogView
  },
  directives: {
  }
})
class ReportsElementRightPanelView extends Vue {
  reports3 = useReports3Store();
  reports3Elements = useReports3ElementsStore();
  reports3Data = useReports3DataStore();

  get canEditGlobal(): boolean {
    return !!this.authState.permissions?.BitpoolAdmin;
  }

  get selectedEntityIsGlobal(): boolean {
    return this.editRecord?.OrganisationId === 0;
  }

  set selectedEntityIsGlobal(value: boolean) {
    if (this.editRecord) {
      if (value) {
        this.editRecord.OrganisationId = 0;
      } else {
        this.editRecord.OrganisationId = this.organisationStore.currentOrganisation?.Id ?? -1;
      }
    }
  }

  get isDarkTheme(): boolean {
    return !!this.authState.userSettings?.darkTheme;
  }

  get extensionsScript(): any[] {
    const result = [];
    if (this.isDarkTheme) {
      result.push(xcodeDark);
    } else {
      result.push(xcodeLight);
    }
    result.push(javascript());
    result.push(keymap.of([indentWithTab]));
    return result;
  }

  get extensionsHTML(): any[] {
    const result = [];
    if (this.isDarkTheme) {
      result.push(xcodeDark);
    } else {
      result.push(xcodeLight);
    }
    result.push(html());
    result.push(keymap.of([indentWithTab]));
    return result;
  }

  get extensionsCSS(): any[] {
    const result = [];
    if (this.isDarkTheme) {
      result.push(xcodeDark);
    } else {
      result.push(xcodeLight);
    }
    result.push(css());
    result.push(keymap.of([indentWithTab]));
    return result;
  }

  columns = WidgetHelper.getWidgetSizes();
  roles = [
    { name: 'Body', key: 0 },
    { name: 'Header', key: 1 },
    { name: 'Footer', key: 2 }
  ];

  get editRecord(): Reports3ElementEntity | null {
    return this.reports3Elements.dataOne;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  Reports3ElementRole = Reports3ElementRole;

  onRoleChange(): void {
    if (this.editRecord) {
      if (!this.editRecord.Height) {
        switch (this.editRecord.Role) {
          case Reports3ElementRole.Header:
            this.editRecord.Height = 2;
            break;
          case Reports3ElementRole.Footer:
            this.editRecord.Height = 2;
            break;
        }
      }
    }
  }

  get isLoaded(): boolean {
    const result = this.reports3Elements.isLoadedOne;
    return result;
  }

  get updateInProgress(): boolean {
    return this.reports3Elements.updateInProgress;
  }

  ScriptAdditionalParameterType = ScriptAdditionalParameterType;

  get scriptAdditionalParameterTypes(): [ScriptAdditionalParameterType, string][] {
    const result = Object
      .keys(ScriptAdditionalParameterType)
      .map(key => [ScriptAdditionalParameterType[key as any], key])
      .filter(value => typeof value[0] === 'number') as [ScriptAdditionalParameterType, string][];
    return result;
  }

  validateAdditionalParameterName(event: Event, data: ScriptAdditionalParameter): void {
    // Remove any non-alphanumeric characters
    const sanitizedValue = (event.target as HTMLInputElement).value.replace(/[^a-zA-Z0-9_]/g, "");

    // Ensure the first character is alphabetic
    const firstChar = sanitizedValue.charAt(0);
    const isAlpha = /^[a-zA-Z]+$/.test(firstChar);

    if (isAlpha) {
      data.Name = sanitizedValue;
    } else {
      // If the first character is not alphabetic, remove it
      data.Name = sanitizedValue.slice(1);
    }
  }

  elementFeatures: [Report3ElementFeatures, string][] = [
    [Report3ElementFeatures.DataOnlyTotalAggregation, "Only Total Aggregation"],
    [Report3ElementFeatures.DataSeriesType, "Series Type"],
    [Report3ElementFeatures.DataSeriesTypeStacked, "Series Type (Stacked)"],
    [Report3ElementFeatures.YAxis, "Y-Axis"]
  ];

  // #region image
  displayImageGalleryDialog = false;

  onImageSelected(entity: ImageGalleryEntity | null): void {
    if (entity && this.editRecord) {
      if (entity.OptimizedFilename) {
        this.editRecord.Image = `${this.uploadUrl}/${entity.OptimizedFilename}`;
      } else {
        this.editRecord.Image = `${this.uploadUrl}/${entity.Filename}`;
      }
    }
    this.displayImageGalleryDialog = false;
  }

  get uploadUrl() {
    return `${this.$store.state.apiUrl}/rest/AWS_S3_V1/File`;
  }

  get uploadImageUrl() {
    return `${this.$store.state.apiUrl}/rest/BitPool_V2/os/ImageGallery/default/Upload`;
  }

  // Callback to invoke before file send begins to customize the request such as adding headers.
  beforeSend(event: FileUploadBeforeSendEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.formData: FormData object.
    event.xhr.setRequestHeader("Authorization", this.$store.state.auth.authKey);
    event.xhr.setRequestHeader("Accept", "application/json");
  }

  // Callback to invoke when file upload is complete.
  onUploadImage(event: FileUploadErrorEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Uploaded files.
    const response: ImageGalleryEntity[] = JSON.parse(event.xhr.response);
    if (this.editRecord && response && response.length) {
      if (response[0].OptimizedFilename) {
        this.editRecord.Image = `${this.uploadUrl}/${response[0].OptimizedFilename}`;
      } else {
        this.editRecord.Image = `${this.uploadUrl}/${response[0].Filename}`;
      }
    }
    ToastService.showToast("success", "", "Upload complete!", 5000);
  }

  // Callback to invoke if file upload fails.
  onError(event: any): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Files that are not uploaded.
    ToastService.showToast("error", "Can't upload file", ErrorHelper.handleAxiosError(event.xhr).message, 5000);
  }
  // #endregion image

  // #region file
  // Callback to invoke when file upload is complete.
  onUploadFile(event: FileUploadErrorEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Uploaded files.
    const response = JSON.parse(event.xhr.response);
    if (this.editRecord && response && response.length) {
      this.editRecord.Image = `${this.uploadUrl}/${response[0]}`;
    }
    ToastService.showToast("success", "", "Upload complete!", 5000);
  }
  // #endregion image
}

export default ReportsElementRightPanelView;
</script>