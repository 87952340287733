<template>
  <div class="widget-settings-container">
    <div class="formgrid grid">

      <!-- Title -->
      <div class="field col-12 mt-0" v-if="widgetDescription?.features.includes(widgetFeatures.title)">
        <label for="widgetTitle">Title</label>
        <div>
          <InputText class="inputfield w-full" type="text" id="widgetTitle" v-model="bws.widgetName" />
        </div>
      </div>
      <!-- Title Optional -->
      <div class="field col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.titleOptional)">
        <div class="flex align-items-center">
          <InputSwitch v-model="aws.displayTitle" inputId="widgetTitleOptional" class="vertical-align-top" />
          <label for="widgetTitleOptional" class="mb-0 ml-2">Show Title</label>
        </div>
      </div>
      <!-- AI Custom Question -->
      <div class="field col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.aiCustomQuestion)">
        <label for="widgetAiCustomQuestion">AI Question</label>
        <div>
          <Textarea 
            class="w-full" 
            id="widgetAiCustomQuestion"
            v-model="aws.aiCustomQuestion"
            style="white-space: pre-wrap;" 
            :autoResize="true" 
          />
        </div>
      </div>
      <!-- Time Zone -->
      <div class="field col-12 md:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.timezone)">
        <label for="widgetTimeZone">Time Zone</label>
        <div>
          <Dropdown class="w-full" inputId="widgetTimeZone" v-model="aws.timeZoneId" :options="timezones" optionValue="Id"
            optionLabel="DisplayNameTitle" placeholder="Select Time Zone" :filter="true"
            filterPlaceholder="Find Time Zone" />
        </div>
      </div>
      <!-- Embed Web -->
      <div class="field col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.iframe)">
        <label for="widgetEmbedWeb">Embed Web Url</label>
        <div>
          <InputText class="inputfield w-full" id="widgetEmbedWeb" type="text" v-model="aws.web_url" />
        </div>
      </div>
      <!-- File URL & Upload -->
      <div class="field col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.file)">
        <label for="widgetFileUrl">File Url</label>
        <div class="p-inputgroup img-url-upload">
          <InputText class="inputfield w-full" id="widgetFileUrl" type="text" v-model="aws.widgetFileUrl" />
          <FileUpload mode="basic" name="files[]" :url="uploadUrl" @before-send="beforeSend" @upload="onUploadFile"
            @error="onError" :multiple="false" :auto="true" :withCredentials="true" chooseIcon="pi pi-plus-circle">
          </FileUpload>
        </div>
      </div>
      <!-- Image URL & Upload -->
      <div class="field col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.image)">
        <label for="widgetImgUrl">Image</label>
        <div class="img-choose-upload-container" :class="aws.widgetImageSrc ? 'with-image-uploaded' : ''">
          <div class="img-choose-upload" :class="aws.widgetImageSrc ? 'with-image-uploaded' : ''">
            <span v-if="!aws.widgetImageSrc" class="img-choose-upload-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 92 104"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M56.312 1v28.966a5.528 5.528 0 0 0 5.526 5.526h28.966M56.312 1 90.8 35.492v61.32a5.528 5.528 0 0 1-5.526 5.527H6.526A5.528 5.528 0 0 1 1 96.813V6.526A5.528 5.528 0 0 1 6.526 1h49.786Zm-45.28 82.454v5.404a2.947 2.947 0 0 0 2.948 2.948h63.966a2.947 2.947 0 0 0 2.947-2.948V73.374c0-.804-.328-1.574-.91-2.13L59.3 51.434a2.95 2.95 0 0 0-4.27.199L34.608 75.249 23.54 67.633a2.948 2.948 0 0 0-4.21.933l-7.896 13.392a2.927 2.927 0 0 0-.41 1.496h.008Zm25.613-41.316a9.531 9.531 0 1 1-19.062 0 9.531 9.531 0 0 1 19.062 0Z" /></svg>
            </span>
            <Button label="Choose from Graphic Library" icon="pi pi-images" @click="displayImageGalleryDialog = true"/>
            <span class="img-choose-upload-divider">or</span>
            <FileUpload
              mode="basic" 
              name="files[]" 
              :url="uploadImageUrl" 
              @before-send="beforeSend" 
              @upload="onUploadImage"
              @error="onError" 
              :multiple="false" 
              :auto="true" 
              accept="image/*" 
              :withCredentials="true"
              chooseLabel="Upload from files"
            >
              <template #uploadicon>
                <i class="pi pi-upload"></i>
              </template>
            </FileUpload>
          </div>

          <div v-if="aws.widgetImageSrc" class="w-full flex justify-content-center align-items-center mt-5">
            <img :src="aws.widgetImageSrc" />
          </div>
        </div>
        
        <ImageGalleryDialogView v-model="displayImageGalleryDialog" @imageSelected="onImageSelected"/>
      </div>
      <!-- Map Initial Zoom -->
      <div class="field col-12 sm:col-6 md:col-4"
        v-if="wds && widgetDescription?.features.includes(widgetFeatures.mapInitialZoom)">
        <label for="widgetMapInitialZoom">Initial Zoom Level</label>
        <div>
          <InputNumber class="inputfield w-full" inputId="widgetMapInitialZoom" v-model="mapInitialZoomPercent"
            mode="decimal" :useGrouping="false" :minFractionDigits="0" showButtons buttonLayout="horizontal" :step="5"
            :min="25" :max="1000" suffix="%" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
        </div>
      </div>
      <!-- Set Point -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.setPoint)">
        <label for="widgetSetPoint">Set Point</label>
        <div>
          <InputNumber class="inputfield w-full" id="widgetSetPoint" v-model="aws.setPoint" mode="decimal"
            :minFractionDigits="1" />
        </div>
      </div>
      <!-- Limit Color + Text -->
      <div class="col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.limitColorText)">
        <div class="formgrid grid">
          <!-- Threshold -->
          <div class="field col-12 sm:col-6">
            <label for="limitThreshold">Threshold</label>
            <InputNumber class="inputfield w-full" id="limitThreshold" v-model="aws.limitThreshold" mode="decimal"
              :minFractionDigits="1" />
          </div>
          <!-- Limit Rule -->
          <div class="field col-12 sm:col-6">
            <label for="limitRule">Logic Rule</label>
            <Dropdown v-model="aws.limitRuleOperator" :options="alarmRules" optionValue="id" optionLabel="name"
              placeholder="Select limit rule" class="w-full" inputId="limitRule" />
          </div>
          <!-- Under Limit State -->
          <div class="field col-12 sm:col-6">
            <label for="underLimitState">Logic True State</label>
            <InputText class="inputfield w-full" type="text" id="underLimitState" v-model="aws.underLimitState" />
          </div>
          <!-- Over Limit State -->
          <div class="field col-12 sm:col-6">
            <label for="overLimitState">Logic False State</label>
            <InputText class="inputfield w-full" type="text" id="overLimitState" v-model="aws.overLimitState" />
          </div>
          <!-- Under Limit Text -->
          <div class="field col-12 sm:col-6">
            <label for="underLimitText">Logic True Text</label>
            <InputText class="inputfield w-full" type="text" id="underLimitText" v-model="aws.underLimitText" />
          </div>
          <!-- Over Limit Text -->
          <div class="field col-12 sm:col-6">
            <label for="overLimitText">Logic False Text</label>
            <InputText class="inputfield w-full" type="text" id="overLimitText" v-model="aws.overLimitText" />
          </div>
          <!-- Under Limit Color -->
          <div class="field col-12 sm:col-6">
            <div class="flex align-items-center">
              <label class="mb-0 mr-2">Logic True Color</label>
              <ColorSelectView :color="aws.underLimitColor" :updateColor="updateUnderLimitColor" />
            </div>
          </div>
          <!-- Over Limit Color -->
          <div class="field col-12 sm:col-6">
            <div class="flex align-items-center">
              <label class="mb-0 mr-2">Logic False Color</label>
              <ColorSelectView :color="aws.overLimitColor" :updateColor="updateOverLimitColor" />
            </div>
          </div>
        </div>
      </div>
      <!-- Fill Type -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.imageFillType)">
        <label for="widgetFillType">Fill type</label>
        <div>
          <Dropdown class="w-full" inputId="widgetFillType" v-model="aws.fillType" :options="fillTypes" optionValue="id"
            optionLabel="name" placeholder="Select Fill Type" />
        </div>
      </div>
      <!-- Minimum Height -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.minHeight)">
        <label for="widgetMinHeight">Minimum Height</label>
        <div class="p-inputgroup">
          <InputNumber 
            class="inputfield w-full" 
            id="widgetMinHeight" 
            v-model="aws.minHeight" 
            mode="decimal"
            :minFractionDigits="0" 
            :min="0"
          />
          <span class="p-inputgroup-addon">px</span>
        </div>
      </div>
      <!-- Location -->
      <div class="field col-12 md:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.weather)">
        <label for="widgetLocation">Location</label>
        <div>
          <AutoComplete class="inputfield w-full" id="widgetLocation" v-model="aws.location"
            :suggestions="filteredLocations" :delay="500" @complete="searchLocation($event)" field="full"
            loadingIcon="pi pi-spinner" forceSelection />
        </div>
      </div>      
      <!-- Widget Mode -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.widgetMode)">
        <label for="widgetWidgetMode">Widget Type</label>
        <div>
          <Dropdown v-model="aws.widgetMode" :options="widgetModes" optionValue="id" optionLabel="name"
            placeholder="Select widget type" class="w-full" inputId="widgetWidgetMode"/>
        </div>
      </div>
      <!-- Data Icon -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.dataIcon)">
        <label for="widgetDataIcon">Select Display Icon</label>
        <div>
          <Dropdown v-model="aws.dataIcon" :options="dataIcons" optionValue="id" optionLabel="name"
            placeholder="Select display icon" class="w-full" inputId="widgetDataIcon">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="dropdown-item-with-icon">
                <DataIconView :iconType="slotProps.value" />
                <span>{{ getDataIconNameById(slotProps.value) }}</span>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="dropdown-item-with-icon highlight-opposite">
                <DataIconView :iconType="slotProps.option.id" />
                <span>{{ slotProps.option.name }}</span>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <!-- Units Grid-->
      <div class="col-12"
        :class="aws && widgetDescription?.features.includes(widgetFeatures.unitsPosition) ? 'sm:col-8' : 'sm:col-6'"
        v-if="aws && widgetDescription?.features.includes(widgetFeatures.units)">
        <div class="formgrid grid">
          <!-- Units -->
          <div class="field col-12"
            :class="aws && widgetDescription?.features.includes(widgetFeatures.unitsPosition) ? 'sm:col-6' : 'sm:col-12'">
            <label for="widgetUnits">Units</label>
            <div>
              <InputText class="inputfield w-full" id="widgetUnits" type="text" v-model="aws.widgetUnit" />
            </div>
          </div>
          <!-- Units Position -->
          <div class="field col-12 sm:col-6"
            v-if="aws && widgetDescription?.features.includes(widgetFeatures.unitsPosition)">
            <label>Units Position</label>
            <div>
              <SelectButton 
                v-model="aws.widgetUnitPos" 
                :options="unitsPositions" 
                optionValue="key" 
                optionLabel="name"
                class="buttonset-equal-btns" 
                :allowEmpty="false"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Target -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.target)">
        <label for="widgetTarget">Target</label>
        <div>
          <InputNumber class="inputfield w-full" id="widgetTarget" v-model="aws.target" mode="decimal"
            :minFractionDigits="2" :min="0" />
        </div>
      </div>
      <!-- Text -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.text)">
        <label for="widgetText">Text</label>
        <div>
          <InputText class="inputfield w-full" id="widgetText" type="text" v-model="aws.text" />
        </div>
      </div>
      <!-- Notifications -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.notificationStatus)">
        <label for="widgetNotificationStatus">Notification Status</label>
        <div>
          <Dropdown 
            v-model="notificationStatus" 
            :options="['All', 'Read', 'Unread']"
            class="w-full" 
            inputId="widgetNotificationStatus"
          />
        </div>
      </div>
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.notificationGroups) && notificationGroupStore.isLoaded">
        <label for="widgetNotificationGroup">Group</label>
        <div>
          <Dropdown 
            v-model="aws.notificationGroupId" 
            :options="notificationGroupStore.entities"
            optionLabel="Name"
            optionValue="Id"
            class="w-full" 
            inputId="widgetNotificationGroup"
            placeholder="Select notification group"
          />
        </div>
      </div>      
      <div class="col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.notificationFilter)">
        <div class="widget-settings-colored-box">
          <div class="formgrid grid">
            <div class="col-12">
              <h4>Apply filter ({{ selectedNotificationFiltersCount }})</h4>
              <span>Use filters to further refine which notifications to show.</span>
            </div>
            
            <div class="field col-12">
              <label for="widgetNotificationFilterFlows">Flows</label>
              <div>
                <MultiSelect
                  v-model="aws.notificationSourceIds"
                  :options="flows"
                  optionLabel="Name"
                  optionValue="_id"
                  :filter="true"
                  class="w-full"
                  inputId="widgetNotificationFilterFlows"
                  placeholder="All"
                />
              </div>
            </div>

            <div class="field col-12">
              <label for="widgetNotificationFilterAlarmType">Alarm Type</label>
              <div>
                <MultiSelect
                  v-model="aws.notificationSubtitles"
                  :options="alarmTypes"
                  :filter="true"
                  class="w-full"
                  inputId="widgetNotificationFilterAlarmType"
                  placeholder="All"
                />
              </div>
            </div>

            <div class="field col-12">
              <label for="widgetNotificationFilter">Title Filter</label>
              <div>
                <InputText class="inputfield w-full" id="widgetNotificationFilter" v-model="aws.notificationFilter" />
              </div>
            </div>

            <div class="field col-12">
              <div class="flex justify-content-end align-items-center">
                <Button
                label="Clear filter"
                link
                class="link p-1 text-base"
                @click="resetNotificationFilters"
                :disabled="!aws.notificationFilter && !aws.notificationSubtitles?.length && !aws.notificationSourceIds?.length"
              />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Decimal Places -->
      <div class="field col-12"
        :class="aws && widgetDescription?.features.includes(widgetFeatures.unitsPosition) ? 'sm:col-4' : 'sm:col-6'"
        v-if="aws && widgetDescription?.features.includes(widgetFeatures.decimalPlaces)">
        <label for="widgetDecimalPlaces">Decimal Places</label>
        <div>
          <InputNumber class="inputfield w-full" id="widgetDecimalPlaces" v-model="aws.decimals" mode="decimal"
            :minFractionDigits="0" :min="0" />
        </div>
      </div>
      <!-- Alarm -->
      <div class="col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.alarm)">
        <div class="formgrid grid">
          <div class="field col-12 sm:col-4">
            <label for="widgetAlarmRule">Alarm Rule</label>
            <div>
              <Dropdown v-model="aws.alarmOperator" :options="alarmRules" optionValue="id" optionLabel="name"
                placeholder="Select alarm rule" class="w-full" inputId="widgetAlarmRule" />
            </div>
          </div>
          <div class="field col-12 sm:col-4">
            <label for="widgetAlarmValue">Alarm Value</label>
            <div>
              <InputNumber class="inputfield w-full" id="widgetAlarmValue" v-model="aws.alarmValue" mode="decimal"
                :minFractionDigits="2" />
            </div>
          </div>
          <div class="field col-12 sm:col-4">
            <label for="widgetAlarmInterval">Alarm Check Interval (Minutes)</label>
            <div>
              <InputNumber class="inputfield w-full" id="widgetAlarmInterval" v-model="aws.alarmCheckMins" mode="decimal"
                :minFractionDigits="0" :min="1" />
            </div>
          </div>
          <div class="field col-12 sm:col-4">
            <div class="flex align-items-center">
              <InputSwitch v-model="aws.aggregating" inputId="widgetAlarmAgg" class="vertical-align-top" />
              <label for="widgetAlarmAgg" class="mb-0 ml-2">Aggregating</label>
            </div>
          </div>
          <div class="field col-12 sm:col-4">
            <div class="flex align-items-center">
              <InputSwitch v-model="aws.alarmShowTime" inputId="widgetAlarmTime" class="vertical-align-top" />
              <label for="widgetAlarmTime" class="mb-0 ml-2">In Alarm Time</label>
            </div>
          </div>
          <div class="field col-12 sm:col-4">
            <div class="flex align-items-center">
              <InputSwitch v-model="aws.alarmShowDate" inputId="widgetAlarmDate" class="vertical-align-top" />
              <label for="widgetAlarmDate" class="mb-0 ml-2">Last Time Normal</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Link Grid -->
      <div class="col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.link)">
        <div class="formgrid grid">
          <!-- Link Type -->
          <div class="field col-12 md:col-6">
            <label>Link Type</label>
            <div>
              <SelectButton 
                v-model="aws.linkType" 
                :options="linkTypes" 
                optionValue="key" 
                optionLabel="name"
                class="buttonset-equal-btns" 
                :allowEmpty="false"
              />
            </div>
          </div>
          <!-- Link -->
          <div class="field col-12 md:col-6" v-if="aws.linkType !== 2">
            <label for="widgetLink">Link</label>
            <div v-if="aws.linkType === 0">
              <TreeSelectView v-if="isDashboardsLoaded" :selectedId="aws.link" :nodes="nodes"
                :changeSelectedId="dashboardSelected" placeholder="Select Dashboard" id="widgetLink" />
              <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6"
                animationDuration="1s" />
            </div>
            <div v-else-if="aws.linkType === 1">
              <InputText class="inputfield w-full" id="widgetLink" type="text" v-model="aws.urlLink" />
            </div>
          </div>
        </div>
      </div>
      <!-- Table Fields -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.tableFields)">
        <label for="widgetTableFields">Table Fields</label>
        <div>
          <MultiSelect class="p-multiselect-multiline w-full" id="widgetTableFields" v-model="aws.fields"
            :options="tableFields" optionValue="key" optionLabel="name" placeholder="Select Table Fields" />
        </div>
      </div>
      <!-- Leaderboard Filter -->
      <div 
        class="field col-12"
        :class="(aws.leaderboardFilterRule === 'Between' || aws.leaderboardFilterRule === 'Not between') ? 'sm:col-4' : 'sm:col-6'"
        v-if="aws && widgetDescription?.features.includes(widgetFeatures.leaderboardFilter)"
      >
        <label>Leaderboard Filter</label>
        <div>
          <Dropdown
            v-if="firstStreamDataType === 0"
            v-model="aws.leaderboardFilterRule"
            :options="['=', '!=','>=', '>', '<=', '<', 'Between', 'Not between', 'No data']"
            placeholder="Select rule" 
            class="w-full"
            showClear 
          /> 
          <Dropdown
            v-else
            v-model="aws.leaderboardFilterRule"
            :options="['=', '!=']"
            placeholder="Select rule" 
            class="w-full"
            showClear 
          /> 
        </div>
      </div>
      <div 
        class="field col-12"
        :class="(aws.leaderboardFilterRule === 'Between' || aws.leaderboardFilterRule === 'Not between') ? 'sm:col-4' : 'sm:col-6'"
        v-if="aws?.leaderboardFilterRule && widgetDescription?.features.includes(widgetFeatures.leaderboardFilter) && firstStreamDataType === 0 && aws.leaderboardFilterRule !== 'No data'"
      >
        <label>{{ (aws.leaderboardFilterRule === 'Between' || aws.leaderboardFilterRule === 'Not between') ? 'Min' : 'Value' }}</label>
        <div>
          <InputNumber class="w-full" v-model="aws.leaderboardFilterValue" mode="decimal" :minFractionDigits="2" :allowEmpty="false" />
        </div>
      </div>
      <div 
        class="field col-12 sm:col-4"
        v-if="aws?.leaderboardFilterRule && widgetDescription?.features.includes(widgetFeatures.leaderboardFilter) && firstStreamDataType === 0 && (aws.leaderboardFilterRule === 'Between' || aws.leaderboardFilterRule === 'Not between')"
      >
        <label>Max</label>
        <div>
          <InputNumber class="w-full" v-model="aws.leaderboardFilterValue2" mode="decimal" :minFractionDigits="2" :allowEmpty="false" />
        </div>
      </div>
      <div 
        class="field col-12 sm:col-6"
        v-if="aws?.leaderboardFilterRule && widgetDescription?.features.includes(widgetFeatures.leaderboardFilter) && firstStreamDataType !== 0"
      >
        <label>Value</label>
        <div>
          <InputText class="w-full" v-model="aws.leaderboardFilterValueStr" />
        </div>
      </div>
      <!-- Leaderboard Direction -->
      <div class="field col-12 sm:col-6"
        v-if="aws?.leaderboardFilterRule && widgetDescription?.features.includes(widgetFeatures.leaderboardDirection)">
        <label>Leaderboard Order</label>
        <div>
          <SelectButton 
            v-model="aws.leaderboardDirection" 
            :options="leaderboardDirections" 
            optionValue="key"
            optionLabel="name" 
            class="buttonset-equal-btns" 
            :allowEmpty="false"
          />
        </div>
      </div>
      <!-- Background Color Grid -->
      <div class="col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.backgroundColor)">
        <div class="formgrid grid align-items-center pt-1">
          <!-- Enable Background Color -->
          <div class="field col-fixed w-auto">
            <div class="flex align-items-center">
              <InputSwitch v-model="aws.widgetColorEnabled" inputId="widgetEnableBgColor" class="vertical-align-top" />
              <label for="widgetEnableBgColor" class="mb-0 ml-2">Enable Background Color</label>
            </div>
          </div>
          <!-- Color -->
          <div class="field col-fixed w-auto">
            <ColorSelectView :color="aws.widgetColorHex" :updateColor="updateWidgetBackgroundColor" />
          </div>
        </div>
      </div>
      <!-- Content Grid -->
      <div class="col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.htmlContent)">
        <div class="formgrid grid">
          <!-- Content -->
          <div class="field col-12">
            <label>Content</label>
            <div class="jodit-hide-fullscreen">
              <jodit-editor v-model="aws.widgetContent" :editorOptions="{ theme: 'bitpool' }"></jodit-editor>
            </div>
          </div>
        </div>
      </div>
      <!-- Min, Max Value -->
      <div class="col-12"
        v-if="aws && aws.minMaxRange && widgetDescription?.features.includes(widgetFeatures.minMaxRange)"
      >
        <div class="formgrid grid">
          <div class="field col-12" v-if="widgetDescription?.features.includes(widgetFeatures.minMaxRangeAuto)">
            <div class="flex align-items-center">
              <InputSwitch v-model="aws.autoMinMax" inputId="widgetAutoMinMaxValue" class="vertical-align-top" />
              <label for="widgetAutoMinMaxValue" class="mb-0 ml-2">Auto Min & Max</label>
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="widgetMinValue">Min Value</label>
            <div>
              <InputNumber class="inputfield w-full" id="widgetMinValue" v-model="aws.minMaxRange[0]" mode="decimal"
                :minFractionDigits="0" :max="aws.minMaxRange[1]" :allowEmpty="false" :disabled="aws.autoMinMax && widgetDescription?.features.includes(widgetFeatures.minMaxRangeAuto)" />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="widgetMaxValue">Max Value</label>
            <div>
              <InputNumber class="inputfield w-full" id="widgetMaxValue" v-model="aws.minMaxRange[1]" mode="decimal"
                :minFractionDigits="0" :min="aws.minMaxRange[0]" :allowEmpty="false" :disabled="aws.autoMinMax && widgetDescription?.features.includes(widgetFeatures.minMaxRangeAuto)" />
            </div>
          </div>
        </div>
      </div>
      <!-- Gauge Inner Size -->
      <div class="field col-12 sm:col-6"
        v-if="aws && widgetDescription?.features.includes(widgetFeatures.gaugeInnerSize)">
        <label for="widgetGaugeInnerSize">Gauge Inner Size</label>
        <div>
          <div class="p-inputgroup w-full">
            <InputNumber class="inputfield w-full" id="widgetGaugeInnerSize" v-model="aws.gaugeInnerSize" mode="decimal"
              :minFractionDigits="0" :min="5" :max="95" />
            <span class="p-inputgroup-addon">%</span>
          </div>
        </div>
      </div>
      <!-- Inactive Color -->
      <div class="field col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.inactiveColor)">
        <div class="flex align-items-center">
          <label class="mb-0 mr-2">Inactive Color</label>
          <ColorSelectView :color="aws.inactiveColor" :updateColor="updateWidgetInactiveColor" />
        </div>
      </div>
      <!-- Range Selector -->
      <div class="col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.gaugeRange)">
        <div class="formgrid grid">
          <div class="field col-12">
            <label>Range Selector</label>
            <div>
              <vue-gpickr v-model="gradient" class="gauge-range" />
            </div>
          </div>
        </div>
      </div>
      <!-- Donut Inner Size -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.donut)">
        <label for="widgetDonutInnerSize">Donut Inner Size</label>
        <div>
          <div class="p-inputgroup w-full">
            <InputNumber class="inputfield w-full" id="widgetDonutInnerSize" v-model="aws.widgetDonutInnerSize"
              mode="decimal" :minFractionDigits="0" :min="0" :max="95" />
            <span class="p-inputgroup-addon">%</span>
          </div>
        </div>
      </div>
      <!-- Max Thickness -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.activityGauge)">
        <label for="widgetMaxThickness">Max Thickness</label>
        <div>
          <div class="p-inputgroup w-full">
            <InputNumber class="inputfield w-full" id="widgetMaxThickness" v-model="aws.widgetActivityGaugeMaxThickness"
              mode="decimal" :minFractionDigits="0" :min="5" :max="80" />
            <span class="p-inputgroup-addon">%</span>
          </div>
        </div>
      </div>
      <!-- Center Value -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.activityGauge)">
        <label for="widgetCenterValue">Center Value</label>
        <div>
          <Dropdown class="w-full" inputId="widgetCenterValue" v-model="aws.widgetActivityGaugeCenterValueType"
            :options="centerValues" optionValue="key" optionLabel="name" placeholder="Select Center Value" />
        </div>
      </div>
      <!-- Stacking -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.stacking)">
        <label>Stacking</label>
        <div>
          <SelectButton 
            v-model="aws.stacking" 
            :options="stackingModes" 
            optionValue="key" 
            optionLabel="name"
            class="buttonset-equal-btns" 
            :allowEmpty="false"
          />
        </div>
      </div>
      <!-- Report Name -->
      <div class="field col-12 sm:col-6"
        v-if="wds && widgetDescription?.features.includes(widgetFeatures.meteringReport)">
        <label for="buildingName">Report Name</label>
        <div>
          <InputText class="inputfield w-full" id="buildingName" type="text" v-model="wds.BuildingName" />
        </div>
      </div>
      <!-- Round to nearest integer -->
      <div class="field col-12 sm:col-6 sm:pb-1 sm:align-self-end"
        v-if="wds && widgetDescription?.features.includes(widgetFeatures.meteringReport)">
        <div class="flex align-items-center">
          <InputSwitch v-model="wds.round" inputId="roundValues" class="vertical-align-top" />
          <label for="roundValues" class="mb-0 ml-2">Round to nearest integer</label>
        </div>
      </div>
      <!-- Boolean Values Name -->
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.booleanValuesName)">
        <label for="trueValueName">True Value Name</label>
        <div>
          <InputText class="inputfield w-full" id="trueValueName" type="text" v-model="aws.booleanTrueName" />
        </div>
      </div>
      <div class="field col-12 sm:col-6" v-if="aws && widgetDescription?.features.includes(widgetFeatures.booleanValuesName)">
        <label for="falseValueName">False Value Name</label>
        <div>
          <InputText class="inputfield w-full" id="falseValueName" type="text" v-model="aws.booleanFalseName" />
        </div>
      </div>
      <!-- Map type -->
      <div class="field col-12 sm:col-6" v-if="wds && widgetDescription?.features.includes(widgetFeatures.mapType)">
        <label for="widgetCenterValue">Map Type</label>
        <div>
          <Dropdown class="w-full" inputId="widgetMapType" v-model="wds.mapType"
            :options="mapTypes" optionValue="key" optionLabel="name" placeholder="Select Map Type" />
        </div>
      </div>
      <!-- Enable optional data -->
      <div class="field col-12 pt-1"
        v-if="aws && widgetDescription?.features.includes(widgetFeatures.dataOptional)">
        <div class="flex align-items-center">
          <InputSwitch v-model="aws.enableOptionalData" inputId="enableOptionalData" class="vertical-align-top" />
          <label for="enableOptionalData" class="mb-0 ml-2">Enable data</label>
        </div>
      </div>
      <!-- Enable optional timestamp -->
      <div class="field col-12 pt-1"
        v-if="aws && widgetDescription?.features.includes(widgetFeatures.dataOptionalTimestamp)">
        <div class="flex align-items-center">
          <InputSwitch v-model="aws.enableOptionalTimestamp" inputId="enableOptionalTimestamp" class="vertical-align-top" />
          <label for="enableOptionalTimestamp" class="mb-0 ml-2">Show Timestamp</label>
        </div>
      </div>
      <!-- Enable optional last period value -->
      <div class="field col-12 pt-1"
        v-if="aws && widgetDescription?.features.includes(widgetFeatures.dataOptionalLastPeriodValue)">
        <div class="flex align-items-center">
          <InputSwitch v-model="aws.enableOptionalLastPeriodValue" inputId="enableOptionalLastPeriodValue" class="vertical-align-top" />
          <label for="enableOptionalLastPeriodValue" class="mb-0 ml-2">Show 'Last Period' value</label>
        </div>
      </div>
      <!-- Negative state -->
      <div class="field col-12 mt-4"
        v-if="aws && widgetDescription?.features.includes(widgetFeatures.dataNegativeState)">
        <label class="mb-3">Select the negative state</label>
        <div class="field-radiobutton">
            <RadioButton inputId="dataNegativeStateIncrease" name="dataNegativeState" value="increase" v-model="aws.dataNegativeState" />
            <label for="dataNegativeStateIncrease">Increase in value</label>
          </div>
          <div class="field-radiobutton mb-1">
            <RadioButton inputId="dataNegativeStateDecrease" name="dataNegativeState" value="decrease" v-model="aws.dataNegativeState" />
            <label for="dataNegativeStateDecrease">Decrease in value</label>
          </div>
      </div>
      <!-- GDRS Grid -->
      <div 
        class="col-12" 
        v-if="aws && widgetDescription?.features.includes(widgetFeatures.gdrs) &&
          (!widgetDescription?.features.includes(widgetFeatures.dataOptional) || aws.enableOptionalData)"
      >
        <div class="widget-settings-colored-box">
          <div class="formgrid grid">
            <div class="col-12">
              <h4>GDRS</h4>
            </div>
            <!-- Use GDRS -->
            <div class="field col-12" :class="gdrsClass">
              <div class="flex align-items-center">
                <InputSwitch v-model="useGDRS" inputId="widgetUseGdrs" class="vertical-align-top" />
                <label for="widgetUseGdrs" class="mb-0 ml-2">Use Global Date Range Setpoint (GDRS)</label>
              </div>
            </div>
            <!-- Display Null Indicator -->
            <div class="field col-12 sm:col-6 sm:align-self-center"
              v-if="aws && widgetDescription?.features.includes(widgetFeatures.showNullValues)">
              <div class="flex align-items-center">
                <InputSwitch v-model="showNullValues" inputId="widgetDisplayNullIndicator" class="vertical-align-top" />
                <label for="widgetDisplayNullIndicator" class="mb-0 ml-2">Display Data Gaps</label>
              </div>
            </div>
            <!-- Aggregation Period -->
            <div class="field col-12 sm:col-6"
              v-if="aws && widgetDescription?.features.includes(widgetFeatures.dataAggregation)">
              <label for="widgetAggregationPeriod">Aggregation Period</label>
              <div>
                <Dropdown class="w-full" inputId="widgetAggregationPeriod" v-model="selectedDataAggregation"
                  :options="dataAggregations" optionValue="key" optionLabel="name"
                  placeholder="Select Data Aggregation" />
              </div>
            </div>
            <!-- Date Range -->
            <div class="field col-12 sm:col-6" v-if="widgetDescription?.features.includes(widgetFeatures.dateRange)">
              <label for="widgetDateRange">Date Range</label>
              <div>
                <Dropdown class="w-full" inputId="widgetDateRange" v-model="selectedDateRange" :options="dateRanges"
                  optionValue="key" optionLabel="name" placeholder="Select Date Range" @change="setDates(false)" />
              </div>
            </div>
            <!-- Start, End Date Grid -->
            <div class="col-12">
              <div class="formgrid grid">
                <!-- Start Date -->
                <div class="field col-12 sm:col-6" v-if="widgetDescription?.features.includes(widgetFeatures.dateRange)">
                  <label>Start Date</label>
                  <div>
                    <Calendar 
                      class="w-full" 
                      ref="calendarFrom" 
                      v-model="dateFrom" 
                      :maxDate="dateTo" 
                      :showTime="true"
                      dateFormat="dd/mm/yy" 
                      @date-select="onChangeDate()" 
                      @change="onChangeDate()" 
                      panelClass="with-max-width"
                    >
                      <template #footer>
                        <div class="flex justify-content-end pb-3">
                          <Button label="Close" icon="pi pi-times" @click="closeCalendar"
                            class="p-button-text p-button-secondary" />
                        </div>
                      </template>
                    </Calendar>
                  </div>
                </div>
                <!-- End Date -->
                <div class="field col-12 sm:col-6" v-if="widgetDescription?.features.includes(widgetFeatures.dateRange)">
                  <label>End date</label>
                  <div>
                    <Calendar 
                      class="w-full" 
                      ref="calendarTo" 
                      v-model="dateTo" 
                      :minDate="dateFrom" 
                      :showTime="true"
                      dateFormat="dd/mm/yy" 
                      @date-select="onChangeDate()" 
                      @change="onChangeDate()" 
                      panelClass="with-max-width"
                    >
                      <template #footer>
                        <div class="flex justify-content-end pb-3">
                          <Button label="Close" icon="pi pi-times" @click="closeCalendar"
                            class="p-button-text p-button-secondary" />
                        </div>
                      </template>
                    </Calendar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Y Axis Grid -->
      <div class="col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.yAxis)">
        <div class="widget-settings-colored-box">
          <div class="formgrid grid">
            <div class="col-12">
              <div class="inline-flex align-items-center">
                <h4>Y Axis (Units)</h4>
                <Button @click="() => yAxis.push({ name: '' })"
                  class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn ml-3 flex-none"
                  icon="pi pi-plus" />
              </div>
            </div>
            <div class="col-12 mt-2">
              <DataTable v-if="yAxis?.length" :value="yAxis" showGridlines responsiveLayout="scroll"
                class="widget-settings-table p-datatable-sm">
                <Column field="name" header="Y Axis Name" headerClass="text-sm"
                  headerStyle="border-right-color: transparent;" bodyClass="text-sm">
                  <template #body="{ data, field }">
                    <InputText class="inputfield w-full" type="text" v-model="data[field]" />
                  </template>
                </Column>
                <Column field="" header="" headerStyle="width: 1%; min-width: 44px; border-left-color: transparent;"
                  bodyStyle="text-align: right; justify-content: flex-end;">
                  <template #body="{ index }">
                    <Button icon="pi pi-trash"
                      class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined"
                      @click="() => yAxis.splice(index, 1)" :disabled="yAxis.length === 1" />
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <!-- Groups -->
      <div class="col-12" v-if="wds && widgetDescription?.features.includes(widgetFeatures.dataStreamsGroups)">
        <div class="widget-settings-colored-box">
          <div class="formgrid grid">
            <div class="col-12">
              <div class="inline-flex align-items-center">
                <h4>Groups</h4>
                <Button @click="() => groups.push({ name: `Group-${groups.length + 1}` })"
                  class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn ml-3 flex-none"
                  icon="pi pi-plus" />
              </div>
            </div>
            <div class="col-12 mt-2">
              <DataTable v-if="groups.length" :value="groups" showGridlines responsiveLayout="scroll"
                class="widget-settings-table p-datatable-sm">
                <Column field="name" header="Name" headerClass="text-sm" headerStyle="border-right-color: transparent;"
                  bodyClass="text-sm">
                  <template #body="{ data, field }">
                    <InputText class="inputfield w-full" type="text" v-model="data[field]" />
                  </template>
                </Column>
                <Column field="" header="" headerStyle="width: 1%; min-width: 44px; border-left-color: transparent;"
                  bodyStyle="text-align: right; justify-content: flex-end;">
                  <template #body="{ index }">
                    <Button icon="pi pi-trash"
                      class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined"
                      @click="() => groups.splice(index, 1)" :disabled="groups.length === 1" />
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <!-- Sites -->
      <div class="col-12" v-if="wds && widgetDescription?.features.includes(widgetFeatures.dataSites)">
        <div class="widget-settings-colored-box">
          <div class="formgrid grid">
            <div class="col-12">
              <div class="inline-flex align-items-center">
                <h4>Sites</h4>
                <Button @click="() => sites.push({ name: '', key: '', link: '' })"
                  class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn ml-3 flex-none"
                  icon="pi pi-plus" 
                />
              </div>
            </div>
            <div class="col-12 mt-2" v-if="sites.length">
              <DataTable
                :value="sites" 
                showGridlines 
                responsiveLayout="scroll"
                class="widget-settings-table p-datatable-sm"
              >
                <Column 
                  field="key" 
                  header="Site" 
                  headerClass="text-sm"
                  headerStyle="border-right-color: transparent; min-width: min-content; width: 45%;"
                  bodyClass="text-sm"
                >
                  <template #body="{ data, field }">
                    <TreeSelectView 
                      v-if="navTreeState.isLoaded"
                      :selectedId="data[field]" 
                      :nodes="allSites"
                      :changeSelectedId="(node: TreeNodeForUI) => { data.key = node.key ?? ''; data.name = node.label ?? ''; }"
                      placeholder="Select Site"
                      :oneLevelOnly="true"
                    />
                    <ProgressSpinner v-else class="spinner-primary block" style="width: 28px; height: 28px" strokeWidth="6" animationDuration="1s" />
                  </template>
                </Column>
                <Column 
                  field="link" 
                  header="Link" 
                  headerClass="text-sm" 
                  headerStyle="border-right-color: transparent; min-width: min-content; width: 45%;"
                  bodyClass="text-sm"
                >
                  <template #body="{ data, field }">
                    <TreeSelectView 
                      v-if="isDashboardsLoaded" 
                      :selectedId="data[field] ? data[field].split(':').pop() : ''"
                      :nodes="nodes"
                      :changeSelectedId="(node: TreeNodeForUI) => data.link = `${node.additionalData.isOrganisation ? 'o' : 'p'}:${node.key}`" 
                      placeholder="Select Dashboard" 
                    />
                    <ProgressSpinner v-else class="spinner-primary block" style="width: 28px; height: 28px" strokeWidth="6"
                      animationDuration="1s" />
                  </template>
                </Column>
                <Column 
                  field="" 
                  header="" 
                  headerStyle="width: 1%; min-width: 44px; border-left-color: transparent;"
                  bodyStyle="text-align: right; justify-content: flex-end;"
                >
                  <template #body="{ index }">
                    <Button 
                      icon="pi pi-trash"
                      class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined"
                      @click="() => sites.splice(index, 1)"
                    />
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <!-- Bakground Color Range -->
      <div class="col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.chartBackgroundColorRange)">
        <div class="widget-settings-colored-box">
          <div class="formgrid grid">
            <div class="col-12">
              <div class="inline-flex align-items-center">
                <h4>Background Color Range</h4>
                <Button
                  @click="() => { aws?.triggerLevels?.push({ name: `Range ${(aws.triggerLevels.length + 1)}`, start: 0, end: 100, color: colorHelper.random(), yaxis: 0 }); }"
                  class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn ml-3 flex-none"
                  icon="pi pi-plus" />
              </div>
            </div>
            <div class="col-12 mt-2" v-if="aws.triggerLevels?.length">
              <DataTable :value="aws.triggerLevels" showGridlines responsiveLayout="stack" breakpoint="600px"
                class="widget-settings-table responsive-breakpoint p-datatable-sm">
                <Column field="name" header="Range Name" headerClass="text-sm" bodyClass="text-sm">
                  <template #body="{ data, field }">
                    <InputText class="w-full" v-model="data[field]" />
                  </template>
                </Column>
                <Column field="yaxis" header="Axis" headerClass="text-sm" bodyClass="text-sm">
                  <template #body="{ data }">
                    <Dropdown
                      v-model="data.yaxis"
                      :options="yAxisOptions"
                      optionValue="id" 
                      optionLabel="name"
                      placeholder="Select axis" 
                      class="w-full"
                    /> 
                  </template>
                </Column>
                <Column field="start" header="Start" headerClass="text-sm" bodyClass="text-sm">
                  <template #body="{ data, field }">
                    <InputNumber class="w-full" v-model="data[field]" mode="decimal" :minFractionDigits="2" />
                  </template>
                </Column>
                <Column field="end" header="End" headerClass="text-sm" bodyClass="text-sm">
                  <template #body="{ data, field }">
                    <InputNumber class="w-full" v-model="data[field]" mode="decimal" :minFractionDigits="2" />
                  </template>
                </Column>
                <Column field="color" header="Color" headerClass="text-sm"
                  headerStyle="width: 1%; min-width: 44px; border-right-color: transparent;"
                  bodyClass="justify-content-start text-center text-sm">
                  <template #body="{ data, field }">
                    <ColorSelectView :color="data[field]" format="rgb" alphaChannel="show"
                      :updateColor="(color: string) => updateTriggerLevelsColor(data, color)" />
                  </template>
                </Column>
                <Column field="" header="" headerStyle="width: 1%; min-width: 44px; border-left-color: transparent;"
                  bodyStyle="text-align: right; justify-content: flex-end;">
                  <template #body="{ index }">
                    <Button icon="pi pi-trash"
                      class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined"
                      @click="() => { aws?.triggerLevels?.splice(index, 1) }" />
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <!-- Data Conditions mode -->
      <div class="field col-12 mt-4"
        v-if="aws && widgetDescription?.features.includes(widgetFeatures.dataConditionsMode)">
        <label class="mb-3">Select data conditions mode</label>
        <div class="field-radiobutton">
            <RadioButton inputId="dataConditionsModePercent" name="dataNegativeState" value="percent" v-model="aws.dataConditionsMode" />
            <label for="dataConditionsModePercent">Percent</label>
          </div>
          <div class="field-radiobutton mb-1">
            <RadioButton inputId="dataConditionsModeCurrentValue" name="dataNegativeState" value="currentValue" v-model="aws.dataConditionsMode" />
            <label for="dataConditionsModeCurrentValue">Current value</label>
          </div>
      </div>
      <!-- Data Conditions -->
      <div class="col-12" v-if="aws && widgetDescription?.features.includes(widgetFeatures.dataConditions)">
        <div class="widget-settings-colored-box">
          <div class="formgrid grid">
            <div class="col-12">
              <div class="inline-flex align-items-center">
                <h4>Data Conditions</h4>
                <Button
                  @click="() => { aws?.dataConditions?.push({ name: `Condition ${(aws.dataConditions.length + 1)}`, rule: '>', value: 0, value2: 1, valueStr: '', color: '' }); }"
                  class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn ml-3 flex-none"
                  icon="pi pi-plus" />
              </div>
            </div>
            <div class="col-12 mt-2" v-if="aws.dataConditions?.length">
              <DataTable :value="aws.dataConditions" showGridlines responsiveLayout="stack" breakpoint="600px"
                class="widget-settings-table responsive-breakpoint p-datatable-sm">
                <Column field="name" header="Name" headerClass="text-sm" bodyClass="text-sm">
                  <template #body="{ data, field }">
                    <InputText class="w-full" v-model="data[field]" />
                  </template>
                </Column>
                <Column field="rule" header="Rule" headerClass="text-sm" bodyClass="text-sm">
                  <template #body="{ data }">
                    <Dropdown
                      v-if="firstStreamDataType === 0"
                      v-model="data.rule"
                      :options="['=', '!=','>=', '>', '<=', '<', 'Between', 'No data']"
                      placeholder="Select rule" 
                      class="w-full"
                    /> 
                    <Dropdown
                      v-else
                      v-model="data.rule"
                      :options="['=', '!=']"
                      placeholder="Select rule" 
                      class="w-full"
                    /> 
                  </template>
                </Column>
                <Column field="value" header="Value" headerClass="text-sm" bodyClass="text-sm">
                  <template #body="{ data, field }">
                    <div class="w-full" v-if="firstStreamDataType === 0">
                      <div v-if="data.rule !== 'No data'">
                        <InputNumber class="w-full" v-model="data[field]" mode="decimal" :minFractionDigits="2" :allowEmpty="false" />
                      </div>
                      <div v-if="data.rule === 'Between'" class="mt-2">
                        <InputNumber class="w-full" v-model="data.value2" mode="decimal" :minFractionDigits="2" :allowEmpty="false" />
                      </div>
                    </div>
                    <div class="w-full" v-else>
                      <div>
                        <InputText class="w-full" v-model="data.valueStr" />
                      </div>
                    </div>
                  </template>
                </Column>
                <Column field="color" header="Color" headerClass="text-sm" bodyClass="text-sm">
                  <template #body="{ data, field }">
                    <ColorSelectView :color="data[field]" :allowDisable="true"
                      :updateColor="(color: string) => { data[field] = color }" />
                  </template>
                </Column>
                <Column field="" header="" headerStyle="width: 1%; min-width: 44px; border-left-color: transparent;"
                  bodyStyle="text-align: right; justify-content: flex-end;">
                  <template #body="{ index }">
                    <Button icon="pi pi-trash"
                      class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined"
                      @click="() => { aws?.dataConditions?.splice(index, 1) }" />
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <!-- Data Conditions Image -->
      <div 
        class="col-12" 
        v-if="aws && widgetDescription?.features.includes(widgetFeatures.dataConditionsImage) &&
        (!widgetDescription?.features.includes(widgetFeatures.dataOptional) || aws?.enableOptionalData)"
      >
        <div class="widget-settings-colored-box">
          <div class="formgrid grid">
            <div class="col-12">
              <div class="inline-flex align-items-center">
                <h4>Data Conditions</h4>
                <Button
                  @click="() => { aws?.dataConditionsImage?.push({ name: `Condition ${(aws.dataConditionsImage.length + 1)}`, rule: '>', value: 0, value2: 1, valueStr: '', image: '' }); }"
                  class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn ml-3 flex-none"
                  icon="pi pi-plus" />
              </div>
            </div>
            <div class="col-12 mt-2" v-if="aws.dataConditionsImage?.length">
              <DataTable :value="aws.dataConditionsImage" showGridlines responsiveLayout="stack" breakpoint="600px"
                class="widget-settings-table responsive-breakpoint p-datatable-sm">
                <Column field="name" header="Name" headerClass="text-sm" headerStyle="min-width: min-content; width: 28%;" bodyClass="text-sm">
                  <template #body="{ data, field }">
                    <InputText class="w-full" v-model="data[field]" />
                  </template>
                </Column>
                <Column field="rule" header="Rule" headerClass="text-sm" headerStyle="min-width: min-content; width: 20%;" bodyClass="text-sm">
                  <template #body="{ data }">
                    <Dropdown
                      v-if="firstStreamDataType === 0"
                      v-model="data.rule"
                      :options="['=', '!=','>=', '>', '<=', '<', 'Between']"
                      placeholder="Select rule" 
                      class="w-full"
                    /> 
                    <Dropdown
                      v-else
                      v-model="data.rule"
                      :options="['=', '!=']"
                      placeholder="Select rule" 
                      class="w-full"
                    /> 
                  </template>
                </Column>
                <Column field="value" header="Value" headerClass="text-sm" headerStyle="min-width: min-content; width: 18%;" bodyClass="text-sm">
                  <template #body="{ data, field }">
                    <div class="w-full" v-if="firstStreamDataType === 0">
                      <div>
                        <InputNumber class="w-full" v-model="data[field]" mode="decimal" :minFractionDigits="2" :allowEmpty="false" />
                      </div>
                      <div v-if="data.rule === 'Between'" class="mt-2">
                        <InputNumber class="w-full" v-model="data.value2" mode="decimal" :minFractionDigits="2" :allowEmpty="false" />
                      </div>
                    </div>
                    <div class="w-full" v-else>
                      <div>
                        <InputText class="w-full" v-model="data.valueStr" />
                      </div>
                    </div>
                  </template>
                </Column>
                <Column field="image" header="Image" headerClass="text-sm" headerStyle="min-width: min-content; width: 25%;" bodyClass="text-sm flex-wrap image-inside-table">
                  <template #body="{ data, field }">
                    <img class="image-inside-table-img" v-if="data[field]" :src="data[field]" />
                    <ImageGalleryDialogButtonView class="image-inside-table-btn"
                      @imageSelected="(entity: ImageGalleryEntity | null) => { data[field] = entity ? entity.OptimizedFilename ? `${uploadUrl}/${entity.OptimizedFilename}` : `${uploadUrl}/${entity.Filename}` : '' }"
                    />
                  </template>
                </Column>
                <Column field="" header="" headerStyle="width: 1%; min-width: 44px; border-left-color: transparent;"
                  bodyStyle="text-align: right; justify-content: flex-end;">
                  <template #body="{ index }">
                    <Button icon="pi pi-trash"
                      class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined"
                      @click="() => { aws?.dataConditionsImage?.splice(index, 1) }" />
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <!-- Streams -->
      <div 
        class="col-12 widget-settings-streams-container"
        v-if="wds && widgetDescription?.features.includes(widgetFeatures.dataStreams) &&
          (!widgetDescription?.features.includes(widgetFeatures.dataOptional) || aws?.enableOptionalData)"
      >
        <div class="formgrid grid">
          <div class="col-12">
            <div class="inline-flex align-items-center gap-2" :class="widgetDescription?.streamSlots?.length ? '' : 'flex-wrap sm:flex-nowrap'">
              <h4 :class="widgetDescription?.streamSlots?.length ? 'mr-2' : 'w-full mr-0 mb-1 sm:w-auto sm:mb-0 sm:mr-2'">Streams</h4>
              <Button @click="addStream"
                :disabled="widgetDescription?.streamSlots && widgetDescription?.streamSlots.length <= wds.streamOptions.length"
                class="p-button-outlined p-button-rounded widget-settings-control-btn flex-none" icon="pi pi-plus"
                label="Single" />
              <Button @click="openSelectStreamsDialog" v-if="!widgetDescription?.streamSlots?.length"
                :disabled="widgetDescription?.streamSlots && widgetDescription?.streamSlots.length <= wds.streamOptions.length"
                class="p-button-outlined p-button-rounded widget-settings-control-btn flex-none"
                icon="pi pi-plus" label="Multiple" />
              <Button
                class="p-button-outlined p-button-danger p-button-rounded widget-settings-control-btn flex-none"
                icon="pi pi-trash" 
                label="Delete All"
                @click="deleteAllStreams()">
              </Button>
            </div>
          </div>
          <div class="col-12">
            <!-- Streams List -->
            <div 
              class="widget-settings-colored-box widget-settings-stream-item" 
              v-for="(stream, i) in wds.streamOptions"
              :key="i + stream.StreamKey" 
              :id="`streamContainer${i}`"
            >
              <div class="widget-settings-stream-item-header">
                <div>
                  <h5 v-if="widgetDescription?.streamSlots && widgetDescription.streamSlots.length > i">
                    {{ widgetDescription.streamSlots[i].label }}<span v-if="widgetDescription.streamSlots[i].unit">
                      ({{ widgetDescription.streamSlots[i].unit }})</span>
                  </h5>
                  <h5 v-else>
                    {{ stream.Label ? `${i + 1}.&nbsp;${stream.Label}` : `${i + 1}.&nbsp;Stream` }}
                  </h5>
                </div>
                <div>
                  <div class="flex-none flex gap-2">
                    <Button class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn"
                      icon="pi pi-arrow-up" @click="() => moveStream(i, i - 1)" :disabled="i === 0" v-tippy="'Move Up'">
                    </Button>
                    <Button class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn"
                      icon="pi pi-arrow-down" @click="() => moveStream(i, i + 1)"
                      :disabled="i === wds.streamOptions.length - 1" v-tippy="'Move Down'">
                    </Button>
                    <Button
                      class="p-button-outlined p-button-danger p-button-icon-only p-button-rounded widget-settings-control-btn"
                      icon="pi pi-trash" @click="deleteStream(i)" v-tippy="'Delete'">
                    </Button>
                  </div>
                  <span @click="toggleCollapseStreams(i)" class="link" :class="{ 'is-open': !collapseStreams[i] }">
                    {{ collapseStreams[i] ? "Show" : "Hide" }} <i class="pi"
                      :class="{ 'pi-chevron-up': !collapseStreams[i], 'pi-chevron-down': collapseStreams[i] }"></i>
                  </span>
                </div>
              </div>

              <transition name="p-toggleable-content">
                <WidgetSettingsStreamView 
                  v-show="!collapseStreams[i]" 
                  :isCollapsed="collapseStreams[i]"
                  :widgetConfig="widgetConfig" 
                  :streamOption="stream" 
                  :nodes="nodes"
                  :isDashboardsLoaded="isDashboardsLoaded"
                />
              </transition>
            </div>
          </div>
        </div>
      </div>

    </div>

    <Dialog header="Select Streams" v-model:visible="displaySelectStreams" :modal="true"
      :breakpoints="{ '1400px': '70vw', '1024px': '85vw', '640px': '90vw' }" :style="{ width: '60vw' }"
      class="widget-select-streams-config-dialog">
      <div class="dialog-content">
        <div class="formgrid grid">
          <!-- Structured/not-structured switch -->
          <div class="field col-12 pt-1">
            <div class="flex align-items-center">
              <InputSwitch v-model="structuredView" inputId="structuredViewStreams" class="vertical-align-top" />
              <label for="structuredViewStreams" class="mb-0 ml-2">Structured Data</label>
            </div>
          </div>

          <!-- Structured -->
          <div v-show="structuredView" class="field col-12">
            <label for="structuredDataStreams">
              Structured Data
            </label>
            <div>
              <TreeWithCheckboxesView v-if="navTreeState.isLoaded && navTreeState.structuredDataForUI"
                :nodes="navTreeState.structuredDataForUI" :changeSelected="structuredChangeSelected"
                placeholder="Find Streams" :includeTags="widgetDescription?.includeTags ?? []" />
              <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6"
                animationDuration="1s" />
            </div>
          </div>

          <!-- Not-structured -->
          <div v-show="!structuredView" class="field col-12">
            <label for="unstructuredDataStreams">
              Unstructured Data
            </label>
            <div>
              <TreeWithCheckboxesView v-if="navTreeState.isLoaded && navTreeState.unstructuredDataForUI"
                :nodes="navTreeState.unstructuredDataForUI" :changeSelected="unstructuredChangeSelected"
                placeholder="Find Streams" :includeTags="widgetDescription?.includeTags ?? []" />
              <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6"
                animationDuration="1s" />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Close" icon="pi pi-times" @click="closeSelectStreamsDialog"
          class="p-button-text p-button-secondary" />
        <Button label="Add" icon="pi pi-check" @click="addSelectedStreams"
          :disabled="!structuredSelectedNodes.length && !unstructuredSelectedNodes.length" />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
import SelectButton from 'primevue/selectbutton';
import Chips from 'primevue/chips';
import OverlayPanel from 'primevue/overlaypanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';
import FileUpload, { FileUploadBeforeSendEvent, FileUploadErrorEvent } from 'primevue/fileupload';
import AutoComplete, { AutoCompleteCompleteEvent } from 'primevue/autocomplete';
import ProgressSpinner from "primevue/progressspinner";
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';
import { JoditEditor } from 'jodit-vue3';
import WidgetHelper from "@/helpers/WidgetHelper";
import { AdvancedWidgetSettings } from "@/models/dashboard/AdvancedWidgetSettings";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import { WidgetDescription } from "@/models/dashboard/WidgetDescription";
import { nextTick } from "vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
import { BasicWidgetSettings } from "@/models/dashboard/BasicWidgetSettings";
import { WidgetDataSettings } from "@/models/dashboard/WidgetDataSettings";
import { WidgetFeature } from "@/models/enums/WidgetFeature";
import SpaceHelper from "@/helpers/SpaceHelper";
import DashboardState from "@/store/states/DashboardState";
import ColorHelper from '@/helpers/ColorHelper';
import TriggerLevel from '@/models/TriggerLevel';
import DateHelper from '@/helpers/DateHelper';
import { TimeRange } from '@/models/enums/TimeRange';
import { debounce } from 'throttle-debounce';
import { Watch } from "vue-facing-decorator";
import moment from 'moment';
import WidgetSettingsStreamView from "./WidgetSettingsStreamView.vue";
import DataIconView from './DataIconView.vue';
import { YAxis } from '@/models/dashboard/YAxis';
import { arrayMoveMutable } from 'array-move';
import { TimeZoneDto } from '@/models/TimeZoneDto';
import ToastService from '@/services/ToastService';
import ErrorHelper from '@/helpers/ErrorHelper';
import { WeatherLocation } from '@/models/WeatherLocation';
import ColorSelectView from './ColorSelectView.vue';
import { VueGpickr, LinearGradient } from 'vue-gpickr';
import HeatmapGaugeRange from '@/models/HeatmapGaugeRange';
import PlusCircleSvg from "@/components/svg/PlusCircleSvg.vue";
import ConfirmationService from '@/services/ConfirmationService';
import { AggregationType } from '@/models/enums/AggregationType';
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import TreeSelectView from "@/components/views/TreeSelectView.vue";
import { DashboardType } from '@/models/dashboard/DashboardType';
import StreamOption from "@/models/dashboard/StreamOption";
import TreeWithCheckboxesView from "@/components/views/TreeWithCheckboxesView.vue";
import { useNavTreeStore } from '@/stores/navTree';
import NavTreeState from '@/stores/states/NavTreeState';
import { StreamDataType } from "@/models/enums/StreamDataType";
import { ImageGalleryEntity } from "@/models/image-gallery/ImageGalleryEntity";
import ImageGalleryDialogView from "@/components/views/image-gallery/ImageGalleryDialogView.vue"
import ImageGalleryDialogButtonView from "@/components/views/image-gallery/ImageGalleryDialogButtonView.vue"
import { WidgetDataSettingsSite } from "@/models/dashboard/WidgetDataSettingsSite";
import { useNotificationGroupStore } from "@/stores/notificationGroup";
import { useOrganisationFlowsStore } from "@/stores/organisationFlows";
import { useOrganisationStore } from "@/stores/organisation";
import { FlowEntity } from "@/models/flow/FlowEntity";

@Component({
  components: {
    Button,
    InputText,
    Textarea,
    InputSwitch,
    Dropdown,
    SelectButton,
    Chips,
    OverlayPanel,
    DataTable,
    Column,
    InputNumber,
    Calendar,
    FileUpload,
    AutoComplete,
    ProgressSpinner,
    Dialog,
    MultiSelect,
    RadioButton,
    JoditEditor,
    WidgetSettingsStreamView,
    DataIconView,
    ColorSelectView,
    VueGpickr,
    PlusCircleSvg,
    TreeSelectView,
    TreeWithCheckboxesView,
    ImageGalleryDialogView,
    ImageGalleryDialogButtonView
  },
  directives: {
  }
})
class WidgetSettingsView extends Vue {
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  widgetFeatures = WidgetFeature;
  colorHelper = ColorHelper;

  get widgetDescription(): WidgetDescription | undefined {
    return WidgetHelper.getWidget(this.widgetConfig.widgetType);
  }

  get bws(): BasicWidgetSettings {
    return this.widgetConfig.widgetOptions.basicWidgetSettings;
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  navTreeStore = useNavTreeStore();

  get navTreeState(): NavTreeState {
    return this.navTreeStore;
  }

  organisationStore = useOrganisationStore();
  organisationFlowsStore = useOrganisationFlowsStore();  
  notificationGroupStore = useNotificationGroupStore();

  arrayMoveMutable = arrayMoveMutable;

  created(): void {
    this.fromWidgetConfigToVariables();
    if (this.widgetDescription?.features.includes(WidgetFeature.dataStreams)) {
      if (!this.navTreeState.isLoaded) {
        this.navTreeStore.load();
      }
      if (!this.navTreeState.unstructuredIsLoaded) {
        this.navTreeStore.loadUnstructured();
      }
    }
    this.loadDashboards();
    // reset location search
    this.$store.dispatch("dashboard/loadWeatherLocations", "");
    // init stream collapsed array
    this.initCollapseStreams();
    // init groups
    this.initGroups();
    // init sites
    this.initSites();
    // init notification groups
    if (this.widgetDescription?.features.includes(WidgetFeature.notificationGroups)) {
      this.loadOrganisationNotificationGroups();
    }
    if (this.widgetDescription?.features.includes(WidgetFeature.notificationFilter)) {
      this.loadOrganisationFlows();
    }
  }

  unmounted(): void {
    const poolKeys = [];
    for (const poolKey in this.$store.state.pool.poolStreamsDictionary) {
      poolKeys.push(poolKey);
    }
    poolKeys.forEach(poolKey => {
      this.$store.commit("pool/unload", poolKey);
    });

    const streamKeys: string[] = [];
    for (const streamKey in this.dashboardState.streams) {
      poolKeys.push(streamKey);
    }
    streamKeys.forEach(streamKey => {
      this.$store.commit("dashboard/unload", streamKey);
    });
  }

  // #region timezones
  get timezones(): TimeZoneDto[] {
    return this.$store.state.timezones;
  }
  // #endregion timezones

  // #region weather location
  get filteredLocations(): WeatherLocation[] | undefined {
    return this.dashboardState.weatherLocations;
  }

  searchLocation(event: AutoCompleteCompleteEvent): void {
    this.$store.dispatch("dashboard/loadWeatherLocations", event.query);
  }
  // #endregion weather location

  // #region image
  fillTypes = [
    {
      id: "fill",
      name: "Fill"
    },
    {
      id: "fit",
      name: "Fit"
    },
    {
      id: "stretch",
      name: "Stretch"
    }
  ];

  displayImageGalleryDialog = false;

  onImageSelected(entity: ImageGalleryEntity | null): void {
    if (entity && this.aws) {
      if (entity.OptimizedFilename) {
        this.aws.widgetImageSrc = `${this.uploadUrl}/${entity.OptimizedFilename}`;
      } else {
        this.aws.widgetImageSrc = `${this.uploadUrl}/${entity.Filename}`;
      }
    }
    this.displayImageGalleryDialog = false;
  }

  get uploadUrl() {
    return `${this.$store.state.apiUrl}/rest/AWS_S3_V1/File`;
  }

  get uploadImageUrl() {
    return `${this.$store.state.apiUrl}/rest/BitPool_V2/os/ImageGallery/default/Upload`;
  }

  // Callback to invoke before file send begins to customize the request such as adding headers.
  beforeSend(event: FileUploadBeforeSendEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.formData: FormData object.
    event.xhr.setRequestHeader("Authorization", this.$store.state.auth.authKey);
    event.xhr.setRequestHeader("Accept", "application/json");
  }

  // Callback to invoke when file upload is complete.
  onUploadImage(event: FileUploadErrorEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Uploaded files.
    const response: ImageGalleryEntity[] = JSON.parse(event.xhr.response);
    if (this.aws && response && response.length) {
      if (response[0].OptimizedFilename) {
        this.aws.widgetImageSrc = `${this.uploadUrl}/${response[0].OptimizedFilename}`;
      } else {
        this.aws.widgetImageSrc = `${this.uploadUrl}/${response[0].Filename}`;
      }
    }
    ToastService.showToast("success", "", "Upload complete!", 5000);
  }

  // Callback to invoke if file upload fails.
  onError(event: any): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Files that are not uploaded.
    ToastService.showToast("error", "Can't upload file", ErrorHelper.handleAxiosError(event.xhr).message, 5000);
  }
  // #endregion image

  // #region file
  // Callback to invoke when file upload is complete.
  onUploadFile(event: FileUploadErrorEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Uploaded files.
    const response = JSON.parse(event.xhr.response);
    if (this.aws && response && response.length) {
      this.aws.widgetFileUrl = `${this.uploadUrl}/${response[0]}`;
    }
    ToastService.showToast("success", "", "Upload complete!", 5000);
  }
  // #endregion file

  // #region map zoom leavel
  get mapInitialZoomPercent(): number {
    const value = this.wds?.mapInitialZoom ?? -1;
    let result = 100;
    if (value > 0) {
      result = result * value * 2;
    } else if (value < 0) {
      result = result / (Math.abs(value) * 2);
    }
    return result;
  }

  set mapInitialZoomPercent(value: number) {
    if (this.wds) {
      if (value > 100) {
        this.wds.mapInitialZoom = value / 200;
      } else if (value < 100) {
        this.wds.mapInitialZoom = -100 / (value * 2);
      } else {
        this.wds.mapInitialZoom = 0;
      }
    }
  }
  // #endregion map zoom level

  // #region background color
  toggleWidgetBackgroundColorOverlay(event: Event): void {
    if (this.$refs.widgetBackgroundOverlayPanel) {
      (this.$refs.widgetBackgroundOverlayPanel as OverlayPanel).toggle(event);
    }
  }

  updateWidgetBackgroundColor(color: string): void {
    if (this.aws) {
      this.aws.widgetColorEnabled = true;
      this.aws.widgetColorHex = color;
    }
  }
  // #endregion background color

  // #region heatmap & radialgaugehc
  updateWidgetInactiveColor(color: string): void {
    if (this.aws) {
      this.aws.inactiveColor = color;
    }
  }

  // usePercentageValues = [
  //   {name: 'Actual Values', key: 0},
  //   {name: 'Percentages', key: 1}
  // ];

  get gradient(): typeof LinearGradient {
    const result = new LinearGradient({
      angle: 90,
      stops: this.aws?.gaugeRange ?
        this.aws?.gaugeRange.map(x => [x.color, x.fromP / 100]) :
        [
          ['#4CAF50', 0],
          ['#f44336', 0.5]
        ]
    });
    return result;
  }

  set gradient(value: typeof LinearGradient) {
    this.updateGaugeRange(value);
  }


  @Watch('aws.minMaxRange', { immediate: false, deep: true })
  updateGaugeRangeFromMinMaxRange(): void {
    if (this.aws?.gaugeRange) {
      this.updateGaugeRange(this.gradient);
    }
  }

  updateGaugeRange(value: typeof LinearGradient): void {
    if (this.aws) {
      this.aws.gaugeRange = value.stops
        .map((x: [string, number], i: number): HeatmapGaugeRange => {
          return {
            color: x[0].slice(0, 7),
            fromP: x[1] * 100,
            from: this.buildGradientActualValue(x),
            toP: i >= value.stops.length - 1 ? 100 : value.stops[i + 1][1] * 100,
            to: i >= value.stops.length - 1 ? (this.aws && this.aws.minMaxRange ? this.aws.minMaxRange[1] : 100) : this.buildGradientActualValue(value.stops[i + 1])
          };
        });
    }
  }

  private buildGradientActualValue(x: [string, number]): number {
    return this.aws && this.aws.minMaxRange ? ((this.aws.minMaxRange[1] - this.aws.minMaxRange[0]) * x[1] + this.aws.minMaxRange[0]) : 0;
  }
  // #region heatmap & radialgaugehc

  // #region alarm
  alarmRules = [
    { name: ">=", id: "gte" },
    { name: ">", id: "gt" },
    { name: "<=", id: "lte" },
    { name: "<", id: "lt" }
  ];
  // #endregion alarm

  // #region data grid
  tableFields = [
    { name: 'Record Count', key: 'streamLogsCount' },
    { name: 'First Value', key: 'firstValue' },
    { name: 'Last Value', key: 'lastValue' },
    { name: 'Minimum', key: 'min' },
    { name: 'Maximum', key: 'max' },
    { name: 'Average', key: 'avg' },
    { name: 'Difference', key: 'diff' },
    { name: 'Sum', key: 'sum' }
  ];
  // #endregion data grid

  // #region activity gauge
  centerValues = [
    { name: 'Max', key: 'Max' },
    { name: 'Min', key: 'Min' },
    { name: 'Avg', key: 'Avg' }
  ];
  // #endregion activity gauge

  // #region units
  unitsPositions = [
    { name: 'Before', key: 0 },
    { name: 'After', key: 1 }
  ];
  // #endregion units

  // #region data icon
  dataIcons = [
    {
      id: "bitpool",
      name: "Bitpool"
    }, {
      id: "electricity",
      name: "Electricity"
    }, {
      id: "water",
      name: "Water"
    }, {
      id: "fan",
      name: "Fan"
    }, {
      id: "data_01",
      name: "Data 01"
    }, {
      id: "data_02",
      name: "Data 02"
    }, {
      id: "plug",
      name: "Plug"
    }, {
      id: "temperature",
      name: "Temperature"
    }
  ];

  getDataIconNameById(id: string): string {
    const result = this.dataIcons.find(x => x.id === id);
    return result ? result.name : "";
  }
  // #endregion data icon

  // #region widget mode
  // if you need different modes for different widgets you can transform to getter with switch by widget type
  widgetModes = [
    {
      id: "bars",
      name: "Bars"
    }, {
      id: "arrows",
      name: "Arrows"
    }, {
      id: "arrowsOnly",
      name: "Icon"
    }
  ];
  // #endregion widget mode

  // #region notifications
  loadOrganisationNotificationGroups(): void {
    if (!this.notificationGroupStore.isLoaded) {
      this.notificationGroupStore.load();
    }
  }

  loadOrganisationFlows(): void {
    if (this.organisationStore.currentOrganisation) {
      const orgIdStr = this.organisationStore.currentOrganisation.Id.toString();
      if (!this.organisationFlowsStore.data[orgIdStr]?.isLoaded && !this.organisationFlowsStore.data[orgIdStr]?.isLoadingInProgress) {
        this.organisationFlowsStore.load(this.organisationStore.currentOrganisation.Id);
      }
    }
  }

  get flows(): FlowEntity[] {
    const result: FlowEntity[] = [];
    if (this.organisationStore.currentOrganisation) {
      const orgIdStr = this.organisationStore.currentOrganisation.Id;
      const orgFlows: FlowEntity[] = this.organisationFlowsStore.data[orgIdStr].entities ?? [];
      if (orgFlows.length) {
        result.push(...orgFlows);
      }
    }
    return result;
  }

  alarmTypes: string[] = [
    "Out of Limits",
    "Data Anomaly",
    "Data Quality",
    "AI Insight",
    "CSV Export",
    "Tag Value"
  ];

  get selectedNotificationFiltersCount(): number {
    if (this.aws) {
      return (this.aws.notificationFilter ? 1 : 0) + 
        (this.aws.notificationSubtitles?.length ? 1 : 0) + 
        (this.aws.notificationSourceIds?.length ? 1 : 0);
    } else {
      return 0;
    }
  }

  resetNotificationFilters(): void {
    if (this.aws) {
      this.aws.notificationFilter = "";
      this.aws.notificationSourceIds = [];
      this.aws.notificationSubtitles = [];
    }
  }

  get notificationStatus(): string | null {
    if (this.aws) {
      switch (this.aws.notificationStatus) {
        case true:
          return "Read";
        case false:
          return "Unread";
        case null:
        default:
          return "All";
      }
    }
    return null;
  }

  set notificationStatus(value: string | null) {
    if (this.aws) {
      switch (value) {
        case "Read":
          this.aws.notificationStatus = true;
          break;
        case "Unread":
        this.aws.notificationStatus = false;
          break;
        case "All":
        default:
          this.aws.notificationStatus = null;
      }
    }
  }

  // #endregion notifications

  // #region stacked chart
  leaderboardDirections = [
    { name: 'Ascending', key: 'asc' },
    { name: 'Descending', key: 'desc' }
  ];
  // #endregion stacked chart

  // #region stacked chart
  stackingModes = [
    { name: 'Normal', key: 'normal' },
    { name: 'Percent', key: 'percent' }
  ];
  // #endregion stacked chart

  // #region link
  nodes: TreeNodeForUI[] = [];
  isDashboardsLoaded = false;

  async loadDashboards(): Promise<void> {
    if (this.widgetDescription?.features.includes(WidgetFeature.link) || 
      this.widgetDescription?.features.includes(WidgetFeature.dataSitesLink) ||
      this.widgetDescription?.features.includes(WidgetFeature.dataStreamsLink)
    ) {
      this.isDashboardsLoaded = false;
      if (!this.dashboardState.dashboards) {
        await this.$store.dispatch("dashboard/loadOrganisation", { id: null, silent: true });
      }
      if (!this.dashboardState.dashboardsPersonal) {
        await this.$store.dispatch("dashboard/loadPersonal", { id: null, silent: true });
      }
      const treeAndDictionaries = SpaceHelper.buildTreeForTreeSelect(this.dashboardState.dashboards, this.dashboardState.dashboardsPersonal);
      this.nodes = treeAndDictionaries[0];
      this.isDashboardsLoaded = true;
    }
  }

  dashboardSelected(node: TreeNodeForUI): void {
    if (this.aws) {
      this.aws.link = node.key ?? "";
      this.aws.linkDashboardType = node.additionalData.isOrganisation ? DashboardType.Organisation : DashboardType.Personal;
    }
  }

  get linkTypes() {
    const result = [
      { name: 'Dashboard', key: 0 },
      { name: 'URL', key: 1 }
    ];
    if (this.widgetDescription?.features.includes(WidgetFeature.aiCustomQuestion)) {
      result.push({ name: 'Bitpool AI', key: 2 });
    }
    return result;
  }
  // #endregion link

  // #region axis
  get yAxis(): YAxis[] {
    const result = this.aws?.yaxis;
    return result ? result : [];
  }

  set yAxis(value: YAxis[]) {
    if (this.aws) {
      this.aws.yaxis = value.length ? value : [{ name: "" }];
    }
  }

  get yAxisOptions(): any[] {
    const result = this.aws?.yaxis?.map((axis, index) => { return {id: index, name: axis.name }; });
    return result ? result : [];
  }
  // #endregion axis

  // #region groups
  groups: YAxis[] = [];

  initGroups(): void {
    const result = this.wds?.groupNames;
    this.groups = result ? result.map(x => { return { name: x } }) : [];
  }

  @Watch('groups', { immediate: false, deep: true })
  updateGroups(): void {
    if (this.wds) {
      this.wds.groupNames = this.groups.length ? this.groups.map((x, i) => { return x.name.length ? x.name : `Group-${i + 1}` }) : ["Group-1"];
    }
  }
  // #endregion groups

  // #region map type
  mapTypes = [
    { name: 'Roadmap', key: 'roadmap' },
    { name: 'Satellite', key: 'satellite' },
    { name: 'Terrain', key: 'terrain' },
    { name: 'Hybrid', key: 'hybrid' }
  ];
  // #endregion map type

  // #region sites
  get allSites(): TreeNodeForUI[] {
    const data = this.navTreeState.isLoaded ? this.navTreeState.structuredDataForUI ?? [] : [];
    const result = data.map(x => { 
      const result: TreeNodeForUI = JSON.parse(JSON.stringify(x));
      result.selectable = true;
      result.leaf = true;
      result.children = undefined;
      return result;
    });
    return result;
  }

  sites: WidgetDataSettingsSite[] = [];

  initSites(): void {
    const result = this.wds?.sites;
    this.sites = result ? result : [];
  }

  @Watch('sites', { immediate: false, deep: true })
  updateSites(): void {
    if (this.wds) {
      this.wds.sites = this.sites.length ? this.sites : [];
    }
  }
  // #endregion sites

  // #region trigger levels
  updateTriggerLevelsColor(data: TriggerLevel, color: string): void {
    if (data) {
      data.color = color;
    }
  }
  // #endregion trigger levels

  // #region gdrs
  get useGDRS(): boolean {
    const result = !!this.aws?.useGDRS;
    return result;
  }

  set useGDRS(value: boolean) {
    if (this.aws) {
      this.aws.useGDRS = value ? 1 : 0;
    }
  }

  get gdrsClass(): string[] {
    const result: string[] = [];
    if (this.aws && this.widgetDescription) {
      if (this.widgetDescription?.features.includes(WidgetFeature.showNullValues)) {
        result.push('sm:col-6');
      } else {
        if (this.widgetDescription?.features.includes(WidgetFeature.dataAggregation)) {
          // only data aggregation
          // nothing  
        } else {
          // none
          result.push('sm:col-6 sm:pt-1 sm:align-self-center');
        }
      }

    }
    return result;
  }
  // #endregion gdrs

  // #region null values
  get showNullValues(): boolean {
    const result = !!this.wds?.showNullValues;
    return result;
  }

  set showNullValues(value: boolean) {
    if (this.wds) {
      this.wds.showNullValues = value ? 1 : 0;
    }
  }
  // #endregion null values

  // #region data aggregation
  get selectedDataAggregation(): number {
    if (this.wds) {
      if (this.wds.autoAggPeriod) {
        return -1;
      } else {
        return this.wds.aggPeriod;
      }
    } else {
      return -1;
    }
  }

  set selectedDataAggregation(value: number) {
    if (this.wds) {
      if (value === -1) {
        this.wds.autoAggPeriod = true;
      } else {
        this.wds.aggPeriod = value;
        this.wds.autoAggPeriod = false;
      }
    }
  }

  dataAggregations = DateHelper.getDataAggregations();
  // #endregion data aggregation

  // #region dates
  dateFrom: Date = new Date();
  dateTo: Date = new Date();
  selectedDateRange = TimeRange.Custom;
  dateRanges = DateHelper.getDateRanges();

  internalWDSUpdate = false;
  @Watch('wds', { immediate: false, deep: true })
  fromWidgetConfigToVariables(): void {
    if (this.wds && !this.internalWDSUpdate) {
      this.selectedDateRange = this.wds.rangePreset;
      if (this.wds.rangePreset === TimeRange.Custom) {
        const startDateM = moment(`${this.wds.startDate}T${this.wds.startTime}`);
        const endDateM = moment(`${this.wds.endDate}T${this.wds.endTime}`);
        this.dateFrom = startDateM.toDate();
        this.dateTo = endDateM.toDate();
      } else {
        this.setDates(true);
      }
    }
    this.internalWDSUpdate = false;
  }

  fromVariablesToWidgetConfig(): void {
    if (this.wds) {
      if (this.selectedDateRange !== TimeRange.Custom) {
        this.wds.rangePresetHolder = this.selectedDateRange;
      }
      this.wds.rangePreset = this.selectedDateRange;
      if (this.selectedDateRange === TimeRange.Custom) {
        const startDateM = moment(this.dateFrom);
        const endDateM = moment(this.dateTo);
        this.wds.startDate = startDateM.format("YYYY-MM-DD");
        this.wds.startTime = startDateM.format("HH:mm:ss");
        this.wds.endDate = endDateM.format("YYYY-MM-DD");
        this.wds.endTime = endDateM.format("HH:mm:ss");
      } else {
        this.wds.startDate = "";
        this.wds.startTime = "";
        this.wds.endDate = "";
        this.wds.endTime = "";
      }
      this.internalWDSUpdate = true;
    }
  }

  setDates(silent: boolean): void {
    const dates = DateHelper.timeRangeToMoments(this.selectedDateRange);
    const start = dates[0];
    const end = dates[1];
    if (start) {
      this.dateFrom = start.toDate();
    }
    if (end) {
      this.dateTo = end.toDate();
    }
    if (!silent) {
      this.fromVariablesToWidgetConfig();
    }
  }

  closeCalendar(): void {
    if (this.$refs.calendarFrom) {
      (this.$refs.calendarFrom as any).overlayVisible = false;
    }
    if (this.$refs.calendarTo) {
      (this.$refs.calendarTo as any).overlayVisible = false;
    }
  }

  onChangeDate(): void {
    this.debounceSelectedDateRangeTrigger();
  }

  debounceSelectedDateRangeTrigger = debounce(500, this.onSelectedDateRangeChanged);

  onSelectedDateRangeChanged(): void {
    this.selectedDateRange = TimeRange.Custom;

    this.fromVariablesToWidgetConfig();
  }
  // #endregion dates

  // #region streams
  async addStream(): Promise<void> {
    if (this.wds && this.widgetDescription && this.widgetDescription.defaultStreamOptions) {
      this.wds.streamOptions.push(JSON.parse(JSON.stringify(this.widgetDescription.defaultStreamOptions)));
      if (this.widgetDescription.name === "tariffsimulator" && this.wds.streamOptions.length === 2) {
        this.wds.streamOptions[1].Params.aggType = AggregationType.Max; // max for kW
      }
      this.collapseStreams.push(false);
      await nextTick();
      const targetScrollElement = document.getElementById(`streamContainer${this.wds.streamOptions.length - 1}`);
      if (targetScrollElement) {
        targetScrollElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  // #region add multiple streams
  displaySelectStreams = false;
  structuredView = false;
  structuredSelectedNodes: TreeNodeForUI[] = [];
  unstructuredSelectedNodes: TreeNodeForUI[] = [];

  openSelectStreamsDialog(): void {
    this.structuredSelectedNodes = [];
    this.unstructuredSelectedNodes = [];
    this.displaySelectStreams = true;
  }

  closeSelectStreamsDialog(): void {
    this.displaySelectStreams = false;
  }

  structuredChangeSelected(nodes: TreeNodeForUI[]): void {
    this.structuredSelectedNodes = nodes;
  }

  unstructuredChangeSelected(nodes: TreeNodeForUI[]): void {
    this.unstructuredSelectedNodes = nodes;
  }

  async addSelectedStreams(): Promise<void> {
    const scrollTo = this.wds?.streamOptions.length ?? 0;
    this.unstructuredSelectedNodes.forEach(node => {
      if (this.wds && this.widgetDescription && this.widgetDescription.defaultStreamOptions && node.key) {
        const streamOption: StreamOption = JSON.parse(JSON.stringify(this.widgetDescription.defaultStreamOptions));
        streamOption.StreamKey = node.key;
        streamOption.Name = node.label ?? "";
        streamOption.Label = node.label ?? "";
        streamOption.structured = false;
        this.wds.streamOptions.push(streamOption);
        this.collapseStreams.push(false);
      }
    });
    this.structuredSelectedNodes.forEach(node => {
      if (this.wds && this.widgetDescription && this.widgetDescription.defaultStreamOptions && node.key) {
        const streamOption: StreamOption = JSON.parse(JSON.stringify(this.widgetDescription.defaultStreamOptions));
        streamOption.StreamKey = node.key;
        streamOption.Name = node.label ?? "";
        streamOption.Label = node.label ?? "";
        streamOption.structured = true;
        this.wds.streamOptions.push(streamOption);
        this.collapseStreams.push(false);
      }
    });
    this.closeSelectStreamsDialog();
    await nextTick();
    const targetScrollElement = document.getElementById(`streamContainer${scrollTo}`);
    if (targetScrollElement) {
      targetScrollElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  // #endregion add multiple streams

  moveStream(fromIndex: number, toIndex: number): void {
    if (this.wds) {
      arrayMoveMutable(this.wds.streamOptions, fromIndex, toIndex);
      arrayMoveMutable(this.collapseStreams, fromIndex, toIndex);
    }
  }

  deleteStream(index: number): void {
    ConfirmationService.showConfirmation({
      message: "Are you sure you want to delete stream?",
      header: 'Delete Stream',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.wds) {
          this.wds.streamOptions.splice(index, 1);
          this.collapseStreams.splice(index, 1);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteAllStreams(): void {
    ConfirmationService.showConfirmation({
      message: "Are you sure you want to delete all streams?",
      header: 'Delete Streams',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.wds) {
          if (this.wds.streamOptions?.length) {
            this.wds.streamOptions.splice(0, this.wds.streamOptions.length);
          }
          if (this.collapseStreams?.length) {
            this.collapseStreams.splice(0, this.collapseStreams.length);
          }
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  collapseStreams: boolean[] = [];

  initCollapseStreams(): void {
    this.collapseStreams = [];
    if (this.wds?.streamOptions?.length) {
      this.wds.streamOptions.forEach(element => {
        this.collapseStreams.push(true);
      });
    }
  }

  toggleCollapseStreams(index: number): void {
    this.collapseStreams[index] = !this.collapseStreams[index];
  }
  // #endregion streams

  // #region limit tracker 
  updateUnderLimitColor(color: string): void {
    if (this.aws) {
      this.aws.underLimitColor = color;
    }
  }
  updateOverLimitColor(color: string): void {
    if (this.aws) {
      this.aws.overLimitColor = color;
    }
  }
  // #endregion limit tracker

  // #region Data Conditions
  get firstStreamDataType(): StreamDataType {
    if (this.wds?.streamOptions?.length && this.wds?.streamOptions[0].StreamKey) {
      const streamKey = this.wds.streamOptions[0].StreamKey;
      const data = this.dashboardState.streams[streamKey];
      if (data) {
        const stream = data[1];
        const result = stream ? stream.DataType as StreamDataType : StreamDataType.Double;
        return result;
      }
    }
    return StreamDataType.Double;
  }
  // #endregion Data Conditions
}

export default WidgetSettingsView;
</script>
