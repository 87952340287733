<template>
  <Dialog 
    header="Add an element" 
    v-model:visible="displayDialog" 
    :modal="true" 
    :style="{width: '70rem'}"
    class="reports-elements-dialog"
  >
    <template #header>
      <div class="sm:flex justify-content-between align-items-center w-full flex-auto">
        <span class="p-dialog-title mb-0 mr-5">Add an element</span>

        <IconField iconPosition="left" class="search-input-box mb-0">
          <InputIcon class="pi pi-search lg:text-xl"></InputIcon>
          <InputText
            class="inputfield lg:text-lg"
            placeholder="Search"
            type="text"
            v-model="search"
            @input="debounceSearch()"
            style="border-radius: 3rem;"
          />
        </IconField>
      </div>
    </template>

    <div class="dialog-content">
      <div class="graphic-library">
        <div v-if="reports3Elements.isLoaded" class="graphic-library-tiles-images">
          <div 
            v-for="element in reports3Elements.data?.Items ?? []" 
            :key="element.Id" 
            @click="elementSelected(element)"
          >
            <div class="graphic-library-image">
              <img v-if="element.Image" :src="element.Image" />
            </div>
            <span>{{ element.Name }}</span>
            <p>{{ element.Description }}</p>
          </div>
        </div>
        <div v-else class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;">
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
        <Paginator 
          v-model:first="reports3Elements.skip" 
          :rows="reports3Elements.take" 
          :totalRecords="reports3Elements.data?.Total" 
          @page="onPage" 
          template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
        />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Component, Emit, Model, Prop, Vue, Watch } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Paginator from 'primevue/paginator';
import { debounce } from "throttle-debounce";
import { useReports3ElementsStore } from "@/stores/reports3Elements";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";

@Component({
  components: {
    InputText,
    IconField,
    InputIcon,
    Dialog,
    ProgressSpinner,
    Paginator
  },
})
class ReportsSelectElementDialogView extends Vue {
  @Model displayDialog!: boolean;
  @Prop({ default: Reports3ElementRole.Body }) elementRole!: Reports3ElementRole;

  @Emit elementSelected(entity: Reports3ElementEntity | null): Reports3ElementEntity | null {
    return entity;
  }
  
  reports3Elements = useReports3ElementsStore();
  
  search = "";
  searchFinal = "";
  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  @Watch('searchFinal', { immediate: false, deep: false })
  async onSearchFinalChanged(val: string, oldVal: string): Promise<void> {
    this.loadData();
  }

  @Watch('displayDialog', { immediate: false, deep: false })
  async onElementChanged(val: boolean, oldVal: boolean): Promise<void> {
    if (val) {
      this.searchFinal = this.reports3Elements.search;
      if (!this.reports3Elements.isLoaded || this.reports3Elements.elementRole !== this.elementRole) {
        this.loadData();
      }
    }
  }
  
  loadData(): void {
    this.reports3Elements.load(this.reports3Elements.skip, this.reports3Elements.take, this.searchFinal, this.elementRole);
  }

  onPage(event: any): void {
    // event.page: New page number
    // event.first: Index of first record
    // event.rows: Number of rows to display in new page
    // event.pageCount: Total number of pages
    this.reports3Elements.skip = event.page * event.rows;
    this.reports3Elements.take = event.rows;
    this.loadData();
  }

  roleToString(role: number): string {
    switch (role) {
      case 0:
        return "Body";
      case 1:
        return "Header";
      case 2:
        return "Footer";
      default:
        return "Unknown";
    }
  }
}

export default ReportsSelectElementDialogView;
</script>