<template>
  <div class="report-constructor-settings">
    <div class="report-constructor-settings-title">
      <div v-if="!reports3.dataOneSelectedElementConfiguration && reports3.dataOne && !reports3.isCompactMode">
        <h3>{{ reports3.getSelectedElementTitle }}</h3>
      </div>
      <div v-else-if="reports3.dataOneSelectedElementConfiguration?.Role === Reports3ItemRole.Grid && !reports3.isCompactMode">
        <h3>{{ reports3.getSelectedElementTitle }}</h3>
      </div>
      <div v-else-if="reports3.dataOneSelectedElementConfiguration?.Role === Reports3ItemRole.Element">
        <h3 v-if="!reports3.isCompactMode">{{ reports3.getSelectedElementTitle }}</h3>
        <p v-if="reports3.getSelectedElementDescription" class="help-text">{{ reports3.getSelectedElementDescription }}</p>
      </div>
    </div>

    <div v-if="reports3.dataOneSelectedElementConfiguration && reports3.dataOneSelectedElementConfiguration.Uid !== reports3.dataOne?.Header?.Uid && reports3.dataOneSelectedElementConfiguration.Uid !== reports3.dataOne?.Footer?.Uid" class="report-constructor-settings-item">
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" style="transform: translateY(-1px);"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m12 8 7-7m0 0h-6m6 0v6M8 12l-7 7m0 0h6m-6 0v-6"/></svg>
        <span>Size</span>
      </h4>
      <div class="formgrid grid">
        <div class="field col-12 mb-0">
          <label for="editRecordItemSize">Width</label>
          <div>
            <Dropdown 
              intupId="editRecordItemSize"
              v-model="reports3.dataOneSelectedElementConfiguration.Size"
              :options="columns" 
              optionValue="key" 
              optionLabel="name" 
              placeholder="Select size" 
              class="w-full"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="!reports3.dataOneSelectedElementConfiguration && reports3.dataOne" class="report-constructor-settings-item">
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1 7h18M7 7v12M5.8 1h8.4c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C19 3.28 19 4.12 19 5.8v8.4c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C16.72 19 15.88 19 14.2 19H5.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C1 16.72 1 15.88 1 14.2V5.8c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C3.28 1 4.12 1 5.8 1Z"/></svg>
        <span>Layout</span>
      </h4>
      <div class="formgrid grid">
        <div class="field col-12 mb-0">
          <label>Margin</label>
        </div>
        <div class="field col-6 flex align-items-center gap-2">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.5 1.5v.008M9 1.5v.008M5.25 1.5v.008m7.5-.008v.008M16.5 5.25v.008M9 5.25v.008m7.5 7.492v.008M9 12.75v.008M16.5 9v.008M9 9v.008M5.25 9v.008M12.75 9v.008M16.5 16.5v.008M9 16.5v.008M5.25 16.5v.008m7.5-.008v.008M1.5 16.5v-15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginLeft"
              class="inputfield w-full"
              v-model="reports3.dataOne.MarginLeft"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
        <div class="field col-6 flex align-items-center gap-2">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.5 16.5h-.008M16.5 9h-.008m.008-3.75h-.008m.008 7.5h-.008M12.75 16.5h-.008M12.75 9h-.008M5.25 16.5h-.008M5.25 9h-.008M9 16.5h-.008M9 9h-.008M9 5.25h-.008M9 12.75h-.008M1.5 16.5h-.008M1.5 9h-.008M1.5 5.25h-.008m.008 7.5h-.008M1.5 1.5h15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginTop"
              class="inputfield w-full"
              v-model="reports3.dataOne.MarginTop"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
        <div class="field col-6 flex align-items-center gap-2 mb-0">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1.5 16.5v-.008M9 16.5v-.008m3.75.008v-.008m-7.5.008v-.008M1.5 12.75v-.008m7.5.008v-.008M1.5 5.25v-.008M9 5.25v-.008M1.5 9v-.008M9 9v-.008M12.75 9v-.008M5.25 9v-.008M1.5 1.5v-.008M9 1.5v-.008m3.75.008v-.008m-7.5.008v-.008M16.5 1.5v15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginRight"
              class="inputfield w-full"
              v-model="reports3.dataOne.MarginRight"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
        <div class="field col-6 flex align-items-center gap-2 mb-0">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1.5 1.5h.008M1.5 9h.008M1.5 12.75h.008M1.5 5.25h.008M5.25 1.5h.008M5.25 9h.008m7.492-7.5h.008M12.75 9h.008M9 1.5h.008M9 9h.008M9 12.75h.008M9 5.25h.008M16.5 1.5h.008M16.5 9h.008m-.008 3.75h.008m-.008-7.5h.008M16.5 16.5h-15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginBottom"
              class="inputfield w-full"
              v-model="reports3.dataOne.MarginBottom"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
      </div>
    </div>

    <div 
      v-if="reports3.dataOneSelectedElementConfiguration?.Role === Reports3ItemRole.Element && reports3.getSelectedElementEntity?.Role === Reports3ElementRole.Body" 
      class="report-constructor-settings-item"
    >
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 16V4m0 12-3-2m3 2 3-2M10 4 7 6m3-2 3 2m6-5H1m18 18H1"/></svg>
        <span>Space</span>
      </h4>
      <div class="formgrid grid">
        <div class="field col-12 mb-0">
          <label>Margin</label>
        </div>
        <div class="field col-6 flex align-items-center gap-2">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.5 1.5v.008M9 1.5v.008M5.25 1.5v.008m7.5-.008v.008M16.5 5.25v.008M9 5.25v.008m7.5 7.492v.008M9 12.75v.008M16.5 9v.008M9 9v.008M5.25 9v.008M12.75 9v.008M16.5 16.5v.008M9 16.5v.008M5.25 16.5v.008m7.5-.008v.008M1.5 16.5v-15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginLeft"
              class="inputfield w-full"
              v-model="reports3.dataOneSelectedElementConfiguration.MarginLeft"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
        <div class="field col-6 flex align-items-center gap-2">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.5 16.5h-.008M16.5 9h-.008m.008-3.75h-.008m.008 7.5h-.008M12.75 16.5h-.008M12.75 9h-.008M5.25 16.5h-.008M5.25 9h-.008M9 16.5h-.008M9 9h-.008M9 5.25h-.008M9 12.75h-.008M1.5 16.5h-.008M1.5 9h-.008M1.5 5.25h-.008m.008 7.5h-.008M1.5 1.5h15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginTop"
              class="inputfield w-full"
              v-model="reports3.dataOneSelectedElementConfiguration.MarginTop"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
        <div class="field col-6 flex align-items-center gap-2 mb-0">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1.5 16.5v-.008M9 16.5v-.008m3.75.008v-.008m-7.5.008v-.008M1.5 12.75v-.008m7.5.008v-.008M1.5 5.25v-.008M9 5.25v-.008M1.5 9v-.008M9 9v-.008M12.75 9v-.008M5.25 9v-.008M1.5 1.5v-.008M9 1.5v-.008m3.75.008v-.008m-7.5.008v-.008M16.5 1.5v15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginRight"
              class="inputfield w-full"
              v-model="reports3.dataOneSelectedElementConfiguration.MarginRight"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
        <div class="field col-6 flex align-items-center gap-2 mb-0">
          <label for="editRecordItemSize" class="flex-shrink-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1.5 1.5h.008M1.5 9h.008M1.5 12.75h.008M1.5 5.25h.008M5.25 1.5h.008M5.25 9h.008m7.492-7.5h.008M12.75 9h.008M9 1.5h.008M9 9h.008M9 12.75h.008M9 5.25h.008M16.5 1.5h.008M16.5 9h.008m-.008 3.75h.008m-.008-7.5h.008M16.5 16.5h-15"/></svg>
          </label>
          <div class="flex-auto">
            <InputNumber
              id="editRecordItemMarginBottom"
              class="inputfield w-full"
              v-model="reports3.dataOneSelectedElementConfiguration.MarginBottom"
              :min="0"
              suffix="cm"
              :allowEmpty="false"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="reports3.dataOne && reports3.dataOneSelectedElementConfiguration && reports3.getSelectedElementEntity?.EnableData"  class="report-constructor-settings-item">
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" fill="none"><path fill="currentColor" d="M0 15.518a1.111 1.111 0 0 0 1.111 1.111H18.89A1.111 1.111 0 0 0 20 15.518V13.74a1.111 1.111 0 0 0-1.111-1.111H1.11A1.111 1.111 0 0 0 0 13.74v1.778Zm2-.889a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-.889-14A1.111 1.111 0 0 0 0 1.74v1.778a1.111 1.111 0 0 0 1.111 1.111H18.89A1.111 1.111 0 0 0 20 3.518V1.74A1.111 1.111 0 0 0 18.889.63H1.11Zm2.889 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM0 9.518a1.111 1.111 0 0 0 1.111 1.111H18.89A1.111 1.111 0 0 0 20 9.518V7.74a1.111 1.111 0 0 0-1.111-1.111H1.11A1.111 1.111 0 0 0 0 7.74v1.778Zm2-.889a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"/></svg>
        <span>Data</span>
      </h4>
      <div class="formgrid grid">
        <div class="field col-12">
          <label for="editRecordElementDatasource">Data source</label>
          <div>
            <Dropdown 
              intupId="editRecordElementDatasource"
              v-model="reports3.dataOneSelectedElementConfiguration.DatasourceId"
              :options="reports3.dataOne.Datasources" 
              optionValue="Uid" 
              optionLabel="Name" 
              placeholder="Select data source" 
              class="w-full"
              @change="onDatasourceChange"
            />
          </div>
        </div>
        <div class="field col-12" v-if="selectedDataStreams.length">
          <div class="flex justify-content-between align-items-center mb-2">
            <label class="mb-0">Streams</label>
            <span class="report-constructor-settings-item-label-links">
              <Button label="Select all" link class="p-0" @click="selectAllDataStreams(true)" />
              <i></i>
              <Button label="Deselect all" link class="p-0" @click="selectAllDataStreams(false)" />
            </span>
          </div>
          <div>
            <DataTable 
              :value="selectedDataStreams" 
              showGridlines 
              responsiveLayout="stack" 
              breakpoint="600px"
              class="widget-settings-table responsive-breakpoint p-datatable-sm"
            >
              <Column field="label" header="Stream" headerClass="text-sm" bodyClass="text-sm break-word" headerStyle="min-width: min-content;"></Column>
              <Column field="type" header="Enabled" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 1%; min-width: 42px;">
                <template #body="{ data }">
                  <InputSwitch
                    v-model="data.enabled"
                    @change="onDataStreamsEnabledChange"
                    :disabled="!canSelectMoreStreams && !data.enabled"
                    class="vertical-align-middle"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="field col-12" v-if="reports3.getSelectedElementEntity.Features.includes(Report3ElementFeatures.DataSeriesType) && selectedDataSeriesTypes.length">
          <label>Series type</label>
          <div>
            <DataTable 
              :value="selectedDataSeriesTypes" 
              showGridlines 
              responsiveLayout="stack" 
              breakpoint="600px"
              class="widget-settings-table responsive-breakpoint p-datatable-sm"
            >
              <Column field="label" header="Stream" headerClass="text-sm" bodyClass="text-sm break-word" headerStyle="min-width: min-content;"></Column>
              <Column field="type" header="Type" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 1%; min-width: 126px;">
                <template #body="{ data }">
                  <Dropdown
                    v-model="data.type"
                    :options="seriesTypes"
                    placeholder="Select type" 
                    class="w-full vertical-align-middle"
                    optionLabel="name"
                    optionValue="id"
                    @change="onSeriesTypeChange"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="field col-12" v-if="reports3.getSelectedElementEntity.Features.includes(Report3ElementFeatures.YAxis) && selectedYAxis.length">
          <label>Y Axis</label>
          <div>
            <DataTable 
              :value="selectedYAxis" 
              showGridlines 
              responsiveLayout="stack" 
              breakpoint="600px"
              class="widget-settings-table responsive-breakpoint p-datatable-sm"
            >
              <Column field="label" header="Stream" headerClass="text-sm" bodyClass="text-sm break-word" headerStyle="min-width: min-content;"></Column>
              <Column field="yAxis" header="Y Axis" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 1%; min-width: 126px;">
                <template #body="{ data }">
                  <InputText
                    v-model="data.yAxis"
                    class="w-full vertical-align-middle"
                    @input="onYAxisChange"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>

    <div v-if="reports3.dataOneSelectedElementConfiguration?.AdditionalParameters && reports3.getSelectedElementEntity?.AdditionalParameters?.length"  class="report-constructor-settings-item">
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M.955 4.092h12.857m0 0c0 1.521 1.152 2.755 2.571 2.755 1.42 0 2.572-1.234 2.572-2.755 0-1.522-1.151-2.756-2.572-2.756-1.42 0-2.57 1.234-2.57 2.756Zm-7.714 6.705h12.857m-12.857 0c0 1.522-1.152 2.756-2.571 2.756-1.42 0-2.572-1.234-2.572-2.756 0-1.522 1.151-2.755 2.572-2.755 1.42 0 2.57 1.233 2.57 2.755ZM.956 17.595h12.856m0 0c0 1.521 1.152 2.755 2.571 2.755 1.42 0 2.572-1.234 2.572-2.755 0-1.523-1.151-2.756-2.572-2.756-1.42 0-2.57 1.233-2.57 2.756Z"/></svg>
        <span>Parameters</span>
      </h4>
      <div class="formgrid grid">
        <div 
          class="field col-12" 
          v-for="(additionalParameter, index) in reports3.getSelectedElementEntity.AdditionalParameters" 
          :key="index + reports3.dataOneSelectedElementConfiguration.Uid"
        >
          <label :for="`editRecordParam-${additionalParameter.Name}`">
            {{ additionalParameter.Label }}
          </label>
          <div>
            <InputText 
              v-if="additionalParameter.Type === ScriptAdditionalParameterType.String"
              :id="`editRecordParam-${additionalParameter.Name}`"
              class="inputfield w-full"
              type="text" 
              v-model="reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
            />
            <InputNumber
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Number"
              :id="`editRecordParam-${additionalParameter.Name}`"
              class="inputfield w-full"
              type="text" 
              v-model="reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
            <InputSwitch
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Boolean"
              :id="`editRecordParam-${additionalParameter.Name}`"
              v-model="reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              class="vertical-align-middle"
            />
            <div v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Color" class="flex align-items-center gap-3">
              <ColorSelectView 
                :color="reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]" 
                :updateColor="(color: string) => { 
                  if (reports3.dataOneSelectedElementConfiguration?.AdditionalParameters) {
                    reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name] = color;
                  }
                }"
              />
              <span class="font-medium">{{ reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name] }}</span>
            </div>
            <div v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Image" class="image-inside-table">
              <div
                v-if="reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              >
                <img 
                  class="image-inside-table-img" 
                  :src="reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]" 
                />
                <span
                  class="image-inside-table-delete"
                  @click="() => { 
                    if (reports3.dataOneSelectedElementConfiguration?.AdditionalParameters) {
                      reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name] = ''; 
                    }
                  }"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none"><path stroke-linecap="round" stroke-linejoin="round" d="M9 1 1 9m0-8 8 8"/></svg>
                </span>
              </div>
              <ImageGalleryDialogButtonView class="image-inside-table-btn"
                @imageSelected="(entity: ImageGalleryEntity | null) => { 
                  if (reports3.dataOneSelectedElementConfiguration?.AdditionalParameters) {
                    reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name] = entity ? entity.OptimizedFilename ? `${uploadUrl}/${entity.OptimizedFilename}` : `${uploadUrl}/${entity.Filename}` : '';
                  }
                }"
              />
            </div>
            <Textarea 
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.StringMultiLine"
              :id="`editRecordParam-${additionalParameter.Name}`"
              class="w-full" 
              style="white-space: pre-wrap;"
              v-model="reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :autoResize="true" 
              :rows="3"
            />
            <Dropdown 
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.FontFamily"
              :inputId="`editRecordParam-${additionalParameter.Name}`"
              v-model="reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :options="FontHelper.fontFamilies"
              placeholder="Select font family" 
              class="w-full"
            />
            <Dropdown 
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.FontSize"
              :inputId="`editRecordParam-${additionalParameter.Name}`"
              v-model="reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :options="FontHelper.fontSizes"
              placeholder="Select font size" 
              class="w-full"
            />
            <Dropdown 
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.FontWeight"
              :inputId="`editRecordParam-${additionalParameter.Name}`"
              v-model="reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :options="FontHelper.fontWeights"
              placeholder="Select font weight" 
              class="w-full"
            />
            <Dropdown 
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.FontStyle"
              :inputId="`editRecordParam-${additionalParameter.Name}`"
              v-model="reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :options="FontHelper.fontStyles"
              placeholder="Select font style" 
              class="w-full"
            />
            <SelectButton
              v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.TextAlign" 
              v-model="reports3.dataOneSelectedElementConfiguration.AdditionalParameters[additionalParameter.Name]"
              :options="textAligns" 
              optionLabel="value"
              optionValue="value"
              dataKey="value"
              class="report-constructor-settings-text-align selectbutton-lg"
            >
              <template #option="slotProps">
                <i :class="slotProps.option.icon"></i>
              </template>
            </SelectButton>
            <p v-if="additionalParameter.HelpText" class="help-text">{{ additionalParameter.HelpText }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Textarea from 'primevue/textarea';
import SelectButton from 'primevue/selectbutton';
import { useReports3Store } from "@/stores/reports3";
import WidgetHelper from "@/helpers/WidgetHelper";
import { ScriptAdditionalParameterType } from "@/models/script/ScriptAdditionalParameterType";
import { Reports3ItemRole } from "@/models/reports/v3/Reports3ItemRole";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import { Report3ElementFeatures, Report3ElementFeaturesToString } from "@/models/reports/v3/Report3ElementFeatures";
import ColorSelectView from '@/components/widgets-next/settings/ColorSelectView.vue';
import { ImageGalleryEntity } from "@/models/image-gallery/ImageGalleryEntity";
import ImageGalleryDialogButtonView from "@/components/views/image-gallery/ImageGalleryDialogButtonView.vue"
import { useReports3DataStore } from "@/stores/reports3Data";
import FontHelper from "@/helpers/FontHelper";

@Component({
  components: {
    Button,
    InputText,
    InputNumber,
    InputSwitch,
    Dropdown,
    DataTable,
    Column,
    Textarea,
    SelectButton,
    ColorSelectView,
    ImageGalleryDialogButtonView
  },
  directives: {
  }
})
class ReportsReportLeftPanelView extends Vue { 
  reports3 = useReports3Store();
  reports3Data = useReports3DataStore();

  columns = WidgetHelper.getWidgetSizes();
  ScriptAdditionalParameterType = ScriptAdditionalParameterType;
  Reports3ItemRole = Reports3ItemRole;
  Reports3ElementRole = Reports3ElementRole;
  Report3ElementFeatures = Report3ElementFeatures;
  FontHelper = FontHelper;

  get textAligns(): any[] {
    return FontHelper.textAligns.map(x => ({ value: x, icon: `pi pi-align-${x}` }));
  }

  getElement(id: string): Reports3ElementEntity | undefined {
    if (this.reports3.dataOneElements?.length) {
      const element = this.reports3.dataOneElements.find((x) => x.Id === id);
      return element;
    }
    return undefined;
  }
  
  get uploadUrl() {
    return `${this.$store.state.apiUrl}/rest/AWS_S3_V1/File`;
  }

  async onDatasourceChange(): Promise<void> {
    const elementConfiguration = this.reports3.dataOneSelectedElementConfiguration;
    const datasource = this.reports3.dataOne?.Datasources.find(x => x.Uid === this.reports3.dataOneSelectedElementConfiguration?.DatasourceId);
    if (elementConfiguration && datasource) {
      const element = this.getElement(elementConfiguration.ElementId);
      if (element) {
        await this.reports3Data.load(element, elementConfiguration, datasource, this.reports3.dataOne);
      }
    }
  }

  // #region data streams
  get canSelectMoreStreams(): boolean {
    const maxCount = this.reports3.getSelectedElementEntity?.DataStreamsMaxCount ?? 0;
    return maxCount === 0 || maxCount > this.selectedDataStreams.filter(x => x.enabled).length;
  }

  get selectedDataStreams(): any[] {
    const result: any[] = [];
    if (this.reports3.dataOne?.Datasources.length && this.reports3.dataOneSelectedElementConfiguration) {
      const datasource = this.reports3.dataOne.Datasources.find(x => x.Uid === this.reports3.dataOneSelectedElementConfiguration?.DatasourceId);
      if (datasource) {
        for (const streamOption of datasource.Configuration.streamOptions) {
          result.push({
            uid: streamOption.Uid,
            label: streamOption.Label,
            enabled: false
          });
        }
      }
      const key = "DataStreams";
      if (this.reports3.dataOneSelectedElementConfiguration?.FeaturesConfiguration &&
        this.reports3.dataOneSelectedElementConfiguration.FeaturesConfiguration[key]
      ) {
        const featuresConfiguration = this.reports3.dataOneSelectedElementConfiguration.FeaturesConfiguration[key];
        const uids = Object.getOwnPropertyNames(featuresConfiguration);
        if (uids.length) {
          // restore state
          for (const item of result) {
            if (featuresConfiguration[item.uid]) {
              item.enabled = featuresConfiguration[item.uid];
            }
          }
        }
      }
    }
    return result;
  }

  set selectedDataStreams(value: any[]) {
    this.saveDataStreams(value);
  }

  selectAllDataStreams(value: boolean): void {
    const maxCount = this.reports3.getSelectedElementEntity?.DataStreamsMaxCount ?? 0;
    if (maxCount > 0) {
      for (let i = 0; i < this.selectedDataStreams.length; i++) {
        if (i < maxCount) {
          this.selectedDataStreams[i].enabled = value;
        } else {
          this.selectedDataStreams[i].enabled = false;
        }
      }
    } else {
      for (let i = 0; i < this.selectedDataStreams.length; i++) {
        this.selectedDataStreams[i].enabled = value;
      }
    }
    this.onDataStreamsEnabledChange();
  }

  onDataStreamsEnabledChange(): void {
    this.saveDataStreams(this.selectedDataStreams);
  }

  saveDataStreams(value: any[]): void {
    if (this.reports3.dataOneSelectedElementConfiguration?.FeaturesConfiguration) {
      const featuresConfiguration = this.reports3.dataOneSelectedElementConfiguration.FeaturesConfiguration;
      const key = "DataStreams";
      featuresConfiguration[key] = {};
      for (const item of value) {
        featuresConfiguration[key][item.uid] = item.enabled;
      }
      this.reports3.dataOneSelectedElementConfiguration.FeaturesConfiguration = featuresConfiguration;
    }
  }
  // #endregion data streams

  // #region series type
  seriesTypes = [
    {
      id: "column",
      name: "Column"
    }, {
      id: "line",
      name: "Line"
    }, {
      id: "area",
      name: "Area"
    }, {
      id: "scatter",
      name: "Scatter"
    }, {
      id: "spline",
      name: "Spline"
    }
  ];

  getSeriesTypeNameById(id: string): string {
    const result = this.seriesTypes.find(x => x.id === id); 
    return result ? result.name : "";
  }

  get selectedDataSeriesTypes(): any[] {
    const result: any[] = [];
    if (this.reports3.dataOne?.Datasources.length && this.reports3.dataOneSelectedElementConfiguration) {
      const datasource = this.reports3.dataOne.Datasources.find(x => x.Uid === this.reports3.dataOneSelectedElementConfiguration?.DatasourceId);
      if (datasource) {
        for (const streamOption of datasource.Configuration.streamOptions) {
          result.push({
            uid: streamOption.Uid,
            label: streamOption.Label,
            type: "column"
          });
        }
      }
      const key = Report3ElementFeaturesToString[Report3ElementFeatures.DataSeriesType];
      if (this.reports3.dataOneSelectedElementConfiguration?.FeaturesConfiguration &&
        this.reports3.dataOneSelectedElementConfiguration.FeaturesConfiguration[key]
      ) {
        const featuresConfiguration = this.reports3.dataOneSelectedElementConfiguration.FeaturesConfiguration[key];
        for (const item of result) {
          if (featuresConfiguration[item.uid]) {
            item.type = featuresConfiguration[item.uid];
          }
        }
      }
    }
    return result;
  }

  set selectedDataSeriesTypes(value: any[]) {
    this.saveSeriesTypes(value);
  }

  onSeriesTypeChange(): void {
    this.saveSeriesTypes(this.selectedDataSeriesTypes);
  }

  saveSeriesTypes(value: any[]): void {
    if (this.reports3.dataOneSelectedElementConfiguration?.FeaturesConfiguration) {
      const featuresConfiguration = this.reports3.dataOneSelectedElementConfiguration.FeaturesConfiguration;
      const key = Report3ElementFeaturesToString[Report3ElementFeatures.DataSeriesType];
      featuresConfiguration[key] = {};
      for (const item of value) {
        featuresConfiguration[key][item.uid] = item.type;
      }
      this.reports3.dataOneSelectedElementConfiguration.FeaturesConfiguration = featuresConfiguration;
    }
  }
  // #endregion series type

  // #region y axis
  get selectedYAxis(): any[] {
    const result: any[] = [];
    if (this.reports3.dataOne?.Datasources.length && this.reports3.dataOneSelectedElementConfiguration) {
      const datasource = this.reports3.dataOne.Datasources.find(x => x.Uid === this.reports3.dataOneSelectedElementConfiguration?.DatasourceId);
      if (datasource) {
        for (const streamOption of datasource.Configuration.streamOptions) {
          result.push({
            uid: streamOption.Uid,
            label: streamOption.Label,
            yAxis: ""
          });
        }
      }
      const key = Report3ElementFeaturesToString[Report3ElementFeatures.YAxis];
      if (this.reports3.dataOneSelectedElementConfiguration?.FeaturesConfiguration &&
        this.reports3.dataOneSelectedElementConfiguration.FeaturesConfiguration[key]
      ) {
        const featuresConfiguration = this.reports3.dataOneSelectedElementConfiguration.FeaturesConfiguration[key];
        for (const item of result) {
          if (featuresConfiguration[item.uid]) {
            item.yAxis = featuresConfiguration[item.uid] ?? "";
          }
        }
      }
    }
    return result;
  }

  set selectedYAxis(value: any[]) {
    this.saveYAxis(value);
  }

  onYAxisChange(): void {
    this.saveYAxis(this.selectedYAxis);
  }

  saveYAxis(value: any[]): void {
    if (this.reports3.dataOneSelectedElementConfiguration?.FeaturesConfiguration) {
      const featuresConfiguration = this.reports3.dataOneSelectedElementConfiguration.FeaturesConfiguration;
      const key = Report3ElementFeaturesToString[Report3ElementFeatures.YAxis];
      featuresConfiguration[key] = {};
      for (const item of value) {
        featuresConfiguration[key][item.uid] = item.yAxis;
      }
      this.reports3.dataOneSelectedElementConfiguration.FeaturesConfiguration = featuresConfiguration;
    }
  }
  // #endregion y axis
}

export default ReportsReportLeftPanelView;
</script>