<template>
  <div v-if="isEnabled" style="position: fixed; top: 0; left:0; width: 30%; min-height: 50px; display: flex; justify-content: center; align-items: center; background-color: rgba(255, 255, 255, 0.5); z-index: 1000;">
    <div v-if="isEnabled">
      <b>App Height: </b>{{systemStore.appHeight}}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { useSystemStore } from "@/stores/system";

@Component({
  
  components: {
  },
})
class DebugView extends Vue {
  systemStore = useSystemStore();

  get isEnabled(): boolean {
    return this.systemStore.devSettigns.includes("appHeight");
  }
}

export default DebugView;
</script>
