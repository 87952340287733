import { AggregatedDataRequest } from "@/models/AggregatedDataRequest";
import { DateRangeSetpoint } from "@/models/dashboard/DateRangeSetpoint";
import { GDRSModel } from "@/models/dashboard/GDRSModel";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";
import { AggregationType } from "@/models/enums/AggregationType";
import { AggregationTypeString } from "@/models/enums/AggregationTypeString";
import { TimeRange } from "@/models/enums/TimeRange";
import { StreamValuesRequest } from "@/models/StreamValuesRequest";
import ToastService from "@/services/ToastService";
import moment from "moment";
import DateHelper from "./DateHelper";
import { StreamModel } from "@/models/StreamModel";
import { StreamModelLite } from "@/models/StreamModelLite";
import { WidgetDataSettingsBasic } from "@/models/dashboard/WidgetDataSettingsBasic";
import { RDRSModel } from "@/models/reports/v3/RDRSModel";

class DataHelper {
  wdsToApiRequest(wds: WidgetDataSettingsBasic, gdrs: GDRSModel | RDRSModel | null, widgetType: string, fields: string[] | undefined = undefined): AggregatedDataRequest {
    const streams: StreamValuesRequest[] = [];
    const drs: DateRangeSetpoint = gdrs && gdrs.active ? gdrs : wds;
    wds.streamOptions.forEach((so) => {
      if (so.StreamKey) {
        const streamParams: StreamValuesRequest = {
          StreamKey: so.StreamKey,
          AggregationPeriod: null,
          AggregationType:
            so.type == "areaRange" ? AggregationType.Max : so.Params.aggType !== undefined ? so.Params.aggType : (wds.aggType ? wds.aggType : AggregationType.Avg),
          AggregationTypeString:
            so.Params.aggTypeString === undefined ? AggregationTypeString.Last : so.Params.aggTypeString,
          Multiplier:
            so.Params.multiplier != null ? so.Params.multiplier : 1,
          PostProcessIfAvailable: so.Params.postProcessIfAvailable
            ? so.Params.postProcessIfAvailable == 1
            : false,
          TimeIntervals:
            so.Params.timeIntervals &&
            so.Params.timeIntervals.length > 0 &&
            (so.Params.useTimeIntervals == 1 ||
              so.Params.useTimeIntervals == 2)
              ? so.Params.timeIntervals
              : null,
          Time: null
        };
        if (so.Params.timeOffset && so.Params.timeOffset.value) {
          // string check for compatiblity with old widgets
          const offsetValue = 
            typeof so.Params.timeOffset.value === "string" ?
              parseFloat(so.Params.timeOffset.value) :
              so.Params.timeOffset.value;
          const timeFrom = DateHelper.shiftTime(
            moment.utc(
              drs.startDate + "T" + drs.startTime,
              "YYYY-MM-DDTHH:mm:ss"
            ),
            so.Params.timeOffset.period,
            offsetValue
          );
          const timeTo = DateHelper.shiftTime(
            moment.utc(
              drs.endDate + "T" + drs.endTime,
              "YYYY-MM-DDTHH:mm:ss"
            ),
            so.Params.timeOffset.period,
            offsetValue
          );
          streamParams.Time = {
            From: timeFrom.format("YYYY-MM-DDTHH:mm:ss"),
            To: timeTo.format("YYYY-MM-DDTHH:mm:ss"),
            Range: TimeRange.Custom,
          };
        }
        streams.push(streamParams);
      }
    });
    let timeFrom = drs.startDate + "T" + (drs.startTime || "00:00:00");
    let timeTo = drs.endDate + "T" + (drs.endTime || "23:59:59");
    if (drs.rangePreset){
      timeFrom = '';
      timeTo = '';
    }
    const result: AggregatedDataRequest = {
      TimeFrom: timeFrom,
      TimeTo: timeTo,
      TimeRange: drs.rangePreset,    
      AggregationPeriod: this.specialAggPeriod(widgetType, drs.aggPeriod),
      NullWhenNoData: !!wds.showNullValues,
      LocalTimezoneResult: true,    
      Streams: streams
    };
    const dates = DateHelper.extractDateFromRequestBody(result);
    const daysDifference = (dates[1].valueOf() - dates[0].valueOf()) / 86400000;
    const autoForce = !drs.autoAggPeriod && daysDifference >= 365 && (
      result.AggregationPeriod === AggregationPeriod.Raw ||
      result.AggregationPeriod === AggregationPeriod.Minute ||
      result.AggregationPeriod === AggregationPeriod.Minutes5 ||
      result.AggregationPeriod === AggregationPeriod.Minutes10 ||
      result.AggregationPeriod === AggregationPeriod.Minutes15 ||
      result.AggregationPeriod === AggregationPeriod.Minutes30 ||
      result.AggregationPeriod === AggregationPeriod.Hourly ||
      result.AggregationPeriod === AggregationPeriod.Daily);
    if (!this.isSpecial(widgetType) && (drs.aggPeriod === result.AggregationPeriod && drs.autoAggPeriod || autoForce)) {
      result.AggregationPeriod = this.findLowestPeriod(widgetType, daysDifference);
    }
    if (autoForce) {
      ToastService.showToast(
        "warn",
        "Warning",
        "Please select aggregation period >= weekly for preiod >= 1 year. Otherwise, bitpool will use Auto aggregation.",
        10000
      );
    }
    if (result.Streams.length && fields && fields.length) {
      const newStreamRequest: StreamValuesRequest[] = [];
      result.Streams.forEach(streamRequest => {
        if (streamRequest.StreamKey) {
          fields.forEach(field => {
            const additionalStreamRequest = JSON.parse(JSON.stringify(streamRequest)) as StreamValuesRequest;
            switch (field) {
              case "min":
                additionalStreamRequest.AggregationType = AggregationType.Min;
                additionalStreamRequest.AggregationTypeString = AggregationTypeString.Least;
                newStreamRequest.push(additionalStreamRequest);
                break;
              case "max":
                additionalStreamRequest.AggregationType = AggregationType.Max;
                additionalStreamRequest.AggregationTypeString = AggregationTypeString.Most;
                newStreamRequest.push(additionalStreamRequest);
                break;
              case "avg":
                additionalStreamRequest.AggregationType = AggregationType.Avg;
                additionalStreamRequest.AggregationTypeString = AggregationTypeString.Last;
                newStreamRequest.push(additionalStreamRequest);
                break;
              case "diff":
                additionalStreamRequest.AggregationType = AggregationType.Diff;
                additionalStreamRequest.AggregationTypeString = AggregationTypeString.Last;
                newStreamRequest.push(additionalStreamRequest);
                break;
              case "sum":
                additionalStreamRequest.AggregationType = AggregationType.Sum;
                additionalStreamRequest.AggregationTypeString = AggregationTypeString.Last;
                newStreamRequest.push(additionalStreamRequest);
                break;
            }
          });
        }
      });
      result.Streams = newStreamRequest;
    }
    return result;
  }
  isSpecial(widgetType: string): boolean {
    switch (widgetType) {
      case "deltacategory":
      case "alarm": 
      case "leaderboardwidget":
      case "tariffsimulator":
      case "tariffcalculator":
      case "pointwidget":
      case "radialgaugehc":
      case "datagridwidget":
      case "piechartwidget":
      case "donutwidget":
      case "funnelwidget":
      case "activitygaugewidget":
      case "temperature":
      case "humidity":
      case "powerusedgenerated":
      case "powerusage":
      case "solargenerated":
      case "reports3_total":
        return true;
      default:
        return false;
    }
  }
  specialAggPeriod(widgetType: string, aggPeriod: AggregationPeriod): AggregationPeriod {
    if (this.isSpecial(widgetType)) {
      aggPeriod = AggregationPeriod.None; // total
    }
    return aggPeriod;
  }
  findLowestPeriod(widgetType: string, daysDiff: number): number {
    let result = AggregationPeriod.Minutes15; // Minutes15
    if (widgetType === "heatmap") {
      if (daysDiff > 1095) {
        // 3 years
        result = AggregationPeriod.Yearly;
      } else if (daysDiff > 365) {
        result = AggregationPeriod.Monthly;
      } else if (daysDiff > 62) {
        result = AggregationPeriod.Weekly;
      } else if (daysDiff > 31) {
        result = AggregationPeriod.Daily;
      } else if (daysDiff > 1) {
        result = AggregationPeriod.Hourly;
      }
    } else {
      if (daysDiff > 1095) {
        // 3 years
        result = AggregationPeriod.Yearly;
      } else if (daysDiff > 365) {
        result = AggregationPeriod.Monthly;
      } else if (daysDiff > 31) {
        result = AggregationPeriod.Weekly;
      } else if (daysDiff >= 14) {
        result = AggregationPeriod.Daily;
      } else if (daysDiff > 1) {
        result = AggregationPeriod.Hourly;
      }
    }
    return result;
  }
  readingStatus(stream: StreamModel | null | undefined): string {
    if (stream && stream.LastUpdates){
      const m = moment(stream.LastUpdates);
      if (m && m >= moment().subtract(1, "days")) {
        return "Normal";
      } else {
        return "Warning";
      }
    }
    return "-";
  }
  readingStatusLite(stream: StreamModelLite | null | undefined): string {
    if (stream && stream.LastUpdate){
      const m = moment(stream.LastUpdate);
      if (m && m >= moment().subtract(1, "days")) {
        return "Normal";
      } else {
        return "Warning";
      }
    }
    return "-";
  }
  readingStatusColorClass(status: string): string {
    return status === 'Normal' ? 'bg-green-500' : 'bg-red-500';
  }
}

export default new DataHelper();
