export enum Report3ElementFeatures {
  DataOnlyTotalAggregation,
  DataSeriesType,
  DataSeriesTypeStacked,
  YAxis
}

export const Report3ElementFeaturesToString = {
  [Report3ElementFeatures.DataOnlyTotalAggregation]: 'DataOnlyTotalAggregation',
  [Report3ElementFeatures.DataSeriesType]: 'DataSeriesType',
  [Report3ElementFeatures.DataSeriesTypeStacked]: 'DataSeriesTypeStacked',
  [Report3ElementFeatures.YAxis]: 'YAxis'
};