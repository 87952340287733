<template>
  <div class="flex align-items-center justify-content-end w-full lg:w-auto column-gap-2 mt-3 lg:mt-0 lg:ml-5 lg:flex-shrink-0" v-if="editRecord">
    <Button 
      label="Preview PDF" 
      class="p-button-outlined flex-shrink-0"
      @click="openPreviewPDFDialog"
      :disabled="reports3Elements.inProgressTest || !isLoaded"
    />
    <Button
      class="flex-shrink-0"
      :label="editRecord.Id ? 'Save' : 'Create'" 
      @click="saveRecord" 
      :disabled='!reports3Elements.isOneDirty || !editRecord.Name || !editRecord.HTML || updateInProgress || editRecord.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin' 
    />

    <Dialog 
      header="Preview PDF" 
      v-model:visible="displayPreviewPDFDialog" 
      :modal="true" 
      :style="{width: '90vw'}"
      class="reports-pdf-preview-dialog p-dialog-maximized"
    >
      <div class="dialog-content">
        <div v-if="pdfLink" class="pdf-viewer-wrapper">
          <PDFViewer
            ref="pdfViewerRef"
            :source="pdfLink"
            style="height: 90vh; width: 100%"
            @download="handleDownload"
            :settings="pdfViewerSettings"
          />
        </div>
        <div v-else-if="reports3Elements.inProgressTest" class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;">
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
        <div v-else class="m-5">
          <Message severity="error" :closable="false">{{ reports3Elements.testError ?? "Pdf generation failed" }}</Message>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import AuthState from "@/store/states/AuthState";
import moment from "moment";
import { useOrganisationStore } from "@/stores/organisation";
import { useReports3ElementsStore } from "@/stores/reports3Elements";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { HtmlToPdfReportParameters } from "@/models/reports/v3/HtmlToPdfReportParameters";
import  secureRandomPassword from 'secure-random-password';
import PDFViewer from 'pdf-viewer-vue';
import { nextTick } from "vue";
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import ReportElementPdfHelper from "@/helpers/ReportElementPdfHelper";
import ReportsHtmlElementView from "@/components/views/reports/ReportsHtmlElementView.vue";
import { useReports3Store } from "@/stores/reports3";
import { useReports3DataStore } from "@/stores/reports3Data";
import { Reports3Datasource } from "@/models/reports/v3/Reports3Datasource";
import ToastService from "@/services/ToastService";
import { usePageStore } from "@/stores/page";

@Component({
  components: {
    Button,
    Dialog,
    ProgressSpinner,
    PDFViewer,
    ReportsHtmlElementView
  },
  directives: {
  }
})
class ReportsElementControlsView extends Vue {
  reports3 = useReports3Store();
  reports3Elements = useReports3ElementsStore();
  reports3Data = useReports3DataStore();

  get editRecord(): Reports3ElementEntity | null {
    return this.reports3Elements.dataOne;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  pageStore = usePageStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  get isChanged(): boolean {
    return this.pageStore.dirty;
  }

  set isChanged(value: boolean) {
    this.pageStore.dirty = value;
  }

  Reports3ElementRole = Reports3ElementRole;

  get updateInProgress(): boolean {
    return this.reports3Elements.updateInProgress;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      this.isChanged = false;
      await this.reports3Elements.createUpdate(this.editRecord);
      this.reports3Elements.isOneDirty = false;
    }
  }

  displayPreviewPDFDialog = false;

  openPreviewPDFDialog(): void {
    this.generatePdf();
    this.displayPreviewPDFDialog = true;
  }
  
  pdfLink: string | null = null;
  pdfViewerSettings: any = { defaultZoom: 175 };

  get isLoaded(): boolean {
    if (this.editRecord) {
      const dataIdentifier = this.reports3Data.buildDataIdentifier(this.editRecord, null, this.datasource, null);
      const result = !!this.reports3Data.isLoaded[dataIdentifier];
      return result || !this.editRecord.EnableData;
    } else {
      return false;
    }
  }

  get datasource(): Reports3Datasource | null {
    const result = this.reports3Data.buildStubDataConfiguration();
    return result;
  }

  async generatePdf(): Promise<void> {
    if (this.editRecord) {
      this.pdfLink = null;

      const dataIdentifier = this.reports3Data.buildDataIdentifier(this.editRecord, null, this.datasource, null);
      let awaitedTime = 0;
      const timeoutMs = 500;
      while (!this.isLoaded) {
        await new Promise(resolve => setTimeout(resolve, timeoutMs));
        awaitedTime += timeoutMs;
        if (awaitedTime >= 90000) {
          break;
        }
      }
      const data = this.reports3Data.data[dataIdentifier];

      const rnd = secureRandomPassword.randomPassword(
        { 
          length: 5, 
          characters: [secureRandomPassword.upper, secureRandomPassword.digits] 
        }
      );
      const html = ReportElementPdfHelper.generateHtmlForPdf(
        this.editRecord, 
        null, 
        `${this.reports3.cssPdf ?? ''}\n${this.reports3.cssCommon ?? ''}`,
        this.currentOrganisation?.Name ?? 'Organisation',
        "Report",
        data,
        this.reports3Data.buildStubDataConfiguration()
      );
      const body: HtmlToPdfReportParameters = {
        HeaderHeight: this.editRecord.Role === Reports3ElementRole.Header ? this.editRecord.Height : 0,
        Header: this.editRecord.Role === Reports3ElementRole.Header ? html : "",
        Body: this.editRecord.Role === Reports3ElementRole.Body ? html : "",
        MarginTop: 1,
        MarginBottom: 1,
        MarginLeft: 1,
        MarginRight: 1,
        FooterHeight: this.editRecord.Role === Reports3ElementRole.Footer ? this.editRecord.Height : 0,
        Footer: this.editRecord.Role === Reports3ElementRole.Footer ? html : "",
        Filename: `report-${moment().format("YYYY-MM-DDTHH-mm-ss")}-${rnd}.pdf`
      }
      await this.reports3Elements.generate(body);
      if (this.reports3Elements.testResult) {
        this.pdfLink = this.reports3Elements.testResult;
        await nextTick();
        if (this.$refs.pdfViewerRef) {
          // defaultZoom option doesn't work, this is workaround
          (this.$refs.pdfViewerRef as any).handleUpdateZoom(125);
        }
      } else {
        ToastService.showToast(
          "error",
          "Pdf generation",
          this.reports3Elements.testError ?? "Pdf generation failed",
          5000
        );
      }
    }
  }

  handleDownload(): void {
    if (this.pdfLink) {
      window.open(this.pdfLink, '_blank');
    }
  }
}

export default ReportsElementControlsView;
</script>