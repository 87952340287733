import { AggregatedDataHighchartsResponse } from "@/models/AggregatedDataHighchartsResponse";
import { Reports3Datasource } from "@/models/reports/v3/Reports3Datasource";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import { v4 as uuidv4 } from "uuid";
import TextHelper from "./TextHelper";

class ReportElementPdfHelper {
  generateHtmlForPdf(element: Reports3ElementEntity, configuration: Reports3ElementConfiguration | null = null,
    globalStyles: string = "", organisationName: string = "Organisation", reportName: string = "Report",
    data: AggregatedDataHighchartsResponse[] | null = null, dataConfiguration: Reports3Datasource | null = null
  ): string {
    const html = this.generateHtml(
      element,
      configuration,
      element.Role === Reports3ElementRole.Body ?
      globalStyles :
      `${globalStyles}
        body {
          /* Header/footer-ralated styles */
          margin: 0;
          padding: 0;
          .report-element {
            width: 100%;
            height: ${element.Height}cm;
          }
        }`,
      organisationName,
      reportName,
      data,
      dataConfiguration
    );
    return html;
  }

  private generateHtml(element: Reports3ElementEntity, configuration: Reports3ElementConfiguration | null = null,
    globalStyles: string = "", organisationName: string = "Organisation", reportName: string = "Report",
    data: AggregatedDataHighchartsResponse[] | null = null, dataConfiguration: Reports3Datasource | null = null
  ): string {
    const parentId = `report-element-${uuidv4()}`;
    const parameters: Record<string, any> = {};
    if (element.AdditionalParameters) {
      for (const param of element.AdditionalParameters) {
        parameters[param.Name] = param.DefaultValue;
      }
    }
    if (configuration?.AdditionalParameters) {
      for (const key in configuration.AdditionalParameters) {
        parameters[key] = configuration.AdditionalParameters[key];
      }
    }
    parameters.organisationName = organisationName;
    parameters.reportName = reportName;
    const now = new Date();
    const dateString = now.toISOString();
    const dateParts = dateString.split('T')[0].split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    parameters.todayYear = year;
    parameters.todayMonth = month;
    parameters.todayDay = day;
    parameters.today = `${day}/${month}/${year}`;

    let htmlWithParameters = element.HTML;
    let cssWithParameters = element.CSS;
    for (const key in parameters) {
      htmlWithParameters = htmlWithParameters.replace(new RegExp(`\\{\\{${key}\\}\\}`, 'g'), parameters[key]);
      cssWithParameters = cssWithParameters.replace(new RegExp(`\\{\\{${key}\\}\\}`, 'g'), parameters[key]);
    }
    const size = element.Role === Reports3ElementRole.Body ? (100 * (configuration ? configuration.Size : element.DefaultSize) / 12) : 100;
    // function displayData(parent, parameters, data, dataConfiguration, libraries, onReadyEvent)
    const html = this.getGeneratedPage(
      `<div id="${parentId}" class="report-element" style="width: ${size}%">${htmlWithParameters}</div>`,
      `${globalStyles} #${parentId} { ${cssWithParameters} }`,
      element.Role === Reports3ElementRole.Body ?
        `document.body.style.width = "calc(21cm - 1cm - 1cm)";
        const elementListReady = [];
        const elementList = ["${parentId}"];
        const timeout = 60000; // 1 minute
        const timeStart = Date.now();
        async function reportReady() {
          await document.fonts.ready;
          while (true) {
            if (elementListReady.length >= elementList.length) {
              // check if all elements are ready
              let isOk = true;
              for (const element of elementList) {
                if (!elementListReady.includes(element)) {
                  isOk = false;
                  break;
                }
              }
              if (isOk) {
                break;
              }
            }
            if (Date.now() - timeStart > timeout) {
              break;
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
        (function() { 
          ${element.JavaScript}
          const onReadyEvent = function() {
            elementListReady.push("${parentId}");
          };
          if (displayData) {
            const parameters = ${JSON.stringify(parameters)};
            const data = ${JSON.stringify(TextHelper.objectKeysToCamelCase(data))};
            const dataConfiguration = ${JSON.stringify(TextHelper.objectKeysToCamelCase(dataConfiguration))};
            const libraries = {
              Highcharts: Highcharts
            };
            displayData(document.querySelector("#${parentId}"), parameters, data, dataConfiguration, libraries, onReadyEvent);
          } else {
            onReadyEvent();
          }
        })();` :
        ``
    );
    return html;
  }

  private getGeneratedPage(html: string, css: string, js: string): string {
    const cssLine = css && `<style>${css}</style>`;
    const jsLine = js && `<script>${js}</${"script"}>`; // note: regular script close tag breaks vue parser
    const source = `
      <html>
        <head>
          <script src="https://code.highcharts.com/11.4.8/highcharts.js"></script>
          ${cssLine}
        </head>
        <body>
          ${html || ''}
          ${jsLine}
        </body>
      </html>`;
    return source;
  }
}

export default new ReportElementPdfHelper();
