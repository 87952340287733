import camelcaseKeys from "camelcase-keys";

class TextHelper {
  objectKeysToCamelCase(object: any): any {
    if (object) {
      const result = camelcaseKeys(object, { deep: true, preserveConsecutiveUppercase: true });
      return result;
    } else {
      return object;
    }
  }
}

export default new TextHelper();