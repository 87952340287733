import { defineStore } from "pinia";
import SystemState from "./states/SystemState";

export const useSystemStore = defineStore('System', {
  state: (): SystemState => ({ 
    isMobile: false,
    isIOs: false,
    os: "",

    appHeight: "",
    devSettigns: []
  }),
  getters: {
    isChristmas(state: SystemState): boolean {
      return state.devSettigns.includes("isChristmas");
    },
    isModernReportsEnabled(state: SystemState): boolean {
      return state.devSettigns.includes("isModernReportsEnabled");
    }
  },
  actions: {
    setAppHeight(value: string) {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", value);
      this.appHeight = value;
    },
    enableDevOption(option: string): void {
      if (!this.devSettigns.includes(option)) {
        this.devSettigns.push(option);
        this.saveDevOptions();
      }
    },
    disableDevOption(option: string): void {
      const index = this.devSettigns.indexOf(option);
      if (index > -1) {
        this.devSettigns.splice(index, 1);
        this.saveDevOptions();
      }
    },
    saveDevOptions(): void {
      // save to local storage
      localStorage.setItem("devSettigns", JSON.stringify(this.devSettigns));
    },
    loadDevOptions(): void {
      // load from local storage
      const devSettigns = localStorage.getItem("devSettigns");
      if (devSettigns) {
        this.devSettigns = JSON.parse(devSettigns);
      }
    }
  },
});
