import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import Reports3DataState from "./states/Reports3DataState";
import { AggregatedDataHighchartsResponse } from "@/models/AggregatedDataHighchartsResponse";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { Reports3Datasource } from "@/models/reports/v3/Reports3Datasource";
import DataHelper from "@/helpers/DataHelper";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";
import { Report3ElementFeatures } from "@/models/reports/v3/Report3ElementFeatures";
import { AggregationType } from "@/models/enums/AggregationType";
import { AggregationTypeString } from "@/models/enums/AggregationTypeString";
import { TimeRange } from "@/models/enums/TimeRange";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";
import WidgetHelper from "@/helpers/WidgetHelper";
import StreamOption from "@/models/dashboard/StreamOption";
import ReportPdfHelper from "@/helpers/ReportPdfHelper";

export const useReports3DataStore = defineStore('reports3Data', {
  state: (): Reports3DataState => ({ 
    isLoaded: {},
    requestGuid: {},
    data: {},
    dataRequestBody: {}
  }),
  getters: {
  },
  actions: {
    buildDataIdentifier(element: Reports3ElementEntity,
      elementConfiguration: Reports3ElementConfiguration | null,
      datasource: Reports3Datasource | null,
      report: Reports3Entity | null
    ) {
      let dataIdentifier = ReportPdfHelper.buildDataIdentifier(element, elementConfiguration, datasource, report);
      return dataIdentifier;
    },
    async load(element: Reports3ElementEntity,
      elementConfiguration: Reports3ElementConfiguration | null,
      datasource: Reports3Datasource | null,
      report: Reports3Entity | null
    ) {
      const dataIdentifier = this.buildDataIdentifier(element, elementConfiguration, datasource, report);
      const isTotal = element.Features.includes(Report3ElementFeatures.DataOnlyTotalAggregation);
      try {
        if (elementConfiguration && datasource && report) {
          // Load actual data
          const requestBody = DataHelper.wdsToApiRequest(
            datasource.Configuration, 
            datasource.UseReportDRS ? report.DateRange : null,
            isTotal ? "reports3_total" : "reports3"
          );
          
          // Reuse datasource value for different elements.
          if (typeof this.isLoaded[dataIdentifier] !== "undefined") {
            const previousRequestBody = this.dataRequestBody[dataIdentifier];
            const previousRequestBodyString = JSON.stringify(previousRequestBody);
            const currentRequestBodyString = JSON.stringify(requestBody);
            let isOk = true;
            if (previousRequestBodyString === currentRequestBodyString) {
              let awaitedTime = 0;
              const timeoutMs = 500;
              while (!this.isLoaded[dataIdentifier]) {
                await new Promise(resolve => setTimeout(resolve, timeoutMs));
                awaitedTime += timeoutMs;
                if (awaitedTime >= 90000) {
                  isOk = false;
                  break;
                }
              }
              if (isOk) {
                return;
              }
            }
          }
          
          const guid = uuidv4();
          this.requestGuid[dataIdentifier] = guid;
          this.isLoaded[dataIdentifier] = false;
          this.dataRequestBody[dataIdentifier] = requestBody;

          const url = `rest/BitPool_V2/stream/values/Highcharts`;
          const response = await axios.post<AggregatedDataHighchartsResponse[]>(url, requestBody, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (this.requestGuid[dataIdentifier] === guid) {
            this.data[dataIdentifier] = response.data;
            this.isLoaded[dataIdentifier] = true;
          }
        } else {
          // Create data stub for testing purposes
          const guid = uuidv4();
          this.requestGuid[dataIdentifier] = guid;
          this.isLoaded[dataIdentifier] = false;
          this.data[dataIdentifier] = this.buildStubData(isTotal);
          this.isLoaded[dataIdentifier] = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load datasource data",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data[dataIdentifier] = null;
        this.isLoaded[dataIdentifier] = true;
      }
    },
    buildStubDataConfiguration(): Reports3Datasource {
      const result: Reports3Datasource = {
        Uid: "stub-1",
        Name: "Stub Data",
        UseReportDRS: false,
        Configuration: {
          streamOptions: [],
          aggType: undefined,
          showNullValues: 1,

          rangePreset: TimeRange.Today,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
        }
      };
      
      const widgetDescription = WidgetHelper.getWidget("reports3", false);
      if (widgetDescription) {
        for (let i = 0; i < 2; i++) {
          const so: StreamOption = JSON.parse(JSON.stringify(widgetDescription.defaultStreamOptions));
          so.StreamKey = `0000000000-0000-0000-0000-00000000000${i}`;
          so.Name = `Stream ${i}`;
          so.Label = `Stream ${i}`;
          result.Configuration.streamOptions.push(so);
        }
      }
      return result;
    },
    buildStubData(isTotal: boolean): AggregatedDataHighchartsResponse[] {
      const data: AggregatedDataHighchartsResponse[] = [];
      for (let i = 0; i < 2; i++) {
        if (isTotal) {
          data.push(
            {
              StreamKey: `0000000000-0000-0000-0000-00000000000${i}`,
              Data: [
                {
                  x: 1729641600000,
                  y:Math.random() * 100000,
                  calculated: false
                }
              ],
              Error: null,
              StreamValuesRequest: {
                StreamKey: `0000000000-0000-0000-0000-00000000000${i}`,
                AggregationType: AggregationType.Last,
                AggregationTypeString: AggregationTypeString.Last,
                Multiplier: 1.0,
                PostProcessIfAvailable: false,
                TimeIntervals: null,
                Time: null,
                AggregationPeriod: null
              }
            }
          );
        } else {
          data.push(
            {
              StreamKey: `0000000000-0000-0000-0000-00000000000${i}`,
              Data: [
              ],
              Error: null,
              StreamValuesRequest: {
                StreamKey: `0000000000-0000-0000-0000-00000000000${i}`,
                AggregationType: AggregationType.Last,
                AggregationTypeString: AggregationTypeString.Last,
                Multiplier: 1.0,
                PostProcessIfAvailable: false,
                TimeIntervals: null,
                Time: null,
                AggregationPeriod: null
              }
            }
          );
          for (let j = 0; j < 7; j++) {
            const x = 1729641600000 + (j * 86400000);
            const y = Math.random() * 100000;
            data[i].Data.push({
              x: x,
              y: y,
              calculated: false
            });
          }
        }
      }
      return data;
    }
  },
})
