<template>
  <div class="gdrs-content flex" :class="rdrsMode ? 'align-items-center' : 'align-items-start gap-3 xl:gap-4'">
    <div :class="rdrsMode ? 'flex-shrink-0 flex-order-2 ml-2' : 'flex-auto'">
      <h3 class="gdrs-title" :class="rdrsMode ? 'mb-0' : ''">
        {{ rdrsMode ? "Enable" : "Enable GDRS (Global Date Range Setpoint)" }}
      </h3>

      <p class="mb-0" v-if="!rdrsMode">
        <i class="pi pi-info-circle"></i>
        <span>Enabling GDRS allows you to view all enabled widgets in your dashboard to your desired time frame</span>
      </p>
    </div>
    <div class="flex-shrink-0" :class="rdrsMode ? 'flex-order-1' : ''">
      <InputSwitch 
        id="activateDeactivateGdrsMain"
        class="vertical-align-top"
        v-model="active" 
        @change="onActiveChanged" 
        />
    </div>
  </div>
  <p v-if="rdrsMode" class="help-text">
    <span>Use settings to adjust all report data to a desired time frame. For customised date ranges on individual data source change settings within the data source.</span>
  </p>
  <div class="flex" style="gap: .5714rem; margin-bottom: 1.1429rem;" :class="rdrsMode ? 'align-items-center' : ''"> 
    <Button @click="goBackForwardEvent(false)" v-tippy="'Go back'" icon="pi pi-chevron-left" class="p-button-rounded p-button-icon-only p-button-outlined flex-shrink-0" :class="rdrsMode ? '' : 'gdrs-back'"/>
    <Button @click="goBackForwardEvent(true)" v-tippy="'Go forward'" icon="pi pi-chevron-right" class="p-button-rounded p-button-icon-only p-button-outlined flex-shrink-0" :class="rdrsMode ? '' : 'gdrs-forward'"/>
    <Button :disabled="gdrs?.rangePresetHolder === gdrs?.rangePreset" @click="resetFromHolder" label="Reset" class="flex-shrink-0" :class="rdrsMode ? 'p-button-outlined' : 'gdrs-reset'"/>
  </div>

  <div class="widget-date-range-controls formgrid grid">
    <div class="col-12 mb-0">
      <div class="formgrid grid align-items-end">
        <div class="field col-12" :class="rdrsMode ? '' : 'mb-3'">
          <label for="selectDateRangeMain">Date Range</label>
          <div>
            <Dropdown
              v-model="selectedDateRange"
              :options="dateRanges"
              optionValue="key"
              optionLabel="name"
              id="selectDateRangeMain"
              placeholder="Select Date Range"
              @change="setDates(false)"
              class="w-full"
            />
          </div>
        </div>
        <div class="field col-12" :class="rdrsMode ? '' : 'mb-3'">
          <label for="dataAggregationWidget">Data Aggregation</label>
          <div>
            <Dropdown
              v-model="selectedDataAggregation"
              :options="dataAggregations"
              optionValue="key"
              optionLabel="name"
              placeholder="Select Data Aggregation"
              @change="setDataAggregation"
              id="dataAggregationWidget"
              class="w-full"
            />
          </div>
        </div>
        <div class="field col-6 mb-0">
          <label for="startDateMain">Start Date</label>
          <div>
            <Calendar
              ref="calendarFrom"
              v-model="dateFrom"
              :maxDate="dateTo"
              :showTime="true"
              dateFormat="dd/mm/yy"
              @date-select="onChangeDate()"
              @change="onChangeDate()" 
              @show="onCalendarShow"
              @hide="onCalendarHide"
              panelClass="with-max-width"
              class="w-full"
              id="startDateMain"
            >
              <template #footer>
                <div class="flex justify-content-end pb-3">
                  <Button
                    label="Close"
                    icon="pi pi-times"
                    @click="closeCalendar"
                    class="p-button-text p-button-secondary"
                  />
                </div>
              </template>
            </Calendar>
          </div>
        </div>
        <div class="field col-6 mb-0">
          <label for="endDateMain">End date</label>
          <div>
            <Calendar
              ref="calendarTo"
              v-model="dateTo"
              :minDate="dateFrom"
              :showTime="true"
              dateFormat="dd/mm/yy"
              @date-select="onChangeDate()"
              @change="onChangeDate()" 
              @show="onCalendarShow"
              @hide="onCalendarHide"
              panelClass="with-max-width"
              class="w-full"
              id="endDateMain"
            >
              <template #footer>
                <div class="flex justify-content-end pb-3">
                  <Button
                    label="Close"
                    icon="pi pi-times"
                    @click="closeCalendar"
                    class="p-button-text p-button-secondary"
                  />
                </div>
              </template>
            </Calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import DashboardState from "@/store/states/DashboardState";
import moment from "moment";
import { debounce } from 'throttle-debounce';
import { Watch } from "vue-facing-decorator";
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import { TimeRange } from "@/models/enums/TimeRange";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import DateHelper from "@/helpers/DateHelper";
import { GDRSModel } from "@/models/dashboard/GDRSModel";
import { RDRSModel } from "@/models/reports/v3/RDRSModel";

@Component({
  components: {
    Dropdown,
    Calendar,
    Button,
    InputSwitch,
    DateTimezoneView
  },
  directives: {
  }
})
class DashboardWidgetGlobalDateRangeView extends Vue {
  @Prop({ required: true }) gdrs!: GDRSModel | RDRSModel | null;
  @Prop({ required: true }) overlayFix!: (dismissableOverlay: boolean) => void;
  @Prop({ required: false, default: false }) rdrsMode!: boolean;

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  created() {
    this.fromWidgetConfigToVariables();
  }

  fromWidgetConfigToVariables(): void {
    if (this.gdrs) {
      this.selectedDateRange = this.gdrs.rangePreset;
      if (this.gdrs.rangePreset === TimeRange.Custom) {
        const startDateM = moment(`${this.gdrs.startDate}T${this.gdrs.startTime}`);
        const endDateM = moment(`${this.gdrs.endDate}T${this.gdrs.endTime}`);
        this.dateFrom = startDateM.toDate();
        this.dateTo = endDateM.toDate();
      } else {
        this.setDates(true);
      }
      if (this.gdrs.autoAggPeriod) {
        this.selectedDataAggregation = -1;
      } else {
        this.selectedDataAggregation = this.gdrs.aggPeriod;
      }
      this.active = this.gdrs.active;
    }
  }

  fromVariablesToWidgetConfig(): void {
    if (this.gdrs) {
      if (this.selectedDateRange !== TimeRange.Custom) {
        this.gdrs.rangePresetHolder = this.selectedDateRange;
      }
      this.gdrs.rangePreset = this.selectedDateRange;
      if (this.selectedDateRange === TimeRange.Custom) {
        const startDateM = moment(this.dateFrom);
        const endDateM = moment(this.dateTo);
        this.gdrs.startDate = startDateM.format("YYYY-MM-DD");
        this.gdrs.startTime = startDateM.format("HH:mm:ss");
        this.gdrs.endDate = endDateM.format("YYYY-MM-DD");
        this.gdrs.endTime = endDateM.format("HH:mm:ss");
      } else {
        this.gdrs.startDate = "";
        this.gdrs.startTime = "";
        this.gdrs.endDate = "";
        this.gdrs.endTime = "";
      }
      this.gdrs.autoAggPeriod = this.selectedDataAggregation === -1;
      if (this.selectedDataAggregation !== -1) {
        this.gdrs.aggPeriod = this.selectedDataAggregation;
      }
      this.gdrs.active = this.active;
      this.$store.dispatch("dashboard/saveGDRS");
    }
  }

  resetFromHolder(): void {
    if (this.gdrs) {
      this.gdrs.rangePreset = this.gdrs.rangePresetHolder;
      this.fromWidgetConfigToVariables();
    }
  }

  goBackForwardTrigger = '';

  debounceGoBackForward = debounce(500, this.goBackForward);

  goBackForwardCounter = 0;

  goBackForwardEvent(forward: boolean): void {
    if (forward) {
      this.goBackForwardCounter++;
    } else {
      this.goBackForwardCounter--;
    }
    this.debounceGoBackForward();
  }

  @Watch('goBackForwardTrigger', { immediate: false, deep: false })
  goBackForward(): void {
    const counter = this.goBackForwardCounter;
    if (counter !== 0) {
      this.goBackForwardCounter = 0;
      const dates = DateHelper.goBackForward(counter, this.dateFrom, this.dateTo);
      this.dateFrom = dates[0];
      this.dateTo = dates[1];
      this.selectedDateRange = TimeRange.Custom;
      this.fromVariablesToWidgetConfig();
    }
  }

  active = false;
  dateFrom: Date = new Date();
  dateTo: Date = new Date();
  selectedDateRange = TimeRange.Custom;

  dateRanges = DateHelper.getDateRanges();

  setDates(silent: boolean): void {    
    const dates = DateHelper.timeRangeToMoments(this.selectedDateRange);
    const start = dates[0];
    const end = dates[1];
    if (start) {
      this.dateFrom = start.toDate();
    }
    if (end) {
      this.dateTo = end.toDate();
    }
    if (!silent) {
      this.fromVariablesToWidgetConfig();
    }
  }

  closeCalendar(): void {
    if (this.$refs.calendarFrom) {
      (this.$refs.calendarFrom as any).overlayVisible = false;
    }
    if (this.$refs.calendarTo) {
      (this.$refs.calendarTo as any).overlayVisible = false;
    }
  }

  onChangeDate(): void {
    this.debounceSelectedDateRangeTrigger();
  }

  debounceSelectedDateRangeTrigger = debounce(500, this.onSelectedDateRangeChanged);

  onSelectedDateRangeChanged(): void {
    this.selectedDateRange = TimeRange.Custom;
    
    this.fromVariablesToWidgetConfig();
  }

  onActiveChanged(): void {
    this.fromVariablesToWidgetConfig();
  }

  // #region overlay
  onCalendarShow(): void {
    if (this.overlayFix) {
      this.overlayFix(false);
    }
  }

  onCalendarHide(): void {
    if (this.overlayFix) {
      this.overlayFix(true);
    }
  }
  // #endregion overlay

  // #region data aggregation
  selectedDataAggregation = -1;

  dataAggregations = DateHelper.getDataAggregations();

  setDataAggregation(): void { 
    this.fromVariablesToWidgetConfig();
  }
  // #endregion data aggregation
}

export default DashboardWidgetGlobalDateRangeView;
</script>
