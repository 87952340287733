import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import { JSStreamLog } from "@/models/email-import/JSStreamLog";
import DateHelper from "@/helpers/DateHelper";
import { WebhookScriptEntity } from "@/models/webhooks/WebhookScriptEntity";
import WebhookScriptState from "./states/WebhookScriptState";

export const useWebhookScriptStore = defineStore('webhookScript', {
  state: (): WebhookScriptState => ({ 
    isLoadedScripts: false,
    guidScripts: "",
    scripts: null,
    updateInProgressScripts: false,
    updateErrorScripts: false,
    inProgressScriptTest: false,
    testResult: null,
    testError: ""
  }),
  getters: {
  },
  actions: {
    async loadScripts() {
      try {
        const guid = uuidv4();
        this.guidScripts = guid;
        this.isLoadedScripts = false;
        const url = `rest/BitPool_V2/webhook/scripts`;
        const response = await axios.get<WebhookScriptEntity[]>(url);
        if (this.guidScripts === guid) {
          this.scripts = response.data;
          this.isLoadedScripts = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load scripts",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.scripts = null;
        this.isLoadedScripts = true;
      }
    },
    async createUpdateScript(entity: WebhookScriptEntity) {
      try {
        this.updateInProgressScripts = true;
        this.updateErrorScripts = false;
        const url = `rest/BitPool_V2/webhook/scripts`;
        const response = await axios.post<WebhookScriptEntity>(url, entity);
        ToastService.showToast("success", "Scripts", "Changes saved", 5000);
        this.updateInProgressScripts = false;
        if (this.isLoadedScripts && this.scripts) {
          if (entity.Id) {
            const index = this.scripts.findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.scripts[index] = response.data;
            } else {
              this.scripts.push(response.data);
            }
          } else {
            this.scripts.push(response.data);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save script",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateErrorScripts = true;
        this.updateInProgressScripts = false;
      }
    },
    async testScript(entity: WebhookScriptEntity) {
      try {
        this.inProgressScriptTest = true;
        this.testResult = null;
        this.testError = "";
        const url = `rest/BitPool_V2/webhook/scripts/test`;
        const response = await axios.post<JSStreamLog[]>(url, entity);
        if (response.data && response.data) {
          response.data.forEach((x) => {
            if (typeof x.date === "string") {
              x.date = DateHelper.parseFromMicrosoftString(x.date);
            }
          })
          this.testResult = response.data;
          ToastService.showToast("success", "The script test is completed", `Parsed ${response.data.length} records.`, 5000);
        }
      } catch (error) {
        this.testResult = null;
        const message = ErrorHelper.handleAxiosError(error).message;
        ToastService.showToast(
          "error",
          "The script test failed",
          message,
          5000
        );
        this.testError = message;
      }
      this.inProgressScriptTest = false;
    },
    async deleteScript(entity: WebhookScriptEntity) {
      try {
        const url = `rest/BitPool_V2/webhook/scripts/${entity.Id}`;
        await axios.delete(url);
        ToastService.showToast("success", "Scripts", `${entity.Name} is deleted`, 5000);
        if (this.isLoadedScripts && this.scripts) {
          const index = this.scripts.findIndex((x) => x.Id === entity.Id);
          if (index > -1) {
            this.scripts.splice(index, 1);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete ${entity.Name}`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    }
  },
})
