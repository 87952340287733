<template>
  <span>
    {{dateStr}}
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { TimeZoneDto } from "@/models/TimeZoneDto";
import moment from "moment";
import { isNumber } from "highcharts";

@Component({
  components: {
  },
})
class DateTimezoneView extends Vue {
  @Prop({ required: true }) date!: Date | string;
  @Prop({ required: true }) timezone!: string;
  @Prop({ required: true }) hideSeconds!: boolean;
  @Prop({ required: true }) hideTime!: boolean;
  @Prop({ required: true }) hideDate!: boolean;
  @Prop({ required: false, default: true }) hideMilliseconds!: boolean;

  get dateStr(): string {
    if (this.date && (this.date instanceof Date || isNumber(this.date) || this.date === "now")) {
      let utcOffset: number;
      if (this.timezone === "local") {
        utcOffset = moment().utcOffset() / 60;
      } else {
        const timezone = this.timezoneModel;
        utcOffset = timezone ? timezone.UtcOffset : 0;
      }
      let m: moment.Moment;
      if (this.date === "now") {
        m = moment().utc().add(utcOffset, "hours");
      } else {
        m = moment(this.date).utc().add(utcOffset, "hours");
      }
      const result = 
        this.hideDate ?
          this.hideSeconds ? 
            m.format("HH:mm") : 
            m.format("HH:mm:ss"):
          this.hideTime ? 
            m.format("DD/MM/YYYY") : 
            this.hideSeconds ? 
              m.format("DD/MM/YYYY HH:mm") : 
              this.hideMilliseconds ?
                m.format("DD/MM/YYYY HH:mm:ss") :
                m.format("DD/MM/YYYY HH:mm:ss.SSS");
      return result;
    }
    return "-";
  }

  get timezoneModel(): TimeZoneDto | undefined {
    const timezone = this.timezones.find((tz) => tz.Id === this.timezone);
    return timezone;
  }

  get timezones(): TimeZoneDto[] {
    return this.$store.state.timezones;
  }
}

export default DateTimezoneView;
</script>