import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import WebhookServerState from "./states/WebhookServerState";
import { WebhookServerConfig } from "@/models/webhooks/WebhookServerConfig";

export const useWebhookServerStore = defineStore('webhookServer', {
  state: (): WebhookServerState => ({ 
    isLoaded: false,
    guid: "",
    entities: null,
    updateInProgress: false,
    updateError: false
  }),
  getters: {
  },
  actions: {
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        const url = `rest/BitPool_V2/webhook/server`;
        const response = await axios.get<WebhookServerConfig[]>(url);
        if (this.guid === guid) {
          this.entities = response.data;
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load configurations",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entities = null;
        this.isLoaded = true;
      }
    },
    async createUpdate(entity: WebhookServerConfig) {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/webhook/server`;
        const response = await axios.post<WebhookServerConfig>(url, entity);
        ToastService.showToast("success", "Webhooks", "Changes saved", 5000);
        this.updateInProgress = false;
        if (this.isLoaded && this.entities) {
          if (entity.Id) {
            const index = this.entities.findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.entities[index] = response.data;
            } else {
              this.entities.push(response.data);
            }
          } else {
            this.entities.push(response.data);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save webhook",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
      }
    },
    async delete(entity: WebhookServerConfig) {
      try {
        const url = `rest/BitPool_V2/webhook/server/${entity.Id}`;
        await axios.delete(url);
        ToastService.showToast("success", "Webhooks", `${entity.Name} is deleted`, 5000);
        if (this.isLoaded && this.entities) {
          const index = this.entities.findIndex((x) => x.Id === entity.Id);
          if (index > -1) {
            this.entities.splice(index, 1);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete ${entity.Name}`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    }
  },
})
